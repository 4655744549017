import React from "react";
import { TourResultMember } from "./useAnalyzeOneDay";
import { convertMinutesToTime } from "../ReviewOneDay/ReviewOneDayExecution";

type Props = {
  tourId: string;
  day: string;
  memberId: string;
  currentTourResult: TourResultMember | undefined;
};

const TourStats = (props: Props) => {
  const { tourId, day, memberId, currentTourResult } = props;

  if (!currentTourResult) return <div>Nicht da</div>;
  return (
    <div>
      TourStats
      <div className="w-96 flex flex-col gap-2">
        <div>{currentTourResult?.day}</div>
        <div>{currentTourResult?.memberId}</div>
        <div>{currentTourResult?.tourId}</div>
        <div>{currentTourResult?.minStartTime}</div>
        <div>{currentTourResult?.maxEndTime}</div>
        <div>
          Sum Work {convertMinutesToTime(currentTourResult?.sumWorktime)}
        </div>
        <div>
          Bezahlt{" "}
          {convertMinutesToTime(currentTourResult?.calculatedPaidDuration)}
        </div>

        <div>Verhältnis {currentTourResult?.ratioWorkPaid}%</div>
        <div>
          Warten {convertMinutesToTime(currentTourResult?.sumWaitingTime)}
        </div>
        <div>Fahren {convertMinutesToTime(currentTourResult?.travelTime)}</div>
        <div>FahrtZuArbeit {currentTourResult?.ratioTravelPaid}</div>
      </div>
    </div>
  );
};

export default TourStats;
