import { EditIcon } from "lucide-react";
import React from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../UiComponents/Popover";
import { Button } from "../UiComponents/Button";
import { Input } from "../UiComponents/Input";
import { DialogClose } from "../UiComponents/Dialog";
import { PopoverClose } from "@radix-ui/react-popover";

type Props = {
  tourId: string;
  children: React.ReactNode;
};

const EditTourIdEasyPlan = (props: Props) => {
  const { tourId, children } = props;

  const [newTourId, setNewTourId] = React.useState<string>(tourId);
  return (
    <div className="flex flex-row gap-2 items-center">
      <Popover>
        <PopoverTrigger>
          <div className="flex flex-row items-center">
            <Button variant={"neutral-ghost"} size={"compact-sm"}>
              <EditIcon size={12} />
            </Button>
            <div className="flex flex-row gap-1">{children}</div>
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <div className="flex flex-col gap-2 p-4 bg-white shadow-md m-4">
            <div>{tourId}</div>
            <Input
              value={newTourId}
              onChange={(e) => {
                setNewTourId(e.target.value);
              }}
            />
            <div className="flex flex-row gap-2">
              <PopoverClose>
                <Button>Okay</Button>
                <Button>Abbrechen</Button>
              </PopoverClose>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default EditTourIdEasyPlan;
