import dayjs from "dayjs";
import { create } from "zustand";
import { client } from "../../urqlClient";
import { graphql } from "../../gql";
import { ArrElement } from "../../helpers/typeHelpers";
import { MissionPublications_MissionPublicationStatusQuery } from "../../gql/graphql";
import _ from "lodash";
import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";

//import { toast } from "react-hot-toast";
import { toast } from "react-toastify";

export const MissionPublications_MissionPublicationStatus = graphql(`
  query MissionPublications_MissionPublicationStatus(
    $filter: MostRecentMissionPublicationFilter!
  ) {
    mostRecentMissionPublications(filter: $filter) {
      id
      releaseStatus
      releaseStatusName
      day
      createdAt
      nextStatus_s {
        releaseStatus
        releaseStatusName
        description
      }
    }
  }
`);

const SmartUpdateMissionAnalytic_MissionPublicationStatus = graphql(`
  mutation SmartUpdateMissionAnalytic_MissionPublicationStatus(
    $startDay: String
    $endDay: String
  ) {
    smartUpdateMissionAnalytic(startDay: $startDay, endDay: $endDay)
  }
`);

export const AllMissionPublications_MissionPublicationStatus = graphql(`
  query AllMissionPublications_MissionPublicationStatus(
    $filter: MissionPublicationFilter!
  ) {
    missionPublications(filter: $filter) {
      id
      releaseStatus
      releaseStatusName
      day
      createdAt
      nextStatus_s {
        releaseStatus
        releaseStatusName
        description
      }
    }
  }
`);

export const SetMissionPublicationStatus_MissionPublicationStatus = graphql(`
  mutation SetMissionPublicationStatus_MissionPublicationStatus(
    $id: String!
    $status: String!
  ) {
    setMissionPublicationStatus(missionPublicationId: $id, status: $status) {
      id
      releaseStatus
      day
    }
  }
`);

// export const MissionPublication_SetRework = graphql(`
//   mutation MissionPublication_SetRework($id: String!) {
//     setMissionPublicationRework(missionPublicationId: $id) {
//       id
//       releaseStatus
//       day
//     }
//   }
// `);

// export const MissionPublication_SetCancel = graphql(`
//   mutation MissionPublication_SetCancel($id: String!) {
//     setMissionPublicationCancel(missionPublicationId: $id) {
//       id
//       releaseStatus
//       day
//     }
//   }
// `);

export const MissionPublication_Purge = graphql(`
  mutation MissionPublication_Purge($id: String!) {
    purgeMissionPublication(missionPublicationId: $id)
  }
`);

// export const MissionPublication_SetReleased = graphql(`
//   mutation MissionPublication_SetReleased($id: String!) {
//     setMissionPublicationReleased(missionPublicationId: $id) {
//       id
//       releaseStatus
//       day
//     }
//   }
// `);

export type LocalMissionPublications_MissionPublicationStatus = ArrElement<
  MissionPublications_MissionPublicationStatusQuery["mostRecentMissionPublications"]
>;

type MissionPublication = {
  minDay: string;
  maxDay: string;
  setCurrentPeriod: ({
    minDay,
    maxDay,
  }: {
    minDay: string;
    maxDay: string;
  }) => void;
  missionPublications: LocalMissionPublications_MissionPublicationStatus[];
  allMissionPublications: LocalMissionPublications_MissionPublicationStatus[];
  groupedMissionPublications: {
    day: string;
    missionPublications: LocalMissionPublications_MissionPublicationStatus[];
  }[];
  loadData: () => void;
  loadAllData: () => void;
  setMissionPublicationStatus: ({
    id,
    status,
  }: {
    id: string;
    status: string;
  }) => void;
  setMissionPublicationReleased: (id: string) => void;
  setMissionPublicationCancel: (id: string) => void;
  setMissionPublicationRework: (id: string) => void;
  purgeMissionPublication: (id: string) => void;
  smartUpdateMissionAnalytic: ({
    startDay,
    endDay,
  }: {
    startDay: string;
    endDay: string;
  }) => void;
};

export const useMissionPublication = create<MissionPublication>((set, get) => ({
  minDay: dayjs(getStartDateOfWeek()).format("YYYY-MM-DD"),
  maxDay: dayjs(getEndDateOfWeek()).format("YYYY-MM-DD"),
  groupedMissionPublications: [],
  allMissionPublications: [],
  missionPublications: [],
  setCurrentPeriod: ({ minDay, maxDay }) => {
    set({ minDay: minDay, maxDay: maxDay });
    get().loadData();
  },

  loadAllData: async () => {
    const { data } = await client

      .query(
        AllMissionPublications_MissionPublicationStatus,
        {
          filter: {
            minDate: get().minDay,
            maxDate: get().maxDay,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();
    if (data) {
      set({
        allMissionPublications: _.orderBy(
          data?.missionPublications || [],
          ["day", "createdAt"],
          ["desc", "desc"]
        ),
      });

      const groupedMissionPublications = _.groupBy(
        data?.missionPublications || [],
        "day"
      );
      set({
        groupedMissionPublications: Object.keys(groupedMissionPublications).map(
          (key) => ({
            day: key,
            missionPublications: _.orderBy(
              groupedMissionPublications[key],
              ["createdAt"],
              ["desc"]
            ),
          })
        ),
      });
    }
  },

  loadData: async () => {
    const { data } = await client
      .query(
        MissionPublications_MissionPublicationStatus,
        {
          filter: { minDate: get().minDay, maxDate: get().maxDay },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();
    if (data) {
      //console.log(data);
      set({
        missionPublications: _.orderBy(
          data?.mostRecentMissionPublications || [],
          ["day", "createdAt"],
          ["desc", "desc"]
        ),
      });
    }
  },
  setMissionPublicationReleased(id: string) {
    toast.error("Not implemented", {
      autoClose: 10000,
    });
    //   client
    //     .mutation(MissionPublication_SetReleased, { id })
    //     .toPromise()
    //     .then(() => {
    //       get().loadData();
    //       get().loadAllData();
    //     });
  },

  setMissionPublicationCancel(id: string) {
    toast.error("Not implemented", {
      autoClose: 10000,
    });
    // client
    //   .mutation(MissionPublication_SetCancel, { id })
    //   .toPromise()
    //   .then((result) => {
    //     console.log(result);
    //     if (result.error) {
    //       toast.error(result.error.message, {
    //         autoClose: 10000,
    //       });
    //       return;
    //     }

    //     get().loadData();
    //     get().loadAllData();
    //   })
    //   .catch((e) => {
    //     toast.error(e.message, {
    //       delay: 10000,
    //     });
    //   });
  },
  setMissionPublicationRework(id: string) {
    toast.error("Not implemented", {
      autoClose: 10000,
    });
    // client
    //   .mutation(MissionPublication_SetRework, { id })
    //   .toPromise()
    //   .then(() => {
    //     get().loadData();
    //     get().loadAllData();
    //   });
  },
  purgeMissionPublication(id: string) {
    client
      .mutation(MissionPublication_Purge, { id })
      .toPromise()
      .then(() => {
        get().loadData();
        get().loadAllData();
      });
  },
  smartUpdateMissionAnalytic({ startDay, endDay }) {
    client
      .mutation(SmartUpdateMissionAnalytic_MissionPublicationStatus, {
        startDay,
        endDay,
      })
      .toPromise()
      .then(() => {
        toast.success("Fertig " + startDay, {
          autoClose: 8000,
        });

        get().loadData();
        get().loadAllData();
      })
      .catch((e) => {
        toast.error(e.message, {});
      });
  },
  setMissionPublicationStatus({ id, status }) {
    client
      .mutation(SetMissionPublicationStatus_MissionPublicationStatus, {
        id,
        status,
      })
      .toPromise()
      .then(() => {
        get().loadData();
        get().loadAllData();
      });
  },
}));
