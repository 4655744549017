import React from "react";
import { useMemberSearchbox } from "../MemberSearchbox/useMemberSearchbox";
import { Edit, EditIcon } from "lucide-react";
import { Button } from "../UiComponents/Button";
import {
  Dialog,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../UiComponents/Dialog";
import MemberSearchbox from "../MemberSearchbox/MemberSearchbox";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";

type Props = {
  tourMemberId: string;
  tourId: string;
  children: React.ReactNode;
};

const EditTourMemberEasyPlan = (props: Props) => {
  const { selectedMember } = useMemberSearchbox();
  const setTourMemberId = useMissionEasyPlanNext(
    (state) => state.setTourMemberId
  );
  const { tourId, tourMemberId, children } = props;

  const [choosenMemberId, setChoosenMemberId] = React.useState<string>(
    props.tourMemberId
  );

  return (
    <div className="flex flex-row gap-2 items-center">
      <div>{children}</div>
      <Dialog>
        <DialogTrigger>
          <Button variant={"neutral-ghost"} size={"compact-sm"}>
            <EditIcon size={14} />
          </Button>
        </DialogTrigger>

        <DialogContent className="w-96 h-96">
          <DialogTitle>Neuen Mitarbeiter zuordnen</DialogTitle>
          <div className="flex flex-col justify-start">
            <div>{tourId}</div>
            <div>{choosenMemberId}</div>
            <MemberSearchbox
              currentMemberId={choosenMemberId}
              setMemberId={setChoosenMemberId}
            />
          </div>
          <DialogClose>
            <Button
              onClick={() => {
                setTourMemberId({
                  tourId: tourId,
                  memberId: choosenMemberId,
                });
              }}
            >
              Okay
            </Button>
            <Button>Abbrechen</Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditTourMemberEasyPlan;
