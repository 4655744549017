import React, { useEffect } from "react";
import {
  LocalTourAsString_MissionEasyPlanNext,
  time2Startmin,
  useMissionEasyPlanNext,
} from "./useMissionEasyPlanNext";
import { Edit2Icon, EditIcon } from "lucide-react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../UiComponents/Popover";
import { PopoverClose } from "@radix-ui/react-popover";
import { Button } from "../UiComponents/Button";
import dayjs from "dayjs";
import { startMin2Time } from "../MissionEasyPlan/MissionEasyPlan";

type Props = {
  tourId: string;
  tourStartMinuteOfDay: number;
};

const EditTourStartTime = (props: Props) => {
  const { tourId, tourStartMinuteOfDay } = props;
  const setTourStartTime = useMissionEasyPlanNext(
    (state) => state.setTourStartTime
  );
  const [newStartMinuteOfDay, setNewStartMinuteOfDay] =
    React.useState<number>(tourStartMinuteOfDay);

  const [startTimeInput, setStartTimeInput] = React.useState<string>(
    startMin2Time(tourStartMinuteOfDay)
  );

  return (
    <div className="">
      <Popover>
        <PopoverTrigger className="cursor-pointer">
          <div className="flex flex-row items-center gap-2">
            <div>{startMin2Time(tourStartMinuteOfDay)}</div>
            <div>
              <EditIcon size={14} />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <div className="flex flex-row gap-2 p-8 bg-white shadow-md m-4">
            <Button
              onClick={() => {
                setNewStartMinuteOfDay(newStartMinuteOfDay - 5);
                setStartTimeInput(startMin2Time(newStartMinuteOfDay - 5));
              }}
              size={"compact-sm"}
            >
              -5
            </Button>
            <div>
              <input
                type="time"
                value={startTimeInput}
                onChange={(e) => {
                  setStartTimeInput(e.target.value);
                  setNewStartMinuteOfDay(time2Startmin(e.target.value));
                }}
              />
            </div>
            <Button
              onClick={() => {
                setNewStartMinuteOfDay(newStartMinuteOfDay + 5);
                setStartTimeInput(startMin2Time(newStartMinuteOfDay + 5));
              }}
              size={"compact-sm"}
            >
              +5
            </Button>
            <PopoverClose>
              <Button
                onClick={() => {
                  setTourStartTime({
                    tourId,
                    startMinuteOfDay: newStartMinuteOfDay,
                  });
                }}
                size={"compact-sm"}
              >
                Okay
              </Button>

              <Button variant={"neutral-ghost"}>Abbrechen</Button>
            </PopoverClose>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default EditTourStartTime;
