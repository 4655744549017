import React, { useEffect } from "react";
import LoginButton from "./Auth0/LoginButton";
import inMemoryJwt from "./Auth0/inMemoryJwt";

import { socket } from "../socketClient";

import { toast } from "react-toastify";
import { myClientId } from "../App";

type Props = {};

export const ServerMessage = (props: Props) => {
  const token = inMemoryJwt.getToken();

  //console.log("Token", token);

  if (token === null) {
    return (
      <div>
        Getting Token
        <LoginButton />
      </div>
    );
  }

  return <ServerMessage1 />;
};

const ServerMessage1 = (props: Props) => {
  const [infoText, setInfoText] = React.useState("");
  const [myClientId, setMyClientId] = React.useState("");
  useEffect(() => {
    socket.on("info-from-server", (args) => {
      console.log("hurga " + myClientId, args);
      setInfoText(args);
    });

    socket.on("connect", () => {
      console.log("connected", socket.id);
      toast.success("Connected to Server" + socket.id);
    });

    socket.on("disconnect", () => {
      console.log("disconnected");
      toast.error("Disconnected from Server");
    });
  }, []);

  return (
    <div className="flex flex-row gap-2">
      <div>{infoText}</div>
      <div>{socket.id}</div>
    </div>
  );
};

export default ServerMessage;
