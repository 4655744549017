import { scan } from "react-scan";
import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react";
import { PostHogProvider } from "posthog-js/react";

import "./index.css";
import App from "./App";
import "typeface-roboto";

import { ToastBar, Toaster, toast } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button } from "./Components/UiComponents/Button";

import "dayjs/locale/de"; // Import German locale
import dayjs from "dayjs";

// scan({
//   enabled: true,
// });

dayjs.locale("de");

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

ReactDOM.render(
  <React.StrictMode>
    {/* <KindeProvider
      clientId="9d80b74a9ad847d297d8efa857b9d9c0"
      domain="https://kinde-auth.pzh-app.de"
      audience="graphql-server.pzh-app.de"
      redirectUri={window.location.origin + "/welcome"}
      logoutUri={window.location.origin + "/welcome"}
    > */}
    {/* <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    > */}
    <Auth0Provider
      //domain="pzh.eu.auth0.com"
      domain="auth.pzh-app.de"
      //clientId="JKUJR5b2La9f4zL8zThN7k7koO51tRlj"
      clientId="T3V62zbhCZfuzMhFGmr1G8ULH2CoFap3"
      authorizationParams={{
        //redirect_uri: window.location.origin + "/calendar",
        redirect_uri: window.location.origin + "/welcome",
        //audience: "https://missions.pzh.de",
        audience: "https://pzh-app.eu.auth0.com/api/v2/",
        //audiance: "https://roles.pzh-app.de/",

        // useRefreshTokens: true,
        // cacheLocation: "localstorage",
        scope: "profile roles read:current_user update:current_user_metadata",
      }}
      //   redirectUri={window.location.origin + "/calendar"}

      //scope="read:current_user update:current_user_metadata"
      //cacheLocation='localstorage'
    >
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={true}
            // hideProgressBar={false}
            //newestOnTop={false}
            closeOnClick
            // rtl={false}
            // pauseOnFocusLoss
            // draggable
            // pauseOnHover
            // theme="light"
          />

          <Toaster position="bottom-right">
            {(t) => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <>
                    {t.type === "error" ? (
                      <>
                        <Button
                          variant={"danger"}
                          onClick={() => toast.dismiss(t.id)}
                        >
                          okay
                        </Button>
                        {message}
                      </>
                    ) : (
                      <>
                        {icon}
                        {message}
                      </>
                    )}
                  </>
                )}
              </ToastBar>
            )}
          </Toaster>
        </Suspense>
      </Router>
    </Auth0Provider>
    {/* </PostHogProvider> */}
    {/* </KindeProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);
