import React, { useEffect } from "react";
import { usePatientTASMissionList } from "./usePatientTASMissionList";
import dayjs from "dayjs";
import { cn } from "../../helpers/utils";

type Props = {
  minDay: string;
  maxDay: string;
  patientId: string;
};

const PatientTASMissionList = (props: Props) => {
  const { setSelectedPatientId, setTimeRange, loadData, tasMissions } =
    usePatientTASMissionList();

  const { minDay, maxDay, patientId } = props;

  useEffect(() => {
    setTimeRange(minDay, maxDay);
    setSelectedPatientId(patientId);
    loadData();
  }, [minDay, maxDay, patientId]);

  return (
    <div>
      {tasMissions.map((m) => {
        const missionInSync = dayjs(m.updatedAt).isSame(
          dayjs(Number(m.bookedMission.updatedAt)),
          "second"
        );

        return (
          <div
            key={"xxx" + m.missionId}
            className="text-xs flex flex-col gap-1"
          >
            <div
              className={cn("flex flex-row gap-1", {
                "bg-red-200": !missionInSync,
              })}
            >
              <div>{dayjs(m.day).format("DD, dd")}</div>
              <div>{dayjs(m.startTS).format("HH:mm")}</div>
              <div>{m.tourId}</div>
              <div>{m.member?.shortName}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PatientTASMissionList;
