import React from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "../UiComponents/Dialog";
import { Button } from "../UiComponents/Button";
import { FilePlusIcon } from "lucide-react";
import dayjs from "dayjs";
import PatientSearchbox from "../PatientSearchbox/PatientSearchbox";
import { LocalPatientSearchBox } from "../PatientSearchbox/usePatientSearchbox";
import { DisplayPatientInfo } from "../Patient/DisplayPatientInfo";
import DisplayScheduleByDay from "../Schedule/DisplayScheduleByDay";
import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";
import ActionClipboard from "../ActionClipboard/ActionClipboard";
import { Label } from "../UiComponents/Label";
import { Input } from "../UiComponents/Input";
import toast from "react-hot-toast";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";

const selectableMinutes = ["05", "10", "15", "20", "25", "30"];
type Props = {
  tourId: string;
  day: string;
};

const CreateNewMissionEasyPlan = (props: Props) => {
  const { tourId, day } = props;

  const createNewMission = useMissionEasyPlanNext(
    (state) => state.createNewMission
  );

  const [selectedPatient, setSelectedPatient] = React.useState<
    LocalPatientSearchBox | undefined
  >(undefined);

  const [duration, setDuration] = React.useState<string>("05");

  const [selectedPatientId, setSelectedPatientId] = React.useState<
    string | null
  >(null);

  const { getActionClipbaordContentAsCoded } = useActionClipboard();

  const startDay = getStartDateOfWeek(day);
  const endDay = getEndDateOfWeek(day);

  return (
    <div>
      <Dialog>
        <DialogTrigger>
          <Button variant={"neutral-ghost"} size={"compact-sm"}>
            <FilePlusIcon size={18} />
          </Button>
        </DialogTrigger>
        <DialogContent className=" flex flex-col justify-start w-[80%] h-[80%]">
          <DialogTitle>
            Neuer Einsatz {tourId} {dayjs(day).format("ddd DD. MMM")}
          </DialogTitle>
          <div className="flex flex-row justify-start">
            <div className="w-96 ">
              <DisplayPatientInfo patientId={selectedPatientId || ""} />
            </div>
            <PatientSearchbox
              onSelect={setSelectedPatientId}
              setPatient={(patient) => {
                setSelectedPatient(patient);
              }}
            />
          </div>
          <div>
            <div className="text-xs">
              <DisplayScheduleByDay
                patientId={selectedPatientId || ""}
                minDay={dayjs(startDay).format("YYYY-MM-DD")}
                maxDay={dayjs(endDay).format("YYYY-MM-DD")}
              />
            </div>
            <div className="w-full flex flex-row items-center">
              <ActionClipboard />
              <Label htmlFor="duration">
                Dauer (
                {selectableMinutes.map((m) => {
                  return (
                    <span>
                      <Button
                        onClick={() => {
                          setDuration(m);
                        }}
                        size={"compact-sm"}
                        variant={"primary-ghost"}
                      >
                        {m}
                      </Button>
                    </span>
                  );
                })}
                )
              </Label>

              <Input
                type="number"
                id="duration"
                placeholder="Dauer"
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
                step="5"
                min="05"
                max="120"
              />
              <DialogClose>
                <Button>Abbrechen</Button>

                <Button
                  disabled={!selectedPatient}
                  onClick={() => {
                    if (!selectedPatient) {
                      toast.error("Bitte Patient auswählen");
                      return;
                    }
                    createNewMission({
                      tourId,
                      patientId: selectedPatientId || "",
                      duration_min: parseInt(duration),
                      action_coded: getActionClipbaordContentAsCoded(),
                    });
                  }}
                >
                  Anlegen
                </Button>
              </DialogClose>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateNewMissionEasyPlan;
