import { ArrElement } from "@/src/helpers/typeHelpers";
import { graphql } from "../../gql";
import { create } from "zustand";
import { client } from "../../urqlClient";
import _, { has, slice, update } from "lodash";

import {
  TourAsStrings_MissionEasyPlanQuery,
  CreateStringAsTourInput,
  GetManyTravelTimes_MissionEasyPlanNextQuery,
} from "@/src/gql/graphql";
import { to } from "@react-spring/web";
import produce from "immer";
import { Active, Over } from "@dnd-kit/core";
import toast from "react-hot-toast";
import { t } from "xstate";
import { createId } from "@paralleldrive/cuid2";

export type TourStatistic = {
  workTime: number;
  pauseTime: number;
  travelTime: number;
  paidTime: number;
};

export const time2Startmin = (time: string) => {
  const _time = time.split(":");
  return parseInt(_time[0]) * 60 + parseInt(_time[1]);
};

export const duration2Time = (duration: number) => {
  const _hours = Math.floor(duration / 60);
  const _minutes = duration - _hours * 60;

  // padding 2 digist with "0"

  const _minutesStr = _minutes.toString().padStart(2, "0");

  return `${_hours}:${_minutesStr}`;
};

const NearestPatients_MissionEasyPlanNext = graphql(/* GraphQL */ `
  query NearestPatients_MissionEasyPlanNext(
    $patientId: String!
    $minutes: Int!
  ) {
    nearByPatients(patientId: $patientId, minutes: $minutes) {
      patientId
    }
  }
`);

const UpdateMissionsFromTourAsString_MissionEasyPlanNewxt = graphql(
  /* GraphQL */ `
    mutation UpdateMissionsFromTourAsString_MissionEasyPlanNext($day: String!) {
      updateMissionsFromTourAsString(day: $day)
    }
  `
);

const GetManyTravelTimes_MissionEasyPlanNext = graphql(/* GraphQL */ `
  query GetManyTravelTimes_MissionEasyPlanNext(
    $input: [GetManyTravelTimesInput!]!
  ) {
    getManyTravelTimes(input: $input) {
      fromPatientId
      toPatientId
      travelTime_min
      distance_km
    }
  }
`);

type LocalTravelTime_MissionEasyPlanNext = ArrElement<
  GetManyTravelTimes_MissionEasyPlanNextQuery["getManyTravelTimes"]
>;

const SingleDistance_MissionEasyPlanNext = graphql(/* GraphQL */ `
  query SingleDistance_MissionEasyPlayNext(
    $fromPatientId: String!
    $toPatientId: String!
  ) {
    singleDistance(fromPatientId: $fromPatientId, toPatientId: $toPatientId) {
      fromPatientId
      toPatientId
      travelTime_min
      distance_km
    }
  }
`);

const MissionFragment_MissionEasyPlan = graphql(/* GraphQL */ `
  fragment MissionFragment_MissionEasyPlan on MissionFromTourCoded {
    missionId
    patientId
    patient {
      shortName
      isPrivate
    }
  }
`);

const CreateTourAsStrings_MissionEasyPlan = graphql(/* GraphQL */ `
  mutation CreateTourAsStrings_MissionEasyPlan(
    $tours: [CreateStringAsTourInput!]!
  ) {
    createManyToursAsString(tours: $tours) {
      day
      tourId
      startTime
      lastMissionsUpdate
      startMinuteOfDay
      createdAt
      missionPublicationId
      member {
        shortName
        memberId
        hasQualificationLevel
        hasExamination
      }

      inActiveMissions {
        missionId
        patientId
        time2nextMission
        startTimeMinuteOfDay
        actionsCoded
        connectedMissionId
        minReqQualification
        duration_min
        hasConflictAbsence
        noGeoData
        type
        patient {
          shortName
          isPrivate
          lastName
          firstName
        }
      }

      activeMissions {
        missionId
        patientId
        time2nextMission
        startTimeMinuteOfDay
        minReqQualification
        actionsCoded
        connectedMissionId
        duration_min
        noGeoData
        hasConflictAbsence
        type
        patient {
          shortName
          isPrivate
          lastName
          firstName
        }
      }
    }
  }
`);

const TourAsStrings_MissionEasyPlan = graphql(/* GraphQL */ `
  query TourAsStrings_MissionEasyPlan($day: String!) {
    tourAsStrings(day: $day) {
      day
      tourId
      startTime
      startMinuteOfDay
      lastMissionsUpdate
      createdAt
      missionPublicationId

      member {
        shortName
        memberId
        hasQualificationLevel
        hasExamination
      }

      inActiveMissions {
        missionId
        patientId
        time2nextMission
        startTimeMinuteOfDay
        actionsCoded
        connectedMissionId
        minReqQualification
        hasConflictAbsence
        duration_min
        noGeoData
        type
        patient {
          shortName
          isPrivate
          lastName
          firstName
        }
      }

      activeMissions {
        missionId
        patientId
        hasConflictAbsence
        time2nextMission
        startTimeMinuteOfDay
        minReqQualification
        actionsCoded
        connectedMissionId
        duration_min
        noGeoData
        type
        patient {
          shortName
          isPrivate
          lastName
          firstName
        }
      }
    }
  }
`);

export type LocalTourAsString_MissionEasyPlanNext = ArrElement<
  TourAsStrings_MissionEasyPlanQuery["tourAsStrings"]
>;

export type LocalMission_MissionEasyPlanNext = ArrElement<
  LocalTourAsString_MissionEasyPlanNext["activeMissions"]
>;

type SelectedElement = {
  element: LocalMission_MissionEasyPlanNext;
  index: number;
  tourId: string;
};

type MatchingElement = {
  element: LocalMission_MissionEasyPlanNext;
  tourId: string;
};

type MissionEasyPlanNextState = {
  selectedDay: string;
  isLoading: boolean;
  setSelectedDay: (day: string) => void;
  tours: LocalTourAsString_MissionEasyPlanNext[];
  matchingElements: MatchingElement[];
  setMatchingElementsPatient: (searchText: string) => void;
  setMatchingElementsNoAction: () => void;
  scrollToMissionId: string;
  setScrollToMissionId: (missionId: string) => void;

  setTourStartTime: ({
    tourId,
    startMinuteOfDay,
  }: {
    tourId: string;
    startMinuteOfDay: number;
  }) => void;
  setTourMemberId: ({
    tourId,
    memberId,
  }: {
    tourId: string;
    memberId: string;
  }) => void;

  createPause: ({
    tourId,
    duration_min,
    index,
  }: {
    tourId: string;
    duration_min: number;
    index: number;
  }) => void;
  removePause: ({ tourId, index }: { tourId: string; index: number }) => void;
  setMissionDuration: ({
    tourId,
    duration_min,
    index,
  }: {
    tourId: string;
    duration_min: number;
    index: number;
  }) => void;

  changeMissionActions: ({
    tourId,
    missionId,
    actionsCoded,
  }: {
    tourId: string;
    missionId: string;
    actionsCoded: string;
  }) => void;

  lastMissionsUpdate: string;
  autoDistanceEnabled: boolean;
  selectedTourId: string;
  setSelectedTourId: (selectedTourId: string) => void;
  createNewTour: ({
    startTime,
    tourId,
  }: {
    startTime: string;
    tourId: string;
  }) => void;
  selectedTour: LocalTourAsString_MissionEasyPlanNext | undefined;
  createNewMission: ({
    tourId,
    patientId,
    duration_min,
    action_coded,
  }: {
    tourId: string;
    patientId: string;
    duration_min: number;
    action_coded: string;
  }) => void;

  getTourStatistic: (tourId: string) => TourStatistic;

  patientDistanceMap: Map<string, number> | undefined;
  calculatePatientDistances: ({ patientId }: { patientId: string }) => void;

  setAutoDistanceEnabled: (autoDistanceEnabled: boolean) => void;
  loadData: () => void;

  selectedElement: SelectedElement | null;
  setSelectedElement: ({
    selectedElement,
  }: {
    selectedElement: SelectedElement | null;
  }) => void;
  calculateStartTimes: ({
    tourId,
    travelTimes,
  }: {
    tourId: string;
    travelTimes: LocalTravelTime_MissionEasyPlanNext[];
  }) => void;
  handleDragEndService: ({
    active,
    over,
  }: {
    active: Active;
    over: Over | null;
  }) => void;
  activateMission: ({
    missionId,
    tourId,
    index,
  }: {
    missionId: string;
    tourId: string;
    index: number;
  }) => void;
  inActivateMission: ({
    missionId,
    tourId,
    index,
  }: {
    missionId: string;
    tourId: string;
    index: number;
  }) => void;
  saveTours: ({ tourIds }: { tourIds: string[] }) => void;
  routingAllTours: () => void;
  updateMissionsOnDatabase: () => void;
};

export const useMissionEasyPlanNext = create<MissionEasyPlanNextState>(
  (set, get) => ({
    selectedDay: "2025-01-12",
    tours: [],
    isLoading: false,
    matchingElements: [],
    lastMissionsUpdate: "",
    selectedTourId: "",
    selectedTour: undefined,
    scrollToMissionId: "",
    setScrollToMissionId: (missionId: string) => {
      set({ scrollToMissionId: missionId });
    },

    setSelectedTourId: (selectedTourId: string) => {
      const { tours } = get();
      const selectedTour = tours.find((tour) => tour.tourId === selectedTourId);
      set({ selectedTourId, selectedTour });
    },
    patientDistanceMap: undefined,
    autoDistanceEnabled: false,
    setAutoDistanceEnabled: (autoDistanceEnabled: boolean) => {
      const selectedElement = get().selectedElement;

      if (selectedElement && autoDistanceEnabled) {
        get().calculatePatientDistances({
          patientId: selectedElement.element.patientId,
        });
      }

      set({ autoDistanceEnabled, patientDistanceMap: undefined });
    },
    setSelectedDay: (selectedDay: string) => {
      set({ selectedDay });
      get().loadData();
    },
    selectedElement: null,
    loadData: () => {
      const { selectedDay } = get();

      set({
        tours: [],
        selectedElement: null,
        matchingElements: [],
        isLoading: true,
      });

      console.log("selectedDay", selectedDay);

      toast.promise(
        client
          .query(
            TourAsStrings_MissionEasyPlan,
            { day: selectedDay },
            { requestPolicy: "network-only" }
          )
          .toPromise()
          .then((result) => {
            if (result.data) {
              console.log("result.data", result);
              const _tours = result.data.tourAsStrings;

              if (!_tours) {
                toast.error("LoadData: Keine Touren gefunden " + result.error);
                return;
              }

              const _sortedTours = _.orderBy(_tours, ["tourId"], ["asc"]);

              console.log("result.data", _sortedTours);
              set({
                tours: _sortedTours,
                lastMissionsUpdate: _tours[0].lastMissionsUpdate,
                isLoading: false,
              });
            }
          })
          .catch((error) => {
            console.error("error", error);
            set({ isLoading: false });
          }),
        {
          loading: "Lade Daten...",
          success: "Daten geladen",
          error: "Fehler beim Laden",
        },
        {
          position: "top-center",
        }
      );
    },
    calculateStartTimes: async ({ tourId, travelTimes }) => {
      const { tours } = get();
      const tour = tours.find((tour) => tour.tourId === tourId);

      if (!tour) {
        return;
      }

      const startTime = tour.startTime;
      const startMinuteOfDay = tour.startMinuteOfDay;
      const _activeMissions = tour.activeMissions;

      const _newMissions = [] as LocalMission_MissionEasyPlanNext[];
      let runningMinuteOfDay = startMinuteOfDay;
      let lastPatientId = "";

      for (let i = 0; i < _activeMissions.length; i++) {
        const mission = { ..._activeMissions[i] };

        if (i < _activeMissions.length - 1) {
          const nexMission = _activeMissions[i + 1];

          let _fromPatientId = mission.patientId;
          let _toPatientId = nexMission.patientId;

          if (_fromPatientId === "0") {
            _fromPatientId = lastPatientId;
          }
          if (_toPatientId === "0") {
            _toPatientId = _fromPatientId;
          }

          let data: LocalTravelTime_MissionEasyPlanNext | undefined = undefined;

          if (_fromPatientId !== _toPatientId) {
            data = travelTimes.find(
              (tt) =>
                tt.fromPatientId === _fromPatientId &&
                tt.toPatientId === _toPatientId
            );
          } else {
            data = {
              fromPatientId: _fromPatientId,
              toPatientId: _toPatientId,
              travelTime_min: 0,
              distance_km: 0,
            };
          }

          if (!data) {
            toast.error(
              "Keine Daten gefunden " + _fromPatientId + "-> " + _toPatientId
            );
            return;
          }

          // if (!data.singleDistance) {
          //   toast.error(
          //     "Keine Daten gefunden " +
          //       mission.patientId +
          //       " " +
          //       nexMission.patientId
          //   );
          //   return;
          // }

          mission.time2nextMission = _.round(data.travelTime_min, 0);
        }
        mission.startTimeMinuteOfDay = _.round(runningMinuteOfDay, 0);

        if (mission.patientId !== "0") {
          lastPatientId = mission.patientId;
        }

        runningMinuteOfDay += mission.duration_min + mission.time2nextMission;
        _newMissions.push({
          ...mission,
        });
      }

      // console.log("mission", _newMissions);

      const _newTours = produce(tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          return;
        }

        draft[tourIndex].activeMissions = _newMissions;
        return draft;
      });
      // console.log("_newTours", _newTours);
      set({ tours: _newTours });
    },
    handleDragEndService: ({ active, over }) => {
      console.log("handleDragEndService", active, over);

      if (!over) {
        toast.error("Kein Ziel gefunden");
        return;
      }

      if (
        active.data.current?.element.type === "P" &&
        over.data.current?.index === 0
      ) {
        toast.error("Pause kann nicht Anfang sein");
        return;
      }

      // pause can not moved over to other tour
      if (
        active.data.current?.element.type === "P" &&
        over.data.current?.tourId !== active.data.current?.tourId
      ) {
        toast.error("Pause kann nicht über Touren verschoben werden");
        return;
      }

      if (active.id === over.id) {
        const _selectedElement = get().selectedElement;

        if (
          _selectedElement?.element.missionId ===
          active.data.current?.element.missionId
        ) {
          get().setSelectedElement({ selectedElement: null });
          set({ patientDistanceMap: undefined });
          return;
        }

        get().setSelectedElement({
          selectedElement: {
            element: active.data.current?.element,
            index: active.data.current?.index,
            tourId: active.data.current?.tourId || "",
          },
        });
        if (get().autoDistanceEnabled) {
          get().calculatePatientDistances({
            patientId: active.data.current?.element.patientId,
          });
        }

        return;
      }

      if (active.data.current?.tourId === over.data.current?.tourId) {
        // verschieben innerhalb einer Tour

        const { tours } = get();
        const tour = tours.find(
          (tour) => tour.tourId === active.data.current?.tourId
        );

        if (!tour) {
          toast.error("Tour nicht gefunden");
          return;
        }

        const _activeMissions = tour.activeMissions;

        const _newMissions = produce(_activeMissions, (draft) => {
          const activeIndex = active.data.current?.index;

          const overIndex = over.data.current?.index;

          console.log("activeIndex", activeIndex);
          console.log("overIndex", overIndex);

          if (activeIndex === -1 || overIndex === -1) {
            toast.error("Mission nicht gefunden");
            return;
          }

          const activeMission = draft[activeIndex];
          console.log("activeMission", activeMission);
          draft.splice(activeIndex, 1);
          draft.splice(overIndex, 0, activeMission);
          return draft;
        });

        console.log("_newMissions", _newMissions);

        const _newTours = produce(tours, (draft) => {
          const tourIndex = draft.findIndex(
            (tour) => tour.tourId === active.data.current?.tourId
          );
          if (tourIndex === -1) {
            return;
          }

          draft[tourIndex].activeMissions = _newMissions;
          return draft;
        });

        set({ tours: _newTours });
        get().saveTours({ tourIds: [active.data.current?.tourId] });
      } else {
        // verschieben zwischen zwei Touren
        const { tours } = get();
        const fromTour = tours.find(
          (tour) => tour.tourId === active.data.current?.tourId
        );
        const toTour = tours.find(
          (tour) => tour.tourId === over.data.current?.tourId
        );

        if (!fromTour || !toTour) {
          toast.error("Tour nicht gefunden");
          return;
        }

        const _fromActiveMissions = fromTour.activeMissions;
        const _toActiveMissions = toTour.activeMissions;

        const _newFromMissions = produce(_fromActiveMissions, (draft) => {
          const activeIndex = active.data.current?.index;

          if (activeIndex === -1) {
            toast.error("Mission nicht gefunden");
            return;
          }

          const activeMission = draft[activeIndex];
          draft.splice(activeIndex, 1);
          return draft;
        });

        const _newToMissions = produce(_toActiveMissions, (draft) => {
          const overIndex = over.data.current?.index;

          if (overIndex === -1) {
            toast.error("Mission nicht gefunden");
            return;
          }

          draft.splice(overIndex, 0, active.data.current?.element);
          return draft;
        });

        const _newTours = produce(tours, (draft) => {
          const fromTourIndex = draft.findIndex(
            (tour) => tour.tourId === active.data.current?.tourId
          );
          if (fromTourIndex === -1) {
            return;
          }

          draft[fromTourIndex].activeMissions = _newFromMissions;

          const toTourIndex = draft.findIndex(
            (tour) => tour.tourId === over.data.current?.tourId
          );
          if (toTourIndex === -1) {
            return;
          }

          draft[toTourIndex].activeMissions = _newToMissions;

          return draft;
        });

        set({ tours: _newTours, selectedElement: null });

        get().saveTours({
          tourIds: [active.data.current?.tourId, over.data.current?.tourId],
        });
      }
    },
    activateMission({ missionId, tourId, index }) {
      const _tour = get().tours.find((tour) => tour.tourId === tourId);

      if (!_tour) {
        toast.error("Tour nicht gefunden");
        return;
      }

      const _mission = _tour.inActiveMissions[index];

      if (!_mission) {
        toast.error("Mission nicht gefunden");
        return;
      }

      const _newActiveMissions = produce(_tour.activeMissions, (draft) => {
        draft.push(_mission);
        return draft;
      });

      const _newInActiveMissions = produce(_tour.inActiveMissions, (draft) => {
        draft.splice(index, 1);
        return draft;
      });

      const _newTours = produce(get().tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          return;
        }

        draft[tourIndex].activeMissions = _newActiveMissions;
        draft[tourIndex].inActiveMissions = _newInActiveMissions;
        return draft;
      });

      set({ tours: _newTours });
      get().saveTours({ tourIds: [tourId] });
    },
    inActivateMission({ missionId, tourId, index }) {
      const _tour = get().tours.find((tour) => tour.tourId === tourId);

      if (!_tour) {
        toast.error("Tour nicht gefunden");
        return;
      }

      const _mission = _tour.activeMissions[index];

      if (!_mission) {
        toast.error("Mission nicht gefunden");
        return;
      }

      const _newInActiveMissions = produce(_tour.inActiveMissions, (draft) => {
        draft.push(_mission);
        return draft;
      });

      const _newActiveMissions = produce(_tour.activeMissions, (draft) => {
        draft.splice(index, 1);
        return draft;
      });

      const _newTours = produce(get().tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          return;
        }

        draft[tourIndex].activeMissions = _newActiveMissions;
        draft[tourIndex].inActiveMissions = _newInActiveMissions;
        return draft;
      });

      set({ tours: _newTours });
      get().saveTours({ tourIds: [tourId] });
    },
    setSelectedElement({ selectedElement }) {
      set({ selectedElement, patientDistanceMap: undefined });
    },
    saveTours: ({ tourIds }) => {
      const _tours = get().tours;

      const _toursToUpdate = [] as CreateStringAsTourInput[];

      for (let tourId of tourIds) {
        const _tour = _tours.find((tour) => tour.tourId === tourId);
        if (!_tour) {
          toast.error("Tour nicht gefunden");
          return;
        }
        let activeTourString = "";
        let inActiveTourString = "";
        const _activeMissions = _tour.activeMissions;
        const _inActiveMissions = _tour.inActiveMissions;
        for (let m of _activeMissions) {
          const _duraion = m.duration_min;
          const _actionCoded = m.actionsCoded;
          activeTourString += `<ID=${m.missionId}&P=${m.patientId}&D=${_duraion}&A=${_actionCoded}&C_ID=${m.connectedMissionId}&TY=${m.type}>`;
        }
        for (let m of _inActiveMissions) {
          const _duraion = m.duration_min;
          const _actionCoded = m.actionsCoded;
          inActiveTourString += `<ID=${m.missionId}&P=${m.patientId}&D=${_duraion}&A=${_actionCoded}&C_ID=${m.connectedMissionId}&TY=${m.type}>`;
        }
        const tourToUpdate: CreateStringAsTourInput = {
          day: get().selectedDay,
          tourId: tourId,
          startTime: _tour.startTime,
          activePatients_coded: activeTourString,
          inActivePatients_coded: inActiveTourString,
          missionPublicationId: _tour.missionPublicationId,
          memberId: _tour.member?.memberId || "",
        };

        _toursToUpdate.push(tourToUpdate);
      }

      client
        .mutation(CreateTourAsStrings_MissionEasyPlan, {
          tours: _toursToUpdate,
        })
        .toPromise()
        .then((result) => {
          if (result.data) {
            console.log("result.data", result.data);

            const _tours = get().tours;
            const _retTours = result.data.createManyToursAsString;

            if (!_retTours) {
              toast.error("Fehler beim Speichern");
              return;
            }

            const _newTours = produce(_tours, (draft) => {
              for (let tour of _retTours) {
                const tourIndex = draft.findIndex(
                  (tourX) => tourX.tourId === tour.tourId
                );
                if (tourIndex === -1) {
                  return;
                }
                draft[tourIndex] = tour;
              }
              return draft;
            });

            set({ tours: _newTours });
            toast.success(
              result.data.createManyToursAsString?.length +
                " Touren  gespeichert"
            );
          }
        })
        .catch((error) => {
          console.error("error", error);
          toast.error("Fehler beim Speichern");
        });
    },
    routingAllTours: async () => {
      const { tours } = get();

      console.log("routing all tours", tours);

      const _dictinctTourIds = _.uniq(tours.map((tour) => tour.tourId));

      const _allTravelTimesInput = [] as {
        fromPatientId: string;
        toPatientId: string;
      }[];

      for (let tour of tours) {
        const _activeMissions = _.filter(
          tour.activeMissions,
          (m) => m.patientId !== "0"
        );

        // console.log("actriveMissions", _activeMissions);

        for (let i = 0; i < _activeMissions.length - 1; i++) {
          const mission1 = _activeMissions[i];
          const mission2 = _activeMissions[i + 1];

          _allTravelTimesInput.push({
            fromPatientId: mission1.patientId,
            toPatientId: mission2.patientId,
          });
        }
      }

      console.log("_allTravelTimesInput", _allTravelTimesInput);

      toast.promise(
        client
          .query(
            GetManyTravelTimes_MissionEasyPlanNext,
            {
              input: _allTravelTimesInput,
            },
            {
              requestPolicy: "network-only",
            }
          )
          .toPromise()
          .then((result) => {
            if (result.data) {
              console.log("result.data", result.data);

              for (let _tourId of _dictinctTourIds) {
                get().calculateStartTimes({
                  tourId: _tourId,
                  travelTimes: result.data.getManyTravelTimes,
                });
              }
            }
          })
          .catch((error) => {
            toast.error("Fehler beim Routing", error);
            console.error("error", error);
          }),
        {
          loading: "Routing...",
          success: "Routing erfolgreich",
          error: "Fehler beim Routing",
        },
        {
          position: "top-center",
        }
      );
    },
    updateMissionsOnDatabase: () => {
      const { selectedDay } = get();

      set({ isLoading: true });

      toast.promise(
        client
          .mutation(UpdateMissionsFromTourAsString_MissionEasyPlanNewxt, {
            day: selectedDay,
          })
          .toPromise()
          .then((result) => {
            set({ isLoading: false });
            if (result.data) {
              get().loadData();
              toast.success("Update erfolgreich");
            } else {
              toast.error("Fehler beim Update" + result.error);
            }
          })
          .catch((error) => {
            set({ isLoading: false });
            console.error("error", error);
            toast.error("Fehler beim Update");
          }),
        {
          loading: "Update...",
          success: "Update erfolgreich",
          error: "Fehler beim Update",
        },
        {
          position: "top-center",
        }
      );
    },
    calculatePatientDistances: async ({ patientId }) => {
      set({
        patientDistanceMap: undefined,
      });

      const { data } = await client
        .query(NearestPatients_MissionEasyPlanNext, {
          patientId: patientId,
          minutes: 3,
        })
        .toPromise();
      console.log(data);
      const min_3_PatientsIds = data?.nearByPatients.map((p) => p.patientId);

      const { data: data5 } = await client
        .query(NearestPatients_MissionEasyPlanNext, {
          patientId: patientId,
          minutes: 5,
        })
        .toPromise();
      console.log(data5);
      const min_5_PatientsIds = data5?.nearByPatients.map((p) => p.patientId);

      const { data: data7 } = await client
        .query(NearestPatients_MissionEasyPlanNext, {
          patientId: patientId,
          minutes: 7,
        })
        .toPromise();
      console.log(data7);
      const min_7_PatientsIds = data7?.nearByPatients.map((p) => p.patientId);

      const { data: data12 } = await client
        .query(NearestPatients_MissionEasyPlanNext, {
          patientId: patientId,
          minutes: 12,
        })
        .toPromise();
      console.log(data12);
      const min_12_PatientsIds = data12?.nearByPatients.map((p) => p.patientId);

      const { data: data17 } = await client
        .query(NearestPatients_MissionEasyPlanNext, {
          patientId: patientId,
          minutes: 17,
        })
        .toPromise();
      console.log(data12);
      const min_17_PatientsIds = data17?.nearByPatients.map((p) => p.patientId);

      const _newDistanceMap = new Map<string, number>();

      if (min_17_PatientsIds) {
        for (let p of min_17_PatientsIds) {
          _newDistanceMap.set(p, 17);
        }
      }

      if (min_12_PatientsIds) {
        for (let p of min_12_PatientsIds) {
          _newDistanceMap.set(p, 12);
        }
      }
      if (min_7_PatientsIds) {
        for (let p of min_7_PatientsIds) {
          _newDistanceMap.set(p, 7);
        }
      }
      if (min_5_PatientsIds) {
        for (let p of min_5_PatientsIds) {
          _newDistanceMap.set(p, 5);
        }
      }
      if (min_3_PatientsIds) {
        for (let p of min_3_PatientsIds) {
          _newDistanceMap.set(p, 3);
        }
      }

      console.log("_newDistanceMap", _newDistanceMap);

      set({ patientDistanceMap: _newDistanceMap });
    },
    getTourStatistic: (tourId) => {
      const tour = get().tours.find((tour) => tour.tourId === tourId);

      if (!tour) {
        toast.error("Tour nicht gefunden");
        return { workTime: 0, pauseTime: 0, travelTime: 0, paidTime: 0 };
      }
      const _activeMissions = tour.activeMissions;

      let workTime = 0;
      let pauseTime = 0;
      let travelTime = 0;
      let paidTime = 0;

      let minMinuteOfDay = 10000;
      let maxMinuteOfDay = 0;

      for (let m of _activeMissions) {
        if (m.type === "P") {
          pauseTime += m.duration_min;
        } else {
          if (m.startTimeMinuteOfDay <= minMinuteOfDay) {
            minMinuteOfDay = m.startTimeMinuteOfDay;
          }
          if (m.startTimeMinuteOfDay + m.duration_min >= maxMinuteOfDay) {
            maxMinuteOfDay = m.startTimeMinuteOfDay + m.duration_min;
          }
          workTime += m.duration_min;
          travelTime += m.time2nextMission !== -1 ? m.time2nextMission : 0;
        }
      }
      paidTime = maxMinuteOfDay - minMinuteOfDay - pauseTime;

      return { workTime, pauseTime, travelTime, paidTime };
    },
    setTourStartTime: ({ tourId, startMinuteOfDay }) => {
      const { tours } = get();
      const _newTours = produce(tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          toast.error("Tour nicht gefunden");
          return;
        }

        draft[tourIndex].startMinuteOfDay = startMinuteOfDay;
        draft[tourIndex].startTime = duration2Time(startMinuteOfDay);
        return draft;
      });

      set({ tours: _newTours });
      get().saveTours({ tourIds: [tourId] });
    },
    setTourMemberId: ({ tourId, memberId }) => {
      const { tours } = get();

      const _newTours = produce(tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          toast.error("Tour nicht gefunden");
          return;
        }

        if (draft[tourIndex].member) {
          draft[tourIndex].member = {
            memberId: memberId,
            shortName: "Update",
            hasExamination: false,
            hasQualificationLevel: "0",
          };
        } else {
          toast.error("Mitarbeiter nicht da");
          return draft;
        }
        return draft;
      });

      set({ tours: _newTours });
      get().saveTours({ tourIds: [tourId] });
    },
    createNewTour: ({ startTime, tourId }) => {
      const { tours } = get();

      const _newTour: CreateStringAsTourInput = {
        day: get().selectedDay,
        tourId: tourId,
        startTime: startTime,
        activePatients_coded: "",
        inActivePatients_coded: "",
        missionPublicationId: tours[0].missionPublicationId,
        memberId: "1000",
      };

      client
        .mutation(CreateTourAsStrings_MissionEasyPlan, {
          tours: [_newTour],
        })
        .toPromise()
        .then((result) => {
          if (result.data) {
            console.log("result.data", result.data);

            const _tours = get().tours;
            const _retTours = result.data.createManyToursAsString;

            if (!_retTours) {
              toast.error("Fehler beim Speichern");
              return;
            }

            get().loadData();
          }
        })
        .catch((error) => {
          console.error("error", error);
          toast.error("Fehler beim Speichern");
        });
    },
    createPause({ tourId, duration_min, index }) {
      const { tours } = get();
      const _newTours = produce(tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          toast.error("Tour nicht gefunden");
          return;
        }

        const _newPause = {
          missionId: createId(),
          patientId: "0",
          time2nextMission: -1,
          startTimeMinuteOfDay: 0,
          actionsCoded: "",
          connectedMissionId: "",
          minReqQualification: 0,
          duration_min: duration_min,
          hasConflictAbsence: false,
          noGeoData: false,
          type: "P",
          patient: {
            shortName: "Pause",
            isPrivate: false,
            lastName: "",
            firstName: "",
          },
        };

        draft[tourIndex].activeMissions.splice(index, 0, _newPause);
        return draft;
      });

      set({ tours: _newTours });

      get().saveTours({ tourIds: [tourId] });
    },
    removePause({ tourId, index }) {
      const { tours } = get();
      const _newTours = produce(tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          toast.error("Tour nicht gefunden");
          return;
        }

        draft[tourIndex].activeMissions.splice(index, 1);
        return draft;
      });

      set({ tours: _newTours });

      get().saveTours({ tourIds: [tourId] });
    },
    changeMissionActions({ tourId, missionId, actionsCoded }) {
      const { tours } = get();
      const _newTours = produce(tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          toast.error("Tour nicht gefunden");
          return;
        }

        const missionIndex = draft[tourIndex].activeMissions.findIndex(
          (m) => m.missionId === missionId
        );

        if (missionIndex === -1) {
          toast.error("Mission nicht gefunden");
          return;
        }

        draft[tourIndex].activeMissions[missionIndex].actionsCoded =
          actionsCoded;
        return draft;
      });

      set({ tours: _newTours });

      get().saveTours({ tourIds: [tourId] });
    },
    setMatchingElementsPatient(searchText) {
      if (!searchText) {
        set({ matchingElements: [] });
        return;
      }

      const { tours } = get();
      const _matchingElements = [] as MatchingElement[];

      for (let tour of tours) {
        for (let m of tour.activeMissions) {
          if (m.patientId === searchText) {
            _matchingElements.push({ element: m, tourId: tour.tourId });
          }

          if (
            m.patient.lastName &&
            m.patient.lastName.toLowerCase().includes(searchText.toLowerCase())
          ) {
            _matchingElements.push({ element: m, tourId: tour.tourId });
          }

          if (
            m.patient.firstName &&
            m.patient.firstName.toLowerCase().includes(searchText.toLowerCase())
          ) {
            _matchingElements.push({ element: m, tourId: tour.tourId });
          }
        }
      }
      console.log("_matchingElements", _matchingElements);
      set({ matchingElements: _matchingElements });
    },
    setMissionDuration({ tourId, duration_min, index }) {
      const { tours } = get();
      const _newTours = produce(tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          toast.error("Tour nicht gefunden");
          return;
        }

        draft[tourIndex].activeMissions[index].duration_min = duration_min;
        return draft;
      });

      set({ tours: _newTours });

      get().saveTours({ tourIds: [tourId] });
    },
    createNewMission({ tourId, patientId, duration_min, action_coded }) {
      const { tours } = get();
      const _newTours = produce(tours, (draft) => {
        const tourIndex = draft.findIndex((tour) => tour.tourId === tourId);
        if (tourIndex === -1) {
          toast.error("Tour nicht gefunden");
          return;
        }

        const _newMission = {
          missionId: createId(),
          patientId: patientId,
          time2nextMission: -1,
          startTimeMinuteOfDay: 0,
          actionsCoded: action_coded,
          connectedMissionId: "null",
          minReqQualification: 0,
          duration_min: duration_min,
          hasConflictAbsence: false,
          noGeoData: false,
          type: "M",
          patient: {
            shortName: "Patient",
            isPrivate: false,
            lastName: "",
            firstName: "",
          },
        };

        draft[tourIndex].activeMissions.push(_newMission);
        return draft;
      });

      set({ tours: _newTours });

      get().saveTours({ tourIds: [tourId] });
    },
    setMatchingElementsNoAction() {
      const tours = get().tours;
      const _matchingElements = [] as MatchingElement[];

      for (let tour of tours) {
        for (let m of tour.activeMissions) {
          if (m.actionsCoded === "" && m.patientId !== "0") {
            _matchingElements.push({ element: m, tourId: tour.tourId });
          }
        }
      }

      set({ matchingElements: _matchingElements });
    },
  })
);
