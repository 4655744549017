import { create } from "zustand";

import { client } from "../../urqlClient";
import { ArrElement } from "../../helpers/typeHelpers";

import { graphql } from "../../gql";
import { TourDatas_MemberEditTourQuery } from "../../gql/graphql";
import { to } from "@react-spring/web";
import toast from "react-hot-toast";

const TourDatas_MemberEditTour = graphql(/* GraphQL */ `
  query TourDatas_MemberEditTour($day: String!) {
    tourDatas(day: $day) {
      canSentToMobile
      tourId
      tourName
      shift
      day
      latesUpdateSupabase
      memberId
    }
  }
`);

const UpdateMemberTour_MemberEditTour = graphql(/* GraphQL */ `
  mutation UpdateMemberTour_MemberEditTour(
    $day: String!
    $tourId: String!
    $memberId: String!
  ) {
    updateMemberTour(day: $day, memberId: $memberId, tourId: $tourId)
  }
`);

export type LocalTourDatas_MemberEditTour = ArrElement<
  TourDatas_MemberEditTourQuery["tourDatas"]
>;

interface MemberEditTourState {
  minDay: string;
  maxDay: string;
  setDateRange: ({
    maxDay,
    minDay,
  }: {
    minDay: string;
    maxDay: string;
  }) => void;
  tourDatas: LocalTourDatas_MemberEditTour[];
  loadData: () => void;
  updateMemberTour: ({
    tourId,
    memberId,
    day,
  }: {
    tourId: string;
    memberId: string;
    day: string;
  }) => void;
}

export const useMemberEditTour = create<MemberEditTourState>((set, get) => ({
  minDay: "",
  maxDay: "",
  setDateRange: ({ maxDay, minDay }) => {
    set({
      minDay,
      maxDay,
    });
  },
  tourDatas: [],
  loadData: async () => {
    if (get().minDay === "" || get().maxDay === "") {
      toast.error("Kein Zeitraum ausgewählt");
      return;
    }
    console.log("Loading", get().minDay, get().maxDay);
    const { data } = await client
      .query(
        TourDatas_MemberEditTour,
        {
          day: get().minDay,
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();

    console.log("data", data);

    set({ tourDatas: data?.tourDatas || [] });
  },
  updateMemberTour: ({ tourId, memberId, day }) => {
    client
      .mutation(UpdateMemberTour_MemberEditTour, {
        day,
        tourId,
        memberId,
      })
      .toPromise()
      .then((result) => {
        console.log("Result", result);
        if (result?.error) {
          toast.error(result.error.message);
        } else {
          toast.success("MA der Tour zugewiesen");
        }
      })
      .catch((error) => {
        console.error("Error", error);
        toast.error(error.message);
      });
  },
}));
