import React from "react";
import {
  LocalMission_MissionEasyPlanNext,
  useMissionEasyPlanNext,
} from "./useMissionEasyPlanNext";
import { cn } from "../../helpers/utils";
import { BriefcaseMedicalIcon, GripIcon } from "lucide-react";
import { BsQuestionCircleFill } from "react-icons/bs";
import { startMin2Time } from "../MissionEasyPlan/MissionEasyPlan";
import { emit } from "process";
import { DragOverlay, useDraggable, useDroppable } from "@dnd-kit/core";
import { Button } from "../UiComponents/Button";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Popover, PopoverContent } from "../UiComponents/Popover";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { get } from "lodash";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UiComponents/Dropdown";

const getDistanceClass = (time: number | undefined) => {
  if (time === undefined) {
    return null;
  }

  if (time < 3) {
    return "bg-green-50";
  }
  if (time < 5) {
    return "bg-yellow-50";
  }
  if (time < 7) {
    return "bg-red-50";
  }
  if (time < 12) {
    return "bg-purple-50";
  }

  return null;
};

const DisplayDuration = ({ duration_min }: { duration_min: number }) => (
  <div
    className={cn(
      "rounded-xl px-1",
      { "bg-green-200": duration_min <= 5 },
      {
        "bg-yellow-200": duration_min > 5 && duration_min <= 10,
      },
      {
        "bg-red-200": duration_min > 10 && duration_min <= 15,
      },
      { "bg-purple-200": duration_min > 15 }
    )}
  >
    {duration_min}
  </div>
);
const ReqQualificationIcon = ({
  minReqQualification,
}: {
  minReqQualification: number;
}) => {
  return (
    <div>
      <div
        className={cn(
          "text-xs  font-extralight p-1 rounded-t-sm",

          { "text-yellow-600": minReqQualification > 2 },
          { "text-yellow-300": minReqQualification <= 2 },
          { "text-blue-500": minReqQualification === 0 },
          { "text-red-500": minReqQualification >= 3 }
        )}
      >
        <div>
          {minReqQualification !== -1 ? (
            <BriefcaseMedicalIcon size={14} />
          ) : (
            <BsQuestionCircleFill size="14" className="text-red-500" />
          )}
        </div>
      </div>
    </div>
  );
};

type Props = {
  element: LocalMission_MissionEasyPlanNext;
  index: number;
  memberHasQualificationLevel: number;
  tourId: string;
  listType: "active" | "inactive";
};

const PauseEasyPlan = (props: Props) => {
  const { element, index, memberHasQualificationLevel, tourId, listType } =
    props;

  const removePause = useMissionEasyPlanNext((state) => state.removePause);
  const patientDistanceMap = useMissionEasyPlanNext(
    (state) => state.patientDistanceMap
  );

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: element.missionId,
      data: {
        type: "element",
        element,
        index: index,
        tourId: tourId,
        listType: listType,
      },
    });

  const { setNodeRef: dropNodeRef, isOver } = useDroppable({
    id: element.missionId,
    data: {
      type: "element",
      element,
      index: index,
      tourId: tourId,
    },
  });

  return (
    <div ref={setNodeRef} className={cn("flex flex-row h-full", {})}>
      <div
        className={cn("bg-white p-1 text-xs font-extralight w-24 ", {
          "opacity-30": isDragging,
        })}
      >
        <div
          ref={dropNodeRef}
          className="flex flex-row justify-between items-center bg-teal-200"
        >
          <div {...attributes} {...listeners} className="font-semibold">
            Pause
          </div>
          <div {...attributes} {...listeners} className="font-light">
            ({element.patientId})
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant={"neutral-ghost"} size={"compact-sm"}>
                <GripIcon className={cn("text-gray-500")} size={14} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  removePause({
                    tourId: tourId,
                    index: index,
                  });
                }}
              >
                Löschen
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div
          {...attributes}
          {...listeners}
          className="flex flex-row justify-between items-center "
        >
          <div>{startMin2Time(element.startTimeMinuteOfDay)}</div>

          <div>
            <DisplayDuration duration_min={element.duration_min} />
          </div>
        </div>
      </div>
      {!element.noGeoData ? (
        <div className="text-xs flex flex-col justify-center items-center w-6">
          <div>{"->"}</div>
          {element.time2nextMission}
        </div>
      ) : (
        <div className="text-xs flex flex-col justify-center items-center w-6 bg-red-500 text-white h-6">
          <div className="">!</div>
        </div>
      )}
    </div>
  );
};

export default PauseEasyPlan;
