import React from "react";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";
import { Button } from "../UiComponents/Button";
import { startMin2Time } from "../MissionEasyPlan/MissionEasyPlan";

type Props = {};

const MatchingElements = (props: Props) => {
  const matchingElements = useMissionEasyPlanNext(
    (state) => state.matchingElements
  );

  const setScrollToMissionId = useMissionEasyPlanNext(
    (state) => state.setScrollToMissionId
  );

  const setSelectedElement = useMissionEasyPlanNext(
    (state) => state.setSelectedElement
  );

  return (
    <div>
      {matchingElements.map((me, index) => {
        return (
          <Button
            onClick={() => {
              setScrollToMissionId(me.element.missionId);
              setSelectedElement({
                selectedElement: {
                  element: me.element,
                  index: index,
                  tourId: me.tourId,
                },
              });
            }}
            key={index}
            className="flex flex-col border-[1px] border-gray-400"
          >
            <div className="flex flex-row gap-2 text-xs">
              <div>{me.element.patient.shortName}</div>
              <div>{startMin2Time(me.element.startTimeMinuteOfDay)}</div>
            </div>

            <div className="text-xs">{me.tourId}</div>

            <div></div>
          </Button>
        );
      })}
    </div>
  );
};

export default MatchingElements;
