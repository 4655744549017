import React from "react";
import {
  LocalMission_MissionEasyPlanNext,
  useMissionEasyPlanNext,
} from "./useMissionEasyPlanNext";
import { cn } from "../../helpers/utils";
import { BriefcaseMedicalIcon, GripIcon } from "lucide-react";
import { BsQuestionCircleFill } from "react-icons/bs";
import { startMin2Time } from "../MissionEasyPlan/MissionEasyPlan";

import { useDraggable, useDroppable } from "@dnd-kit/core";
import { Button } from "../UiComponents/Button";

import { Popover, PopoverContent } from "../UiComponents/Popover";
import { PopoverTrigger } from "@radix-ui/react-popover";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../UiComponents/Dropdown";
import { set } from "lodash";

const getDistanceClass = (time: number | undefined) => {
  if (time === undefined) {
    return null;
  }

  if (time < 3) {
    return "bg-green-50";
  }
  if (time < 5) {
    return "bg-yellow-50";
  }
  if (time < 7) {
    return "bg-red-50";
  }
  if (time < 12) {
    return "bg-purple-50";
  }

  return null;
};

const DisplayDuration = ({ duration_min }: { duration_min: number }) => (
  <div
    className={cn(
      "rounded-xl px-1",
      { "bg-green-200": duration_min <= 5 },
      {
        "bg-yellow-200": duration_min > 5 && duration_min <= 10,
      },
      {
        "bg-red-200": duration_min > 10 && duration_min <= 15,
      },
      { "bg-purple-200": duration_min > 15 }
    )}
  >
    {duration_min}
  </div>
);
const ReqQualificationIcon = ({
  minReqQualification,
}: {
  minReqQualification: number;
}) => {
  return (
    <div>
      <div
        className={cn(
          "text-xs  font-extralight p-1 rounded-t-sm",

          { "text-yellow-600": minReqQualification > 2 },
          { "text-yellow-300": minReqQualification <= 2 },
          { "text-blue-500": minReqQualification === 0 },
          { "text-red-500": minReqQualification >= 3 }
        )}
      >
        <div>
          {minReqQualification !== -1 ? (
            <BriefcaseMedicalIcon size={14} />
          ) : (
            <BsQuestionCircleFill size="14" className="text-red-500" />
          )}
        </div>
      </div>
    </div>
  );
};

type Props = {
  element: LocalMission_MissionEasyPlanNext;
  index: number;
  memberHasQualificationLevel: number;
  tourId: string;
  listType: "active" | "inactive";
};

const ElementEasyPlan = (props: Props) => {
  const { element, index, memberHasQualificationLevel, tourId, listType } =
    props;

  const inActivateMission = useMissionEasyPlanNext(
    (state) => state.inActivateMission
  );

  const patientDistanceMap = useMissionEasyPlanNext(
    (state) => state.patientDistanceMap
  );

  const selectedElement = useMissionEasyPlanNext(
    (state) => state.selectedElement
  );

  const setSelectedElement = useMissionEasyPlanNext(
    (state) => state.setSelectedElement
  );

  const autoDistanceEnabled = useMissionEasyPlanNext(
    (state) => state.autoDistanceEnabled
  );

  const calculatePatientDistances = useMissionEasyPlanNext(
    (state) => state.calculatePatientDistances
  );

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: element.missionId,
      data: {
        type: "element",
        element,
        index: index,
        tourId: tourId,
        listType: listType,
      },
    });

  const { setNodeRef: dropNodeRef, isOver } = useDroppable({
    id: element.missionId,
    data: {
      type: "element",
      element,
      index: index,
      tourId: tourId,
    },
  });

  const _distance = patientDistanceMap?.get(element.patientId);

  let warnQualification = false;
  if (
    element.minReqQualification > memberHasQualificationLevel &&
    element.patient?.isPrivate === false
  ) {
    warnQualification = true;
  }

  return (
    <div ref={setNodeRef} className={cn("flex flex-row ", {})}>
      <div
        className={cn("bg-white p-1 text-xs font-extralight w-32", {
          "opacity-30": isDragging,
          // "border-l-4 border-l-green-500": element.patient?.isPrivate,
          "border-b-4 border-purple-400":
            _distance !== undefined &&
            _distance <= 12 &&
            selectedElement?.element?.missionId !== element.missionId,
          "border-b-4 border-red-400":
            _distance !== undefined && _distance <= 7,
          "border-b-4 border-yellow-400":
            _distance !== undefined && _distance <= 5,
          "border-b-4 border-green-400":
            _distance !== undefined && _distance <= 3,

          "bg-yellow-500":
            selectedElement?.element?.missionId === element.missionId,
          "bg-green-200": isOver,
          "bg-black text-white": element.hasConflictAbsence,
        })}
      >
        <div
          ref={dropNodeRef}
          className={cn("flex flex-row justify-between items-center", {})}
        >
          <div
            className={cn("font-semibold", {
              "text-green-500 bg-white": element.patient?.isPrivate,
              "bg-purple-500 text-white": warnQualification,
            })}
            {...attributes}
            {...listeners}
          >
            {element.patient?.shortName}
          </div>
          <div {...attributes} {...listeners} className="font-light">
            ({element.patientId})
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant={"neutral-ghost"} size={"compact-sm"}>
                <GripIcon className={cn("text-gray-500")} size={14} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                onClick={() => {
                  setSelectedElement({
                    selectedElement: {
                      element,
                      index,
                      tourId,
                    },
                  });
                  calculatePatientDistances({
                    patientId: element.patientId,
                  });
                }}
              >
                Fahren
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  if (selectedElement?.element === element) {
                    setSelectedElement({
                      selectedElement: null,
                    });
                  } else {
                    setSelectedElement({
                      selectedElement: {
                        element,
                        index,
                        tourId,
                      },
                    });
                  }
                }}
              >
                Select
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  inActivateMission({
                    tourId: tourId,
                    missionId: element.missionId,
                    index: index,
                  });
                }}
              >
                Absagen
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div
          {...attributes}
          {...listeners}
          className="flex flex-row justify-between items-center"
        >
          <div>
            <ReqQualificationIcon
              minReqQualification={element.minReqQualification}
            />
          </div>
          <div>{startMin2Time(element.startTimeMinuteOfDay)}</div>
          {autoDistanceEnabled && (
            <div className="text-black opacity-40">{_distance}</div>
          )}

          <div>
            <DisplayDuration duration_min={element.duration_min} />
          </div>
        </div>
      </div>
      {!element.noGeoData ? (
        <div className="text-xs flex flex-col justify-center items-center w-6">
          <div>{"->"}</div>
          {element.time2nextMission}
        </div>
      ) : (
        <div className="text-xs flex flex-col justify-center items-center w-6 bg-red-500 text-white h-6">
          <div className="">!</div>
        </div>
      )}
    </div>
  );
};

export default ElementEasyPlan;
