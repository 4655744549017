import React, { useEffect } from "react";
import { Input } from "../UiComponents/Input";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";

type Props = {};

const PatientSearchInput = (props: Props) => {
  const setMatchingElementsPatient = useMissionEasyPlanNext(
    (state) => state.setMatchingElementsPatient
  );
  const matchingElements = useMissionEasyPlanNext(
    (state) => state.matchingElements
  );
  const [searchValue, setSearchValue] = React.useState<string>("");

  useEffect(() => {
    setMatchingElementsPatient(searchValue);
  }, [searchValue]);

  return (
    <div className="flex flex-col gap-2 p-2">
      <Input
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
    </div>
  );
};

export default PatientSearchInput;
