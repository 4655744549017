import React, { useEffect } from "react";
import MatchingElements from "./MatchingElements";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";

type Props = {};

const NoActionFilterEasyPlan = (props: Props) => {
  const { setMatchingElementsNoAction, tours } = useMissionEasyPlanNext();

  useEffect(() => {
    setMatchingElementsNoAction();
  }, [tours]);
  return (
    <div className="flex flex-col gap-2 overflow-y-scroll h-[calc(100vh-4rem)]">
      <MatchingElements />
    </div>
  );
};

export default NoActionFilterEasyPlan;
