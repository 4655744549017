import React from "react";
import PatientSearchInput from "./PatientSearchInput";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";
import { startMin2Time } from "../MissionEasyPlan/MissionEasyPlan";
import { Button } from "../UiComponents/Button";
import { set } from "lodash";
import MatchingElements from "./MatchingElements";

type Props = {};

const PatientFilterEasyPlan = (props: Props) => {
  const matchingElements = useMissionEasyPlanNext(
    (state) => state.matchingElements
  );

  const setScrollToMissionId = useMissionEasyPlanNext(
    (state) => state.setScrollToMissionId
  );

  const setSelectedElement = useMissionEasyPlanNext(
    (state) => state.setSelectedElement
  );
  return (
    <div className="flex flex-col gap-2">
      <div>
        <PatientSearchInput />
      </div>
      <MatchingElements />
    </div>
  );
};

export default PatientFilterEasyPlan;
