import React, { useEffect } from "react";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";
import PatientTASMissionList from "../PatientTASMissionList/PatientTASMissionList";
import { Dialog, DialogContent, DialogTrigger } from "../UiComponents/Dialog";
import { DisplayPatientInfo } from "../Patient/DisplayPatientInfo";
import { cn } from "../../helpers/utils";
import ActionClipboard from "../ActionClipboard/ActionClipboard";
import DisplayScheduleByDay from "../Schedule/DisplayScheduleByDay";
import DisplayActivePatientAbsence from "../Patient/PatientAbsence/DisplayActivePatientAbsence";
import DisplayPatientMedifoxInfo from "../Patient/DisplayPatientMedifoxInfo";
import { Button } from "../UiComponents/Button";
import { Sheet, SheetContent } from "../UiComponents/Sheet";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import ManageOnePatientAbsence from "../Patient/PatientAbsence/ManageOnePatientAbsence";

import { getEndDateOfWeek, getStartDateOfWeek } from "../../helpers/datetime";
import dayjs from "dayjs";
import { Label } from "../UiComponents/Label";
import { Input } from "../UiComponents/Input";
import { set } from "lodash";
import { startMin2Time } from "../MissionEasyPlan/MissionEasyPlan";

const selectableMinutes = ["05", "10", "15", "20", "25", "30"];

type Props = {};

const PatientInfoEasyPlan = (props: Props) => {
  const [duration, setDuration] = React.useState<string>("05");
  const selectedElement = useMissionEasyPlanNext(
    (state) => state.selectedElement
  );
  const changeMissionActions = useMissionEasyPlanNext(
    (state) => state.changeMissionActions
  );
  const setMissionDuration = useMissionEasyPlanNext(
    (state) => state.setMissionDuration
  );

  const selectedDay = useMissionEasyPlanNext((state) => state.selectedDay);

  const minDay = getStartDateOfWeek(selectedDay);
  const maxDay = getEndDateOfWeek(selectedDay);

  const [openPatientDiolog, setOpenPatientDiolog] =
    React.useState<boolean>(false);
  const [selectedPatientId, setSelectedPatientId] = React.useState<string>("");
  const {
    getActionClipbaordContentAsCoded,
    setActionClipboardContentFromCoded,
  } = useActionClipboard();

  useEffect(() => {
    if (selectedElement) {
      setActionClipboardContentFromCoded(selectedElement?.element.actionsCoded);
      setDuration(selectedElement?.element.duration_min.toString() || "05");
    }
  }, [selectedElement]);

  console.log(selectedElement);

  return (
    <>
      <div className="flex flex-col overflow-y-scroll h-full">
        <ActionClipboard />
        <Button
          onClick={() => {
            setSelectedPatientId(selectedElement?.element.patientId || "");
            setOpenPatientDiolog(true);
          }}
        >
          Open
        </Button>
        <Button
          onClick={() => {
            changeMissionActions({
              tourId: selectedElement?.tourId || "",
              missionId: selectedElement?.element.missionId || "",
              actionsCoded: getActionClipbaordContentAsCoded(),
            });
          }}
        >
          Set Leistungen
        </Button>
        <DisplayActivePatientAbsence patientId={selectedPatientId || ""} />
        <div>
          <DisplayPatientInfo
            patientId={selectedElement?.element.patientId || ""}
          />
        </div>

        <div className="flex flex-row gap-2 items-center">
          <Label htmlFor="duration">
            Dauer (
            {selectableMinutes.map((m) => {
              return (
                <span>
                  <Button
                    onClick={() => {
                      setDuration(m);
                    }}
                    size={"compact-sm"}
                    variant={"primary-ghost"}
                  >
                    {m}
                  </Button>
                </span>
              );
            })}
            )
          </Label>

          <Input
            className="w-18"
            type="number"
            id="duration"
            placeholder="Dauer"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
            step="5"
            min="05"
            max="120"
          />
          <Button
            onClick={() => {
              setMissionDuration({
                tourId: selectedElement?.tourId || "",
                index: selectedElement?.index || 0,
                duration_min: Number(duration),
              });
            }}
          >
            Setzen
          </Button>
        </div>

        <DisplayActivePatientAbsence
          patientId={selectedElement?.element.patientId || ""}
        />
        <Dialog>
          <DialogContent className="w-[60%]" title="Abwesenheit verwalten">
            <ManageOnePatientAbsence
              patientId={selectedElement?.element.patientId || ""}
              updateData={() => {}}
            />
          </DialogContent>

          <DialogTrigger>
            <Button>Abwesenheit</Button>
          </DialogTrigger>
        </Dialog>
        <PatientTASMissionList
          patientId={selectedElement?.element.patientId || ""}
          minDay={dayjs(minDay).format("YYYY-MM-DD")}
          maxDay={dayjs(maxDay).format("YYYY-MM-DD")}
        />
      </div>
      <Sheet open={openPatientDiolog} onOpenChange={setOpenPatientDiolog}>
        <SheetContent
          side={"bottom"}
          className="w-[80%] h-[80%] overflow-y-auto content-center"
          title={"Info"}
        >
          <div>
            <div className="flex flex-row">
              <div className="w-96">
                <DisplayPatientInfo patientId={selectedPatientId || ""} />
              </div>
              <div className="flex flex-row text-xs"></div>
            </div>
            <ActionClipboard />

            <div
              className={cn("text-center font-semibold", {
                "bg-blue-500 text-white":
                  (selectedElement?.element.startTimeMinuteOfDay || 0) >= 720,
              })}
            >
              {dayjs(selectedDay).format("dddd, DD. MMM")}{" "}
              {startMin2Time(
                selectedElement?.element.startTimeMinuteOfDay || 0
              )}{" "}
              für {selectedElement?.element.duration_min} Minuten
            </div>

            <DisplayScheduleByDay
              patientId={selectedPatientId || ""}
              minDay={dayjs(minDay).format("YYYY-MM-DD")}
              maxDay={dayjs(maxDay).format("YYYY-MM-DD")}
            />
            <div className="h-96">
              <DisplayActivePatientAbsence
                patientId={selectedPatientId || ""}
              />
              {/* <div className="w-1/2 h-full">
                <DisplayPatientMedifoxInfo
                  patientId={selectedPatientId || ""}
                />
              </div> */}
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default PatientInfoEasyPlan;
