import { io } from "socket.io-client";
import inMemoryJwt from "./Components/Auth0/inMemoryJwt";
import dayjs from "dayjs";

export const socket = io(
  process.env.REACT_APP_SOCKET_ENDPOINT || "http://localhost:3333",
  {
    auth: (cb) => {
      cb({ token: inMemoryJwt.getUserId(), userId: inMemoryJwt.getUserId() });
    },
  }
);

// socket.on("connect", () => {
//   console.log("connected", socket.id);
// });

// socket.onAny((event, ...args) => {
//   console.log(`got ${event}: ${args}`);
// });
