// inMemoryJwt.js

import { socket } from "../../socketClient";
import { get } from "lodash";
import { nanoid } from "nanoid";

const inMemoryJWTManager = () => {
  let inMemoryJWT = null;
  let clientId = null;
  let userId = "none";
  const getToken = () => {
    //  console.log("Get Token", inMemoryJWT);
    return inMemoryJWT;
  };

  const getClientId = () => {
    return clientId;
  };

  const getUserId = () => {
    return userId;
  };

  const setUserId = (id) => {
    userId = id;
  };

  const setToken = (token) => {
    //console.log("Token in Memory",token)
    inMemoryJWT = token;
    socket.auth = { token: token };
    socket.disconnect().connect();
    clientId = nanoid(10);
    console.log("clientId", clientId);
    return true;
  };

  const ereaseToken = () => {
    inMemoryJWT = null;
    return true;
  };

  return {
    ereaseToken,
    getToken,
    setToken,
    getClientId,
    setUserId,
    getUserId,
  };
};

export default inMemoryJWTManager();
