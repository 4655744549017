import "@total-typescript/ts-reset";
import { graphql } from "../../gql";

import { ArrElement } from "@/src/helpers/typeHelpers";
import { create } from "zustand";
import { client } from "../../urqlClient";
import _, { slice, update } from "lodash";
import produce from "immer";
import { enableMapSet } from "immer";

import toast from "react-hot-toast";

import {
  ActionIdWithQuantity,
  Missions_MissionEasyPlanQuery,
  TourPauses_MissionEasyplanQuery,
} from "../../gql/graphql";

import { UpsertSupabaseME_ReviewExecutionMobile } from "../ReviewExecutionMobile/useReviewExecutionMobile";

import dayjs from "dayjs";
import { createId } from "@paralleldrive/cuid2";
import { LocalPatientSearchBox } from "../PatientSearchbox/usePatientSearchbox";
import { LocalActionWithQuantity } from "../../type-definitions";
import { useMission } from "../../services/Mission/useMission";
import { Socket, io } from "socket.io-client";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import { nanoid } from "nanoid";
import { to } from "@react-spring/web";
import { group } from "console";

enableMapSet();

const AddMissionToDay_MissionEasyPlan = graphql(/* GraphQL */ `
  mutation AddMissionToDay_MissionEasyPlan($input: AddMissionToDayInput!) {
    addMissionToDay(input: $input) {
      id
    }
  }
`);

const UpsertOneTourPause_MissionEasyPlan = graphql(/* GraphQL */ `
  mutation UpsertOneTourPause_MissionEasyPlan($input: CreateTourPauseInput!) {
    upsertOneTourPause(input: $input) {
      id
    }
  }
`);

const DeleteOneTourPause_MissionEasyPlan = graphql(/* GraphQL */ `
  mutation DeleteOneTourPause_MissionEasyPlan($id: String!) {
    deleteOneTourPause(id: $id) {
      id
    }
  }
`);

const MissionSetAction_MissionEasyPlan = graphql(/* GraphQL */ `
  mutation MissionSetAction_MissionEasyPlan(
    $id: String!
    $actionIdsWQ: [ActionIdWithQuantity!]!
    $updatedAt: String!
  ) {
    missionSetAction(
      id: $id
      actionIdsWQ: $actionIdsWQ
      updatedAt: $updatedAt
    ) {
      id
      updatedAt
      tourId
      minReqQualification
      actionsWithQuantity {
        quantity
        actionLnr
        action {
          examinationRequired
          leistungsart
          lnr
          leistungskomplex
        }
      }
    }
  }
`);

const TourPauses_MissionEasyPlan = graphql(/* GraphQL */ `
  query TourPauses_MissionEasyplan(
    $publicationDays: [PublicationDayInput!]!
    $tourIds: [String!]!
  ) {
    tourPauses(publicationDays: $publicationDays, tourIds: $tourIds) {
      id
      missionPublicationId
      memberId
      startTS
      endTS
      day
      tourId
      groupId
      duration_min
      startMinuteOfDay
      endMinuteOfDay
      isActive
    }
  }
`);

export type LocalTourPauses_MissionEasyPlan = ArrElement<
  TourPauses_MissionEasyplanQuery["tourPauses"]
>;

const UpdateManyMissionsEnhanced_MissionEasyPlan = graphql(/* GraphQL */ `
  mutation UpdateManyMissionsEnhanced_MissionEasyPlan(
    $input: [MissionUpdateBulkInput1!]!
  ) {
    updateManyMissionsEnhanced(input: $input) {
      canBeWritten {
        id
      }
      notCanBeWritten {
        id
      }
      dataWritten
      written {
        id
      }
    }
  }
`);

const NearestPatients_MissionEasyPlan = graphql(/* GraphQL */ `
  query NearestPatients_MissionEasyPlan($patientId: String!, $minutes: Int!) {
    nearByPatients(patientId: $patientId, minutes: $minutes) {
      patientId
    }
  }
`);

const Missions_MissionEasyPlan = graphql(/* GraphQL */ `
  query Missions_MissionEasyPlan($filter: GlobalTimeRangeFilter!) {
    missionsTimeRange(filter: $filter) {
      id
      missionPublicationId
      isActive
      hasConflictAbsence
      updatedAt
      patientId
      duration_min
      startMinuteOfDay
      endMinuteOfDay
      day
      tourId
      memberId
      memberHasQualificationLevel
      minReqQualification

      actionsWithQuantity {
        quantity
        actionLnr
        action {
          examinationRequired
          leistungsart
          lnr
          leistungskomplex
        }
      }

      connectedMission {
        id
        startMinuteOfDay
        endMinuteOfDay
        time
        member {
          memberId
          lastName
          firstName
          shortName
          hasQualificationLevel
          hasExamination
        }
      }

      member {
        memberId
        lastName
        firstName
        shortName
        hasQualificationLevel
        hasExamination
      }
      patient {
        lastName
        firstName
        isPrivate
        shortName
        latitude
        longitude
      }
      executionMobiles {
        id
      }
    }
  }
`);

const SingleDistance_MissionEasyPlan = graphql(/* GraphQL */ `
  query SingleDistance_MissionEasyPlay(
    $fromPatientId: String!
    $toPatientId: String!
  ) {
    singleDistance(fromPatientId: $fromPatientId, toPatientId: $toPatientId) {
      fromPatientId
      toPatientId
      travelTime_min
      distance_km
    }
  }
`);

export type LocalMission_MissionEasyPlan = ArrElement<
  Missions_MissionEasyPlanQuery["missionsTimeRange"]
>;

export type LocalMember_MissionEasyPlan =
  LocalMission_MissionEasyPlan["member"];

export type LocalActionWithQuantity_MissionEasyPlan = ArrElement<
  LocalMission_MissionEasyPlan["actionsWithQuantity"]
>;

export type MissionPause = LocalMission_MissionEasyPlan & {
  type: string;
  isTouched: boolean;
  isModifiedInDatabase: boolean;
  isNewCreated: boolean;
};

export type TourInfo_MissionEasyPlan = {
  tourId: string;
  day: string;
  startMinuteOfDay: number;
  memberId: string | undefined;
  member: LocalMission_MissionEasyPlan["member"] | undefined;
  tourPauses: LocalTourPauses_MissionEasyPlan[];
  currentMissions: MissionPause[];
  originalMissions: LocalMission_MissionEasyPlan[];
  isModifiedInDatabase: boolean;
  isModifiedInBrowser: boolean;
  isAutorouted: boolean;
};
export type Tour_MissionEasyPlan = {
  tourId: string;
  day: string;
  startMinuteOfDay: number;
  memberId: string | undefined;
  member: LocalMission_MissionEasyPlan["member"] | undefined;
  tourPauses: LocalTourPauses_MissionEasyPlan[];
  currentMissionIds: string[];
  isModifiedInBrowser: boolean;
  isAutorouted: boolean;
};

type MoveMission = {
  missionId: string;
  fromTourId: string;
  intoTourId: string;
  fromIndex: number;
  intoIndex: number;
};

type PatientTourMove = {
  id: string;
  patientId: string;
  patient:
    | {
        lastName: string | undefined;
        firstName: string | undefined;
        isPrivate: boolean | undefined;
        shortName: string | undefined;
      }
    | undefined;
  missionId: string;
  originalTime: string;
  fromTourId: string;
  fromTourMember: LocalMember_MissionEasyPlan | undefined;
  intoTourId: string;
  intoTourMember: LocalMember_MissionEasyPlan | undefined;
};

type MissionEasyPlanState = {
  showTourInfoMessageDialog: boolean;
  isSaving: boolean;
  currentMissionPublicationId: string;
  setShowTourInfoMessageDialog: (show: boolean) => void;
  tourInfoMessageTourId: string;
  activeMissionMap: Map<string, MissionPause> | undefined;
  notActiveMissionMap: Map<string, MissionPause> | undefined;
  setTourInfoMessageTourId: (tourId: string) => void;
  setDay: (day: string) => void;
  toogleShift: () => void;
  prevDay: () => void;
  nextDay: () => void;
  wanderList: PatientTourMove[];
  removeWanderlistItem: (id: string) => void;
  syncSupabase: () => void;
  createNewTour: ({ tourId }: { tourId: string }) => void;
  appendMissionTour: ({
    tourId,
    patient,
    duration,
    actionsWQ,
  }: {
    tourId: string;
    patient: LocalPatientSearchBox;
    duration: number;
    actionsWQ: LocalActionWithQuantity[];
  }) => void;

  day: string;
  selectedShift: { early: boolean; late: boolean };
  setSelectedShift: (shift: { early: boolean; late: boolean }) => void;
  currentScrollDirection: string;
  setCurrentScrollDirection: (direction: string) => void;
  isUpdating: boolean;
  monitorTourIds: string[];
  socketMonitorTourIds: Socket | undefined;
  setMonitorTourIds: (tourIds: string[]) => void;
  touchedTourIds: string[];
  touchedMissionIds: string[];
  moveMissions: MoveMission[];
  isLoading: boolean;
  splitMission: ({ missionId }: { missionId: string }) => void;

  setMissionDurationAction_old: ({
    missionId,
    duration,
  }: //awqs,
  {
    missionId: string;
    duration: number;
    //awqs: LocalActionWithQuantity_MissionEasyPlan[];
  }) => void;
  setMissionDurationAction: ({
    missionId,
    duration,
  }: //awqs,
  {
    missionId: string;
    duration: number;
    //awqs: LocalActionWithQuantity_MissionEasyPlan[];
  }) => void;
  setMissionActions: ({
    missionId,
    actions,
    updatedAt,
  }: {
    missionId: string;
    actions: ActionIdWithQuantity[];
    updatedAt: string;
  }) => void;

  lastFetchFromDbTS: string;
  //missions: LocalMission_MissionEasyPlan[];
  tourInfos_old: TourInfo_MissionEasyPlan[];
  tours: Tour_MissionEasyPlan[];

  missionsMap: Map<string, MissionPause[]> | undefined;
  notActiveMissionsMap: Map<string, MissionPause[]> | undefined;
  tourMoveFrom: string;
  tourMoveInto: string;
  selectedMission: MissionPause | undefined;
  randomValue: number;
  updateTourPausesFromDB: () => void;
  deleteOneTourPause: (id: string) => void;
  addOneTourPause: ({
    tourId,
    duration,
  }: {
    tourId: string;
    duration: number;
  }) => void;
  setSelectedMission: (mission: MissionPause | undefined) => void;
  setTourMoveFrom: (tourId: string) => void;
  setTourMoveInto: (tourId: string) => void;
  getMissionById_old: (missionId: string) => MissionPause | undefined;
  getMissionById: (missionId: string) => MissionPause | undefined;
  setTourStartTime_old: (tourId: string, startMinuteOfDay: number) => void;
  setTourStartTime: (tourId: string, startMinuteOfDay: number) => void;

  saveAllMissions_old: () => void;
  saveAllMissions: () => void;
  createTourPause: (tourId: string) => void;
  countChangedMissions: ({ tourId }: { tourId?: string }) => number;
  assignMemberIdToTour: ({
    tourId,
    member,
    memberId,
  }: {
    tourId: string;
    member: LocalMission_MissionEasyPlan["member"];
    memberId: string;
  }) => void;

  distinctTourIds: string[];
  selectedPatientId: string;
  setSelectedPatientId: (patientId: string) => void;
  selectedMissionId: string;
  setSelectedMissionId: (missionId: string) => void;
  selectedTourId: string;
  setSelectedTourId: (tourId: string) => void;
  min_3_PatientsIds: string[];
  min_5_PatientsIds: string[];
  min_7_PatientsIds: string[];
  min_12_PatientsIds: string[];

  calculateNearestPatients: (patientId: string) => void;
  clearNearestPatients: () => void;
  updateMisisonsFromDB: () => void;

  loadData_old: () => void;
  loadData: () => void;
  recalculateTour_old(tourId: string): void;
  recalculateTour(tourId: string): void;
  moveMission_old: (
    missionIdFrom: string,
    misionIdInto: string | null,
    tourIdFrom: string,
    tourIdInto: string
  ) => void;
  moveMission: (
    missionIdFrom: string,
    misionIdInto: string | null,
    tourIdFrom: string,
    tourIdInto: string,
    undoWanderList: boolean
  ) => void;
  setMissionStatus: ({
    missionId,
    tourId,
    isActive,
  }: {
    tourId: string;
    missionId: string;
    isActive: boolean;
  }) => void;
  createSocketMonitor: () => void;
  closeSocketMonitor: () => void;
};

export const useMissionEasyPlan = create<MissionEasyPlanState>((set, get) => ({
  showTourInfoMessageDialog: false,
  currentMissionPublicationId: "",
  monitorTourIds: [],
  wanderList: [],
  activeMissionMap: undefined,
  notActiveMissionMap: undefined,
  socketMonitorTourIds: undefined,
  isLoading: false,
  isSaving: false,
  tourInfoMessageTourId: "",
  day: "",
  selectedShift: { early: true, late: false },
  selectedPatientId: "",
  isUpdating: false,
  currentScrollDirection: "up",
  missionsMap: undefined,
  notActiveMissionsMap: undefined,
  tourInfos_old: [],
  tours: [],
  //missions: [],
  lastFetchFromDbTS: (dayjs().unix() * 1000).toString(),
  selectedMission: undefined,
  selectedMissionId: "",
  selectedTourId: "",
  touchedTourIds: [],
  touchedMissionIds: [],
  moveMissions: [],
  randomValue: Math.random(),

  tourMoveFrom: "",
  tourMoveInto: "",
  min_3_PatientsIds: [],
  min_5_PatientsIds: [],
  min_7_PatientsIds: [],
  min_12_PatientsIds: [],

  distinctTourIds: [],
  setCurrentScrollDirection: (direction) => {
    set({ currentScrollDirection: direction });
  },
  setSelectedPatientId: (patientId) => {
    set({ selectedPatientId: patientId });
  },

  loadData: () => {
    if (get().day === "") {
      toast.error("Tag nicht angegeben");
      return;
    }

    console.log("loadData", get().day, get().selectedShift);
    set({
      //missions: [],
      isLoading: true,
      moveMissions: [],
      missionsMap: undefined,
      notActiveMissionsMap: undefined,
      touchedTourIds: [],
      touchedMissionIds: [],
      wanderList: [],
      lastFetchFromDbTS: (dayjs().unix() * 1000).toString(),
    });
    client
      .query(
        Missions_MissionEasyPlan,
        {
          filter: {
            minDate: get().day,
            maxDate: get().day,
            includeEarly: get().selectedShift.early,
            includeLate: get().selectedShift.late,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then(async (result) => {
        console.log(result.data);

        const _allMissions = result?.data?.missionsTimeRange || [];
        const distinctMissionPublicationIds = _.uniqBy(
          _allMissions,
          "missionPublicationId"
        ).map((m) => m.missionPublicationId || "");

        if (distinctMissionPublicationIds.length > 1) {
          console.log(
            "Tour has multiple missionPublicationIds",
            distinctMissionPublicationIds
          );
          toast.error("Tour has multiple missionPublicationIds");
          return;
        }

        set({ currentMissionPublicationId: distinctMissionPublicationIds[0] });

        const _distinctTourIds =
          _.uniqBy(_allMissions, "tourId").map((m) => m.tourId || "") || [];

        const retPauses = await client.query(TourPauses_MissionEasyPlan, {
          publicationDays: [
            {
              day: get().day,
              missionPublicationId: distinctMissionPublicationIds[0],
            },
          ],
          tourIds: _distinctTourIds,
        });

        console.log("Pusen da ", retPauses);
        const _tourPauses = retPauses?.data?.tourPauses || [];

        const _activeMissions = _.filter(
          result?.data?.missionsTimeRange || [],
          (m) => m.isActive === true
        );
        const _notActiveMissions = _.filter(
          result?.data?.missionsTimeRange || [],
          (m) => m.isActive === false
        );

        // create Maps for zthe misions

        const _notActiveMissionsMap = new Map<string, MissionPause>();
        const _activeMissionsMap = new Map<string, MissionPause>();

        for (const mission of _notActiveMissions) {
          const obj = {
            ...mission,
            type: "mission",
            isModifiedInDatabase: false,
            isTouched: false,
            isNewCreated: false,
          } as MissionPause;
          _notActiveMissionsMap.set(mission.id, obj);
        }

        for (const mission of _activeMissions) {
          const obj = {
            ...mission,
            type: "mission",
            isModifiedInDatabase: false,
            isTouched: false,
            isNewCreated: false,
          } as MissionPause;
          _activeMissionsMap.set(mission.id, obj);
        }

        for (const tp of _tourPauses) {
          const obj = {
            id: tp.id,
            missionPublicationId: tp.missionPublicationId,
            isActive: tp.isActive,
            updatedAt: "",
            patientId: "pause",
            duration_min: tp.duration_min,
            startMinuteOfDay: tp.startMinuteOfDay,
            endMinuteOfDay: tp.endMinuteOfDay,
            day: tp.day,
            tourId: tp.tourId,
            memberId: tp.memberId,
            memberHasQualificationLevel: "",
            member: undefined,
            patient: {
              lastName: "pause",
              firstName: "pause",
              isPrivate: false,
              shortName: "Pause",
            },
            type: "pause",
            isModifiedInDatabase: false,
            isTouched: false,
            isNewCreated: false,
          } as MissionPause;
          _activeMissionsMap.set(tp.id, obj);
        }

        const _tours = [] as Tour_MissionEasyPlan[];

        for (const tourId of _distinctTourIds) {
          const __activeMissions = Array.from(_activeMissionsMap.values());
          const _missions = _.chain(__activeMissions)
            .orderBy("startMinuteOfDay", "asc")
            .filter((m) => m.tourId === tourId)
            .value();

          const _missionIds = _missions.map((m) => m.id);

          const _startMinuteOfDay =
            _.minBy(_missions, "startMinuteOfDay")?.startMinuteOfDay || 360;

          const _distinctMembers = _.uniqBy(
            _activeMissions.filter((m) => m.tourId === tourId),
            "memberId"
          ).map((m) => m.member || undefined);

          if (_distinctMembers.length > 1) {
            console.log("Tour has multiple members", _distinctMembers);
            toast.error("Tour has multiple members");
            return;
          }

          // console.log("TourId", tourId);
          // console.log("MissionsIds", _missionIds);

          _tours.push({
            tourId: tourId,
            day: get().day,
            startMinuteOfDay: _startMinuteOfDay,
            memberId: _distinctMembers[0]?.memberId || "",
            member: _distinctMembers[0],
            tourPauses: [],
            currentMissionIds: _missionIds,
            isModifiedInBrowser: false,
            isAutorouted: true,
          });
        }
        set({
          tours: _tours,
          distinctTourIds: _.orderBy(_distinctTourIds),
          activeMissionMap: _activeMissionsMap,
          notActiveMissionMap: _notActiveMissionsMap,
          isLoading: false,
        });
      });
  },

  loadData_old: () => {
    if (get().day === "") {
      toast.error("Tag nicht angegeben");
      return;
    }

    console.log("loadData", get().day, get().selectedShift);

    set({
      //missions: [],
      isLoading: true,
      moveMissions: [],
      missionsMap: undefined,
      notActiveMissionsMap: undefined,
      touchedTourIds: [],
      touchedMissionIds: [],
      lastFetchFromDbTS: (dayjs().unix() * 1000).toString(),
    });
    client
      .query(
        Missions_MissionEasyPlan,
        {
          filter: {
            minDate: get().day,
            maxDate: get().day,
            includeEarly: get().selectedShift.early,
            includeLate: get().selectedShift.late,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise()
      .then(async (result) => {
        console.log(result.data);
        const _missions = result?.data?.missionsTimeRange || [];

        const _distinctTourIds =
          _.uniqBy(_missions, "tourId").map((m) => m.tourId || "") || [];

        const _tourInfos = [] as TourInfo_MissionEasyPlan[];

        const _missionsMap = new Map<string, MissionPause[]>();
        const _notActiveMissionsMap = new Map<string, MissionPause[]>();

        const distinctMissionPublicationIds = _.uniqBy(
          _missions,
          "missionPublicationId"
        ).map((m) => m.missionPublicationId || "");

        if (distinctMissionPublicationIds.length > 1) {
          console.log(
            "Tour has multiple missionPublicationIds",

            distinctMissionPublicationIds
          );
          toast.error("Tour has multiple missionPublicationIds");
          return;
        }

        const retPauses = await client.query(TourPauses_MissionEasyPlan, {
          publicationDays: [
            {
              day: get().day,
              missionPublicationId: distinctMissionPublicationIds[0],
            },
          ],
          tourIds: _distinctTourIds,
        });

        console.log("Pusen da ", retPauses);
        const _tourPauses = retPauses?.data?.tourPauses || [];

        for (const tourId of _distinctTourIds) {
          console.log("TourId", tourId);
          const tourMissions = _missions.filter((m) => m.tourId === tourId);

          const tourMissionsWithPauses = tourMissions.map((m) => {
            return {
              ...m,
              type: "mission",
              isModifiedInDatabase: false,
              isTouched: false,
              isNewCreated: false,
            };
          });

          const tourPauses = _tourPauses.filter(
            (tp) => tp.tourId === tourId && tp.day === get().day
          );

          for (const tp of tourPauses) {
            const obj = {
              id: tp.id,
              missionPublicationId: tp.missionPublicationId,
              isActive: tp.isActive,
              updatedAt: "",
              patientId: "pause",
              duration_min: tp.duration_min,
              startMinuteOfDay: tp.startMinuteOfDay,
              endMinuteOfDay: tp.endMinuteOfDay,
              day: tp.day,
              tourId: tp.tourId,
              memberId: tp.memberId,
              memberHasQualificationLevel: "",
              member: undefined,
              patient: {
                lastName: "pause",
                firstName: "pause",
                isPrivate: false,
                shortName: "Pause",
              },
              type: "pause",
              isModifiedInDatabase: false,
              isTouched: false,
              isNewCreated: false,
            } as MissionPause;
            tourMissionsWithPauses.push(obj);
          }

          const tourStart = _.minBy(tourMissions, "startMinuteOfDay");

          _missionsMap.set(
            tourId,
            _.chain(tourMissionsWithPauses)
              .filter((m) => m.isActive === true)
              .orderBy("startMinuteOfDay", "asc")
              .value()
          );

          _notActiveMissionsMap.set(
            tourId,
            _.chain(tourMissionsWithPauses)
              .filter((m) => m.isActive === false)
              .orderBy("startMinuteOfDay", "asc")
              .value()
          );

          _tourInfos.push({
            tourId: tourId,
            day: get().day,
            startMinuteOfDay: tourStart?.startMinuteOfDay || 0,
            memberId: tourStart?.memberId || "",
            member: tourStart?.member,
            tourPauses: _tourPauses.filter((tp) => tp.tourId === tourId),
            currentMissions: _missionsMap.get(tourId) || [],
            originalMissions: _missionsMap.get(tourId) || [],
            isModifiedInDatabase: false,
            isModifiedInBrowser: false,
            isAutorouted: true,
          });
        }

        set({
          //       missions: _.orderBy(_missions, ["startMinuteOfDay"], ["asc"]),
          distinctTourIds: _.orderBy(_distinctTourIds),
          tourInfos_old: _tourInfos,
          missionsMap: _missionsMap,
          notActiveMissionsMap: _notActiveMissionsMap,
          isLoading: false,
        });
      });
  },

  recalculateTour: async (tourId) => {
    console.log("recalculateTour", tourId);

    const _tour = get().tours.find((t) => t.tourId === tourId);

    if (!_tour) {
      toast.error("recalculateTour: TourId not found: " + tourId);
      set({ isLoading: false });
      return;
    }

    const _missionIds = _tour.currentMissionIds;
    set({ isLoading: true });

    const __missionId = _missionIds[0];
    const __mission = get().activeMissionMap?.get(__missionId);

    if (!__mission) {
      toast.error("Mission not found");
      return;
    }

    __mission.isTouched = true;
    __mission.startMinuteOfDay = _tour.startMinuteOfDay;
    __mission.endMinuteOfDay = _.round(
      __mission.startMinuteOfDay + __mission.duration_min,
      0
    );

    for (let i = 1; i < _missionIds.length; i++) {
      const toMissionId = _missionIds[i];
      const toMission = get().activeMissionMap?.get(toMissionId);
      const fromMission = get().activeMissionMap?.get(_missionIds[i - 1]);

      if (!toMission || !fromMission) {
        toast.error("Mission not found");
        return;
      }

      let fromPatientId = fromMission?.patientId;
      let toPatientId = toMission?.patientId;

      //   if (fromPatientId === "pause") {
      //     fromPatientId = _missions[i].patientId;
      //   }

      //   if (toPatientId === "pause") {
      //     toPatientId = _missions[i + 1].patientId;
      //   }

      if (fromPatientId === "pause") {
        const _mission = get().activeMissionMap?.get(_missionIds[i]);
        if (!_mission) {
          toast.error("Mission not found");
          return;
        }
        fromPatientId = _mission.patientId;
        console.log("From Pause", fromPatientId);
      }

      if (toPatientId === "pause") {
        const nextToMission = get().activeMissionMap?.get(_missionIds[i + 1]);
        toPatientId = nextToMission?.patientId || "";
        console.log("To Pause", nextToMission, toPatientId);
      }

      // manange pause in regard to trfavel time

      const { data } = await client
        .query(SingleDistance_MissionEasyPlan, {
          fromPatientId: fromPatientId,
          toPatientId: toPatientId,
        })
        .toPromise();

      const sdTravelTime =
        fromPatientId === toPatientId
          ? 0
          : _.round(data?.singleDistance?.travelTime_min || 7, 0);

      toMission.startMinuteOfDay = _.round(
        fromMission.startMinuteOfDay + fromMission.duration_min + sdTravelTime,
        0
      );

      toMission.endMinuteOfDay = _.round(
        toMission.startMinuteOfDay + toMission.duration_min,
        0
      );
      toMission.isTouched = true;

      //      console.log("Mission", toMission, sdTravelTime);
    }

    const _newTours = produce(get().tours, (draft) => {
      const _tourIndex = draft.findIndex((t) => t.tourId === tourId);

      draft[_tourIndex].isModifiedInBrowser = true;
      draft[_tourIndex].isAutorouted = true;
    });

    set({ tours: _newTours, isLoading: false });
  },

  /**
   *
   * @param tourId
   * @returns
   */
  recalculateTour_old: async (tourId) => {
    console.log("recalculateTour_old", tourId);
    // set({ isLoading: true });

    // const missions = get().missionsMap?.get(tourId) || [];
    // const _missions = missions.map((mission) => ({ ...mission }));
    // const _tourInfo = get().tourInfos_old.find((t) => t.tourId === tourId);

    // if (tourId === "") {
    //   toast.error("recalculateTour: TourId not found: " + tourId);
    //   set({ isLoading: false });
    //   return;
    // }

    // if (!_tourInfo) {
    //   toast.error(tourId + " recalculateTour: Tour not found: " + tourId);
    //   set({ isLoading: false });
    //   return;
    // }

    // if (_missions.length === 0) {
    //   toast.error("recalculateTour: Tour hat keine Einsätze " + tourId);
    //   set({ isLoading: false });
    //   return;
    // }

    // _missions[0].isTouched = true;
    // _missions[0].startMinuteOfDay = _tourInfo?.startMinuteOfDay || 360;
    // _missions[0].endMinuteOfDay = _.round(
    //   _missions[0].startMinuteOfDay + _missions[0].duration_min,
    //   0
    // );

    // for (let i = 1; i < _missions.length; i++) {
    //   const fromMission = _missions[i - 1];
    //   const toMission = _missions[i];

    //   let fromPatientId = fromMission.patientId;
    //   let toPatientId = toMission.patientId;

    //   if (fromPatientId === "pause") {
    //     fromPatientId = _missions[i].patientId;
    //   }

    //   if (toPatientId === "pause") {
    //     toPatientId = _missions[i + 1].patientId;
    //   }

    //   // manange pause in regard to trfavel time

    //   const { data } = await client
    //     .query(SingleDistance_MissionEasyPlan, {
    //       fromPatientId: fromPatientId,
    //       toPatientId: toPatientId,
    //     })
    //     .toPromise();

    //   const sdTravelTime =
    //     fromPatientId === toPatientId
    //       ? 0
    //       : _.round(data?.singleDistance?.travelTime_min || 7, 0);

    //   _missions[i].startMinuteOfDay = _.round(
    //     _missions[i - 1].startMinuteOfDay +
    //       _missions[i - 1].duration_min +
    //       sdTravelTime,
    //     0
    //   );
    //   _missions[i].endMinuteOfDay = _.round(
    //     _missions[i].startMinuteOfDay + _missions[i].duration_min,
    //     0
    //   );
    //   _missions[i].isTouched = true;
    //   get().touchedMissionIds.push(_missions[i].id);
    // }

    // const _missionMap = get().missionsMap;

    // set({
    //   missionsMap: _missionMap?.set(tourId, _missions),
    //   isLoading: false,
    // });

    // const _socket = get().socketMonitorTourIds;

    // if (_socket) {
    //   console.log("Socket Emit");
    //   _socket.emit("mission_easy_plan", _missions);
    // }

    // console.log("Updated Tour", tourId);

    // update missionsMap
  },
  calculateNearestPatients: async (patientId) => {
    const { data } = await client
      .query(NearestPatients_MissionEasyPlan, {
        patientId: patientId,
        minutes: 3,
      })
      .toPromise();
    console.log(data);
    const min_3_PatientsIds = data?.nearByPatients.map((p) => p.patientId);

    const { data: data5 } = await client
      .query(NearestPatients_MissionEasyPlan, {
        patientId: patientId,
        minutes: 5,
      })
      .toPromise();
    console.log(data5);
    const min_5_PatientsIds = data5?.nearByPatients.map((p) => p.patientId);

    const { data: data7 } = await client
      .query(NearestPatients_MissionEasyPlan, {
        patientId: patientId,
        minutes: 7,
      })
      .toPromise();
    console.log(data7);
    const min_7_PatientsIds = data7?.nearByPatients.map((p) => p.patientId);

    const { data: data12 } = await client
      .query(NearestPatients_MissionEasyPlan, {
        patientId: patientId,
        minutes: 12,
      })
      .toPromise();
    console.log(data12);
    const min_12_PatientsIds = data12?.nearByPatients.map((p) => p.patientId);

    set({
      min_3_PatientsIds: min_3_PatientsIds || [],
      min_5_PatientsIds: min_5_PatientsIds || [],
      min_7_PatientsIds: min_7_PatientsIds || [],
      min_12_PatientsIds: min_12_PatientsIds || [],
    });
    toast.success("Fahrtzeiten berechnet");
  },
  clearNearestPatients: () => {
    set({
      min_3_PatientsIds: [],
      min_5_PatientsIds: [],
      min_7_PatientsIds: [],
      min_12_PatientsIds: [],
    });
  },

  moveMission: (
    missionIdFrom,
    missionIdInto,
    tourIdFrom,
    tourIdInto,
    undoWanderList
  ) => {
    console.log(
      "moveMission",
      missionIdFrom,
      missionIdInto,
      tourIdFrom,
      tourIdInto
    );

    const _fromTour = get().tours.find((t) => t.tourId === tourIdFrom);
    const _intoTour = get().tours.find((t) => t.tourId === tourIdInto);

    if (!_fromTour || !_intoTour) {
      toast.error("Tour nicht gefunden");
      return;
    }

    if (_fromTour === _intoTour) {
      const _missionFrom = get().getMissionById(missionIdFrom);
      if (!_missionFrom) {
        toast.error("Mission nicht gefunden");
        return;
      }

      const _newMissionIds = produce(_fromTour.currentMissionIds, (draft) => {
        const _fromIndex = draft.findIndex((m) => m === missionIdFrom);

        const _missionIdFrom = draft[_fromIndex];
        const _intoIndex = draft.findIndex((m) => m === missionIdInto);

        draft.splice(_fromIndex, 1);
        if (_intoIndex !== -1) {
          draft.splice(_intoIndex, 0, _missionIdFrom);
        } else {
          draft.push(_missionIdFrom);
        }
      });

      const _newTours = produce(get().tours, (draft) => {
        const _tourIndex = draft.findIndex((t) => t.tourId === tourIdFrom);
        draft[_tourIndex].currentMissionIds = _newMissionIds;
        draft[_tourIndex].isModifiedInBrowser = true;
        draft[_tourIndex].isAutorouted = false;
      });
      if (undoWanderList === false) {
        const _newWanderlist = produce(get().wanderList, (draft) => {
          draft.push({
            id: nanoid(),
            patientId: _missionFrom.patientId,
            missionId: missionIdFrom,
            patient: {
              firstName: _missionFrom.patient?.firstName || "",
              lastName: _missionFrom.patient?.lastName || "",
              shortName: _missionFrom.patient?.shortName || "",
              isPrivate: _missionFrom.patient?.isPrivate || false,
            },
            originalTime: _missionFrom.startMinuteOfDay.toString(),
            fromTourId: tourIdFrom,
            fromTourMember: _fromTour.member || undefined,
            intoTourId: tourIdInto,
            intoTourMember: _intoTour.member || undefined,
          });
        });
        set({
          wanderList: _newWanderlist,
        });
      }

      set({
        tours: _newTours,
      });
    } else {
      console.log("Move between tours");
      console.log("MissionIds from", _fromTour.currentMissionIds);
      console.log("MissionIds into", _intoTour.currentMissionIds);

      const _newMissionIdsFrom = produce(
        _fromTour.currentMissionIds,
        (draft) => {
          const _fromIndex = draft.findIndex((m) => m === missionIdFrom);
          if (_fromIndex === -1) {
            toast.error("Mission nicht gefunden");
            return;
          }

          draft.splice(_fromIndex, 1);
          return draft;
        }
      );

      const _newMissionIdsInto = produce(
        _intoTour.currentMissionIds,
        (draft) => {
          const _intoIndex = draft.findIndex((m) => m === missionIdInto);
          if (_intoIndex !== -1) {
            draft.splice(_intoIndex, 0, missionIdFrom);
          } else {
            draft.push(missionIdFrom);
          }

          const _missionFrom = get().getMissionById(missionIdFrom);

          if (!_missionFrom) {
            toast.error("Mission nicht gefunden");
            throw new Error("Mission nicht gefunden");
          }

          _missionFrom.tourId = tourIdInto;
          _missionFrom.memberId = _intoTour.memberId || "";
          _missionFrom.member = _intoTour.member;

          get().activeMissionMap?.set(missionIdFrom, _missionFrom);

          return draft;
        }
      );

      console.log("NewMissionIdsFrom", _newMissionIdsFrom);
      console.log("NewMissionIdsInto", _newMissionIdsInto);

      const _newTours = produce(get().tours, (draft) => {
        const _tourFromIndex = draft.findIndex((t) => t.tourId === tourIdFrom);
        const _tourIntoIndex = draft.findIndex((t) => t.tourId === tourIdInto);

        if (_tourFromIndex === -1 || _tourIntoIndex === -1) {
          toast.error("Tour nicht gefunden");
          return;
        }

        draft[_tourFromIndex].currentMissionIds = _newMissionIdsFrom;
        draft[_tourIntoIndex].currentMissionIds = _newMissionIdsInto;

        draft[_tourFromIndex].isModifiedInBrowser = true;
        draft[_tourIntoIndex].isModifiedInBrowser = true;
        draft[_tourFromIndex].isAutorouted = false;
        draft[_tourIntoIndex].isAutorouted = false;

        return draft;
      });

      if (undoWanderList === false) {
        const _missionFrom = get().getMissionById(missionIdFrom);
        if (!_missionFrom) {
          toast.error("Mission nicht gefunden");
          return;
        }

        const _newWanderlist = produce(get().wanderList, (draft) => {
          draft.push({
            id: nanoid(),
            patientId: _missionFrom.patientId,
            missionId: missionIdFrom,
            patient: {
              firstName: _missionFrom.patient?.firstName || "",
              lastName: _missionFrom.patient?.lastName || "",
              shortName: _missionFrom.patient?.shortName || "",
              isPrivate: _missionFrom.patient?.isPrivate || false,
            },
            originalTime: _missionFrom.startMinuteOfDay.toString(),
            fromTourId: tourIdFrom,
            fromTourMember: _fromTour.member || undefined,
            intoTourId: tourIdInto,
            intoTourMember: _intoTour.member || undefined,
          });
        });
        set({
          wanderList: _newWanderlist,
        });
      }

      set({
        tours: _newTours,
      });
    }
  },

  moveMission_old: (missionIdFrom, missionIdInto, tourIdFrom, tourIdInto) => {
    // move mission within the same tour. Insertt is before the missionIdInto

    console.log(
      "moveMission",
      missionIdFrom,
      missionIdInto,
      tourIdFrom,
      tourIdInto
    );

    const _missionsMap = get().missionsMap;
    if (!_missionsMap) {
      toast.error(
        "moveMissions: Missions not loaded " + tourIdFrom + " " + tourIdInto
      );
      return;
    }

    const _tourInfoFrom = get().tourInfos_old.find(
      (t) => t.tourId === tourIdFrom
    );
    const _tourInfoInto = get().tourInfos_old.find(
      (t) => t.tourId === tourIdInto
    );

    let fromIndex = -1;
    let intoIndex = 1;

    if (!_tourInfoFrom || !_tourInfoInto) {
      toast.error("moveMission: Tour not found");
      return;
    }

    if (_tourInfoFrom.memberId === undefined) {
      toast.error("Tour hat keine Mitarbeiter");
      return;
    }
    if (_tourInfoInto.memberId === undefined) {
      toast.error("Tour hat keinen Mitarbeiter");
      return;
    }

    if (tourIdFrom == tourIdInto) {
      if (missionIdInto !== null) {
        const _missions = _missionsMap.get(tourIdFrom) || [];
        const _missionFrom = _missions.find((m) => m.id === missionIdFrom);
        const _missionInto = _missions.find((m) => m.id === missionIdInto);

        if (!_missionFrom || !_missionInto) {
          toast.error("MissionFrom or MissionInto not found");
          return;
        }

        const _newMissions = produce(_missions, (draft) => {
          const _missionFromIndex = draft.findIndex(
            (m) => m.id === missionIdFrom
          );
          fromIndex = _missionFromIndex;
          const _missionIntoIndex = draft.findIndex(
            (m) => m.id === missionIdInto
          );
          intoIndex = _missionIntoIndex;

          draft.splice(_missionFromIndex, 1);
          draft.splice(_missionIntoIndex, 0, _missionFrom);
        });

        _missionsMap.set(tourIdFrom, _newMissions);

        //console.log(_missionsMap);

        // determine touched missionIds
        const _touchedMissionIds = get().touchedMissionIds;
        const newTouchedMissionIds = produce(_touchedMissionIds, (draft) => {
          const _minIndex = Math.min(fromIndex, intoIndex);

          for (let i = _minIndex; i < _missions.length; i++) {
            draft.push(_missions[i].id);
          }
          return draft;
        });

        set({
          missionsMap: _missionsMap,
          touchedMissionIds: _.uniq(newTouchedMissionIds),
        });
      } else {
        // move mission to the end of the tour
        const _missions = _missionsMap.get(tourIdFrom) || [];
        const _missionFrom = _missions.find((m) => m.id === missionIdFrom);
        if (!_missionFrom) {
          toast.error("MissionFrom not found");
          return;
        }

        const _newMissions = produce(_missions, (draft) => {
          const _missionFromIndex = draft.findIndex(
            (m) => m.id === missionIdFrom
          );
          draft.splice(_missionFromIndex, 1);
          draft.push(_missionFrom);
          fromIndex = _missionFromIndex;
          intoIndex = draft.length - 1;
        });

        _missionsMap.set(tourIdFrom, _newMissions);
      }
      if (get().selectedTourId !== tourIdFrom) {
        get().recalculateTour_old(tourIdFrom);
      }
    } else {
      // move mission to another tour
      if (missionIdInto !== null) {
        const _missionsFrom = _missionsMap.get(tourIdFrom) || [];
        const _missionsInto = _missionsMap.get(tourIdInto) || [];
        const _missionFrom = _missionsFrom.find((m) => m.id === missionIdFrom);
        const _missionInto = _missionsInto.find((m) => m.id === missionIdInto);

        if (!_missionFrom || !_missionInto) {
          toast.error("MissionFrom or MissionInto not found");
          return;
        }

        const _newMissionsFrom = produce(_missionsFrom, (draft) => {
          const _missionFromIndex = draft.findIndex(
            (m) => m.id === missionIdFrom
          );
          draft.splice(_missionFromIndex, 1);
          fromIndex = _missionFromIndex;
        });

        const _newMissionsInto = produce(_missionsInto, (draft) => {
          const _missionIntoIndex = draft.findIndex(
            (m) => m.id === missionIdInto
          );

          draft.splice(_missionIntoIndex, 0, {
            ..._missionFrom,
            memberId: _tourInfoInto.memberId || "nichtDa",

            tourId: tourIdInto,
          });
          intoIndex = _missionIntoIndex;
        });

        _missionsMap.set(tourIdFrom, _newMissionsFrom);
        _missionsMap.set(tourIdInto, _newMissionsInto);

        set({
          missionsMap: _missionsMap,
        });
      } else {
        // move mission to the end of the tour
        const _missionsFrom = _missionsMap.get(tourIdFrom) || [];
        const _missionsInto = _missionsMap.get(tourIdInto) || [];

        const _missionFrom = _missionsFrom.find((m) => m.id === missionIdFrom);

        console.log(_missionsFrom, _missionFrom);

        if (!_missionFrom) {
          toast.error("MissionFrom not found");
          return;
        }
        const _newMissionsFrom = produce(_missionsFrom, (draft) => {
          const _missionFromIndex = draft.findIndex(
            (m) => m.id === missionIdFrom
          );
          draft.splice(_missionFromIndex, 1);
          fromIndex = _missionFromIndex;
        });

        const _newMissionsInto = produce(_missionsInto, (draft) => {
          draft.push({
            ..._missionFrom,
            memberId: _tourInfoInto.memberId || "nichtDa",
            tourId: tourIdInto,
          });
          intoIndex = draft.length - 1;
        });

        _missionsMap.set(tourIdFrom, _newMissionsFrom);
        _missionsMap.set(tourIdInto, _newMissionsInto);

        set({
          missionsMap: _missionsMap,
        });
      }

      // if (get().selectedTourId !== tourIdFrom) {
      //   get().recalculateTour(tourIdFrom);
      // }
      //   get().recalculateTour(tourIdInto);
    }

    const _movedMissions = get().moveMissions;
    const newMovedMissions = produce(_movedMissions, (draft) => {
      draft.push({
        missionId: missionIdFrom,
        fromTourId: tourIdFrom,
        intoTourId: tourIdInto,
        fromIndex: fromIndex,
        intoIndex: intoIndex,
      });
      return draft;
    });
    console.log(newMovedMissions);
    set({
      moveMissions: newMovedMissions,
    });
  },
  setTourMoveFrom: (tourId) => {
    set({ tourMoveFrom: tourId });
  },
  setTourMoveInto: (tourId) => {
    set({ tourMoveInto: tourId });
  },
  setSelectedMissionId: (missionId) => {
    set({ selectedMissionId: missionId });
  },
  setSelectedTourId: (tourId) => {
    set({ selectedTourId: tourId });
  },
  setMissionStatus({ missionId, tourId, isActive }) {
    const _missionsMap = get().missionsMap;
    const _notActiveMissionsMap = get().notActiveMissionsMap;

    const _missions = _missionsMap?.get(tourId) || [];
    const _notActiveMissions = _notActiveMissionsMap?.get(tourId) || [];

    const _mission = _missions.find((m) => m.id === missionId);

    if (_mission?.isNewCreated === true && isActive === false) {
      //remove mission from tour

      const newMissions = produce(_missions, (draft) => {
        const index = draft.findIndex((m) => m.id === missionId);

        if (index === -1) {
          toast.error("Mission not found in notActiveMissions");
          return draft;
        }

        draft.splice(index, 1);
        return draft;
      });

      _missionsMap?.set(tourId, newMissions);
      set({
        missionsMap: _missionsMap,
      });

      return;
    }

    if (isActive === true) {
      const _notActiveMission = _notActiveMissions.find(
        (m) => m.id === missionId
      );

      if (!_notActiveMission) {
        toast.error("Mission not found in notActiveMissions");
        return;
      }

      const newMissions = produce(_missions, (draft) => {
        draft.push({ ..._notActiveMission, isActive: true, isTouched: true });
        return draft;
      });

      const newNotActiveMissions = produce(_notActiveMissions, (draft) => {
        const index = draft.findIndex((m) => m.id === missionId);

        if (index === -1) {
          toast.error("Mission not found in notActiveMissions");
          return draft;
        }

        draft.splice(index, 1);
        return draft;
      });

      _missionsMap?.set(tourId, newMissions);
      _notActiveMissionsMap?.set(tourId, newNotActiveMissions);
    }

    if (isActive === false) {
      const _mission = _missions.find((m) => m.id === missionId);

      if (!_mission) {
        toast.error("Mission not found in notActiveMissions");
        return;
      }

      const newNotActiveMissions = produce(_notActiveMissions, (draft) => {
        draft.push({ ..._mission, isActive: false, isTouched: true });
        return draft;
      });

      const newMissions = produce(_missions, (draft) => {
        const index = draft.findIndex((m) => m.id === missionId);

        if (index === -1) {
          toast.error("Mission not found in notActiveMissions");
          return draft;
        }

        draft.splice(index, 1);
        return draft;
      });

      _missionsMap?.set(tourId, newMissions);
      _notActiveMissionsMap?.set(tourId, newNotActiveMissions);
    }

    set({
      missionsMap: _missionsMap,
      notActiveMissionsMap: _notActiveMissionsMap,
    });
    get().recalculateTour_old(tourId);
  },
  setSelectedMission: (mission) => {
    set({ selectedMission: mission });
  },

  getMissionById: (missionId) => {
    const _tours = get().tours;

    if (!_tours) {
      toast.error("getMissionById: Missions not loaded " + missionId);
      return undefined;
    }

    const _mission = get().activeMissionMap?.get(missionId);

    // for (let tour of _tours) {
    //   const _mission = tour.currentMissions.find((m) => m.id === missionId);
    //   if (_mission) {
    //     return _mission;
    //   }
    // }

    return _mission;
  },

  getMissionById_old: (missionId) => {
    const _missionsMap = get().missionsMap;
    const _notActiveMissionsMap = get().notActiveMissionsMap;
    const distinctTourIds = get().distinctTourIds;

    console.log("getMissionById", missionId);
    if (
      !_notActiveMissionsMap ||
      !_missionsMap ||
      !distinctTourIds ||
      distinctTourIds.length === 0
    ) {
      toast.error("getMissionById: Missions not loaded " + missionId);
      return undefined;
    }

    for (let tourId of distinctTourIds) {
      const missions = _missionsMap.get(tourId) || [];
      const notActiveMissions = _notActiveMissionsMap.get(tourId) || [];

      const _mission = missions.find((m) => m.id === missionId);
      if (_mission) {
        return _mission;
      }

      const _notActiveMission = notActiveMissions.find(
        (m) => m.id === missionId
      );
      if (_notActiveMission) {
        return _notActiveMission;
      }
    }

    return undefined;
  },
  setMissionActions({ missionId, actions, updatedAt }) {
    client
      .mutation(MissionSetAction_MissionEasyPlan, {
        id: missionId,
        actionIdsWQ: actions,
        updatedAt: updatedAt,
      })
      .toPromise()
      .then((result) => {
        console.log(result);
        if (!result.data?.missionSetAction) {
          toast.error("Failed to update mission" + result.error);
          return;
        }

        const _tourId = result.data.missionSetAction.tourId;
        const _returnedMission = result.data.missionSetAction;

        if (!_tourId) {
          toast.error("TourId not found");
          return;
        }

        const _missionsMap = get().missionsMap;
        const _missions = _missionsMap?.get(_tourId) || [];
        const _mission = _missions.find((m) => m.id === missionId);

        if (_mission) {
          console.log("Updating mission in missionsMap");
          const newMissions = produce(_missions, (draft) => {
            const index = draft.findIndex((m) => m.id === missionId);

            if (index === -1) {
              toast.error("Mission not found");
              return draft;
            }

            draft[index].actionsWithQuantity =
              _returnedMission.actionsWithQuantity;
            draft[index].updatedAt = _returnedMission.updatedAt;
            draft[index].minReqQualification =
              _returnedMission.minReqQualification;

            return draft;
          });

          console.log(newMissions);

          _missionsMap?.set(_tourId, newMissions);

          //get().recalculateTour(_tourId);

          set({
            missionsMap: _missionsMap,
            randomValue: Math.random(),
          });
        }
      });
  },

  saveAllMissions: async () => {
    console.log("saveAllMissions");
    set({ isLoading: true });
    set({ isSaving: true });

    const _allMissions = get().activeMissionMap;

    if (!_allMissions) {
      toast.error("No Missions found");
      return;
    }

    const _modifiedMissions = Array.from(_allMissions.values()).filter(
      (m) => m.isTouched && m.type === "mission"
    );

    const _modifedPauses = Array.from(_allMissions.values()).filter(
      (m) => m.isTouched && m.type === "pause"
    );

    const _missionsToSave = _modifiedMissions.map((m) => {
      const hoursStart = Math.floor(m.startMinuteOfDay / 60);
      const minutesStart = m.startMinuteOfDay % 60;
      const hoursEnd = Math.floor(m.endMinuteOfDay / 60);
      const minutesEnd = m.endMinuteOfDay % 60;
      const dateTimeStart = dayjs(m.day)
        .hour(hoursStart)
        .minute(minutesStart)
        .second(0)
        .millisecond(0);
      const dateTimeEnd = dayjs(m.day)
        .hour(hoursEnd)
        .minute(minutesEnd)
        .second(0)
        .millisecond(0);

      return {
        id: m.id,
        startTS: dateTimeStart.toISOString(),
        endTS: dateTimeEnd.toISOString(),
        tourId: m.tourId,
        memberId: m.memberId,
        updatedAt: m.updatedAt,
        isActive: m.isActive,
      };
    });

    console.log(_missionsToSave);

    const _pausesToSave = _modifedPauses.map((m) => {
      const hoursStart = Math.floor(m.startMinuteOfDay / 60);
      const minutesStart = m.startMinuteOfDay % 60;
      const hoursEnd = Math.floor(m.endMinuteOfDay / 60);
      const minutesEnd = m.endMinuteOfDay % 60;
      const dateTimeStart = dayjs(m.day)
        .hour(hoursStart)
        .minute(minutesStart)
        .second(0)
        .millisecond(0);
      const dateTimeEnd = dayjs(m.day)
        .hour(hoursEnd)
        .minute(minutesEnd)
        .second(0)
        .millisecond(0);

      /**
 * 
 *                tourId: tourId,
          missionPublicationId: _publicationId,
          startDate: dateTimeStart.toISOString(),
          endDate: dayjs(dateTimeStart).add(duration, "minute").toISOString(),
          groupId: _tour.day + "_" + tourId,

          memberId: _tour.memberId || "",
          id: createId(),
 */

      return {
        id: m.id,
        missionPublicationId: m.missionPublicationId,
        startDate: dateTimeStart.toISOString(),
        endDate: dateTimeEnd.toISOString(),
        tourId: m.tourId || "nichtda",
        memberId: m.memberId,
        groupId: m.day + "_" + m.tourId,
      };
    });

    const promiseUpdateMissions = client
      .mutation(UpdateManyMissionsEnhanced_MissionEasyPlan, {
        input: _missionsToSave,
      })
      .toPromise()
      .then((result) => {
        console.log(result);
        if (!result.data?.updateManyMissionsEnhanced?.written) {
          toast.error("Failed to update missions");
          //get().loadData();
        } else {
          toast.success("Database Update");
          //get().loadData();
        }
      });

    const promiseUpsertTourPauses = [];

    for (let pause of _pausesToSave) {
      const promiseUpsertTourPause = client
        .mutation(UpsertOneTourPause_MissionEasyPlan, {
          input: { ...pause },
        })
        .toPromise()
        .then((result) => {
          console.log(result);
          if (!result.data?.upsertOneTourPause) {
            toast.error("Failed to update pauses");
            //get().loadData();
          } else {
            toast.success("Database Update");
            //get().loadData();
          }
        });
      promiseUpsertTourPauses.push(promiseUpsertTourPause);
    }

    Promise.all([promiseUpdateMissions, promiseUpsertTourPauses]).then(() => {
      toast.success("Alle Einsätze / Pausen gespeichert");
      set({ isSaving: false, isLoading: false, isUpdating: false });
      get().loadData();
    });
  },

  saveAllMissions_old: async () => {
    console.log("saveAllMissions_old");
    set({ isLoading: true });
    set({ isSaving: true });

    const _missionsMap = get().missionsMap;
    const _tourIds = get().distinctTourIds;

    const _toUpdateMissions = [];
    const _toDeletePauses = [];
    const _toUpsertPauses = [];

    for (let tourId of _tourIds) {
      const _missions = _missionsMap?.get(tourId) || [];
      const _notActiveMissions = get().notActiveMissionsMap?.get(tourId) || [];
      const _tourInfo = get().tourInfos_old.find((t) => t.tourId === tourId);

      if (!_tourInfo) {
        toast.error("TourInfo not found");
        return;
      }
      const dayDate = dayjs(_tourInfo.day);

      const _touchedMisisons = _.chain(_missions)
        .filter(
          (m) => m.isNewCreated === false && m.isTouched && m.type === "mission"
        )
        .map((m) => {
          const hoursStart = Math.floor(m.startMinuteOfDay / 60);
          const minutesStart = m.startMinuteOfDay % 60;
          const hoursEnd = Math.floor(m.endMinuteOfDay / 60);
          const minutesEnd = m.endMinuteOfDay % 60;
          const dateTimeStart = dayDate
            .hour(hoursStart)
            .minute(minutesStart)
            .second(0)
            .millisecond(0);
          const dateTimeEnd = dayDate
            .hour(hoursEnd)
            .minute(minutesEnd)
            .second(0)
            .millisecond(0);

          return {
            id: m.id,
            startTS: dateTimeStart.toISOString(),
            endTS: dateTimeEnd.toISOString(),
            tourId: m.tourId,
            memberId: m.memberId,
            updatedAt: m.updatedAt,
            isActive: m.isActive,
          };
        })
        .value();

      _toUpdateMissions.push(..._touchedMisisons);

      console.log("Touched Missions ", _touchedMisisons);

      const _newMisisons = _.chain(_missions)
        .filter(
          (m) => m.isNewCreated === true && m.isTouched && m.type === "mission"
        )
        .map((m) => {
          const hoursStart = Math.floor(m.startMinuteOfDay / 60);
          const minutesStart = m.startMinuteOfDay % 60;
          const hoursEnd = Math.floor(m.endMinuteOfDay / 60);
          const minutesEnd = m.endMinuteOfDay % 60;
          const dateTimeStart = dayDate
            .hour(hoursStart)
            .minute(minutesStart)
            .second(0)
            .millisecond(0);
          const dateTimeEnd = dayDate
            .hour(hoursEnd)
            .minute(minutesEnd)
            .second(0)
            .millisecond(0);

          return {
            id: m.id,
            startTS: dateTimeStart.toISOString(),
            endTS: dateTimeEnd.toISOString(),
            tourId: m.tourId,
            memberId: m.memberId,
            updatedAt: m.updatedAt,
            isActive: m.isActive,
            patientId: m.patientId,
            duration_min: m.duration_min,
            actionsWithQantity: m.actionsWithQuantity || [],
          };
        })
        .value();

      console.log("New Missions ", _newMisisons);

      for (let m of _newMisisons) {
        let _awqs = [] as ActionIdWithQuantity[];

        for (let awq of m.actionsWithQantity) {
          if (awq === undefined) continue;
          if (awq === null) continue;
          if (awq.action === undefined) continue;
          if (awq.action === null) continue;

          if (awq.quantity === undefined) continue;
          if (awq.quantity === null) continue;

          _awqs.push({
            quantity: awq.quantity,
            lnr: awq.action.lnr,
          });
        }

        const retNewMission = await client.mutation(
          AddMissionToDay_MissionEasyPlan,
          {
            input: {
              day: _tourInfo.day,
              mission: {
                startTS: m.startTS,
                endTS: m.endTS,
                actionsWithQantity: _awqs,
                tourId: m.tourId || "nichtDa",
                patientId: m.patientId,
                memberId: m.memberId,
              },
            },
          }
        );
        console.log(retNewMission);
      }

      const _touchedPauses = _.chain(_missions)
        .filter((m) => m.isTouched && m.type === "pause")
        .map((m) => {
          const hoursStart = Math.floor(m.startMinuteOfDay / 60);
          const minutesStart = m.startMinuteOfDay % 60;
          const hoursEnd = Math.floor(m.endMinuteOfDay / 60);
          const minutesEnd = m.endMinuteOfDay % 60;
          const dateTimeStart = dayDate
            .hour(hoursStart)
            .minute(minutesStart)
            .second(0)
            .millisecond(0);
          const dateTimeEnd = dayDate
            .hour(hoursEnd)
            .minute(minutesEnd)
            .second(0)
            .millisecond(0);

          return {
            id: m.id,
            startDate: dateTimeStart.toISOString(),
            endDate: dateTimeEnd.toISOString(),
            tourId: m.tourId || "nichtDa",
            groupId: m.day + "_" + m.tourId,
            missionPublicationId: m.missionPublicationId,
            memberId: m.memberId,
          };
        })
        .value();

      console.log(_touchedPauses);
      _toUpsertPauses.push(..._touchedPauses);

      const _touchedNotActiveMissions = _.chain(_notActiveMissions)
        .filter((m) => m.isTouched && m.type === "mission")
        .map((m) => {
          const hoursStart = Math.floor(m.startMinuteOfDay / 60);
          const minutesStart = m.startMinuteOfDay % 60;
          const hoursEnd = Math.floor(m.endMinuteOfDay / 60);
          const minutesEnd = m.endMinuteOfDay % 60;
          const dateTimeStart = dayDate
            .hour(hoursStart)
            .minute(minutesStart)
            .second(0)
            .millisecond(0);
          const dateTimeEnd = dayDate
            .hour(hoursEnd)
            .minute(minutesEnd)
            .second(0)
            .millisecond(0);

          return {
            id: m.id,
            startTS: dateTimeStart.toISOString(),
            endTS: dateTimeEnd.toISOString(),
            tourId: m.tourId,
            memberId: m.memberId,
            updatedAt: m.updatedAt,
            isActive: m.isActive,
          };
        })
        .value();

      _toUpdateMissions.push(..._touchedNotActiveMissions);

      const _touchedNotActivePauses = _.chain(_notActiveMissions)
        .filter((m) => m.isTouched && m.type === "pause")
        .map((m) => {
          const hoursStart = Math.floor(m.startMinuteOfDay / 60);
          const minutesStart = m.startMinuteOfDay % 60;
          const hoursEnd = Math.floor(m.endMinuteOfDay / 60);
          const minutesEnd = m.endMinuteOfDay % 60;
          const dateTimeStart = dayDate
            .hour(hoursStart)
            .minute(minutesStart)
            .second(0)
            .millisecond(0);
          const dateTimeEnd = dayDate
            .hour(hoursEnd)
            .minute(minutesEnd)
            .second(0)
            .millisecond(0);

          return {
            id: m.id,
            startDate: dateTimeStart.toISOString(),
            endDate: dateTimeEnd.toISOString(),
            tourId: m.tourId || "nichtDa",
            groupId: m.day + "_" + m.tourId,
            missionPublicationId: m.missionPublicationId,
            memberId: m.memberId,
          };
        })
        .value();

      console.log(_touchedNotActivePauses);
      _toDeletePauses.push(..._touchedNotActivePauses);
    }

    console.log(_toUpdateMissions);
    const promiseUpdateMissions = client
      .mutation(UpdateManyMissionsEnhanced_MissionEasyPlan, {
        input: _toUpdateMissions,
      })
      .toPromise()
      .then((result) => {
        console.log(result);
        if (!result.data?.updateManyMissionsEnhanced?.written) {
          toast.error("Failed to update missions");
          //get().loadData();
        } else {
          toast.success("Database Update");
          //get().loadData();
        }
      });

    const promises_DeletePauses = [] as any[];

    _toDeletePauses.forEach((pause) => {
      const prom = client
        .mutation(DeleteOneTourPause_MissionEasyPlan, {
          id: pause.id,
        })
        .toPromise()
        .then((result) => {
          console.log(result);
          if (!result.data?.deleteOneTourPause) {
            toast.error("Failed to delete pause");
            //get().loadData();
          } else {
            toast.success("Pause deleted");
            //get().loadData();
          }
        });
      promises_DeletePauses.push(prom);
    });

    const promisesUpsertPauses = [] as any[];

    _toUpsertPauses.forEach((pause) => {
      console.log(pause);
      const prom = client
        .mutation(UpsertOneTourPause_MissionEasyPlan, {
          input: pause,
        })
        .toPromise()
        .then((result) => {
          console.log(result);
          if (!result.data?.upsertOneTourPause) {
            toast.error("Failed to upsert pause");
            // get().loadData();
          } else {
            toast.success("Pause upserted");
            //get().loadData();
          }
        });

      promisesUpsertPauses.push(prom);
    });
    const allPromises = [
      ...promisesUpsertPauses,
      ...promises_DeletePauses,
      promiseUpdateMissions,
    ];

    console.log(allPromises);

    await Promise.all(allPromises).then(() => {
      console.log("All Promises done");
      set({
        touchedMissionIds: [],
        touchedTourIds: [],
        isLoading: false,
      });
      get().loadData_old();
      set({ isSaving: false });
    });
  },

  setTourStartTime(tourId, startMinuteOfDay) {
    const _tours = get().tours;
    const _tour = _tours.find((t) => t.tourId === tourId);

    if (!_tour) {
      toast.error("SetTourStartTime: Tour not found");
      return;
    }

    const _newTours = produce(_tours, (draft) => {
      const index = draft.findIndex((t) => t.tourId === tourId);
      draft[index].startMinuteOfDay = startMinuteOfDay;
      draft[index].isModifiedInBrowser = true;
      draft[index].isAutorouted = false;
      return draft;
    });

    set({
      tours: _newTours,
    });
  },

  /*
   */

  setTourStartTime_old(tourId, startMinuteOfDay) {
    const _tourInfos = get().tourInfos_old;
    const _tourInfo = _tourInfos.find((t) => t.tourId === tourId);

    if (!_tourInfo) {
      toast.error("SetTourStartTime: Tour not found");
      return;
    }

    const _newTourInfos = produce(_tourInfos, (draft) => {
      const index = draft.findIndex((t) => t.tourId === tourId);
      draft[index].startMinuteOfDay = startMinuteOfDay;
      return draft;
    });

    set({
      tourInfos_old: _newTourInfos,
    });
  },
  createTourPause(tourId) {
    const _missions = get().missionsMap?.get(tourId) || [];
    const _tourInfo = get().tourInfos_old.find((t) => t.tourId === tourId);

    if (!_tourInfo) {
      toast.error("createTourPause: Tour not found");
      return;
    }

    if (_missions.length === 0) {
      toast.error("No missions found");
      return;
    }

    const _newMissions = produce(_missions, (draft) => {
      const index = draft.findIndex((t) => t.tourId === tourId);
      const _pause = {
        id: createId(),
        missionPublicationId: _missions[0].missionPublicationId,
        isActive: true,
        updatedAt: "",
        patientId: "pause",
        duration_min: 15,
        startMinuteOfDay: 0,
        endMinuteOfDay: 0,
        day: _tourInfo.day,
        tourId: tourId,
        memberId: _tourInfo.memberId,
        memberHasQualificationLevel: "",
        member: undefined,
        patient: {
          lastName: "pause",
          firstName: "pause",
          isPrivate: false,
          shortName: "Pause",
        },
        type: "pause",
        isModifiedInDatabase: false,
        isTouched: true,
      } as MissionPause;

      draft.splice(0, 0, _pause);
      return draft;
    });

    set({
      missionsMap: get().missionsMap?.set(tourId, _newMissions),
    });
    get().recalculateTour_old(tourId);
  },
  updateMisisonsFromDB: async () => {
    console.log("updateMisisonsFromDB");
    // if (get().isLoading) return;
    // if (get().isSaving) return;

    // console.log("updateMisisonsFromDB Processing");

    // const __lastFetchFromDbTS = get().lastFetchFromDbTS;

    // client
    //   .query(
    //     Missions_MissionEasyPlan,
    //     {
    //       filter: {
    //         minDate: get().day,
    //         maxDate: get().day,
    //         includeEarly: get().selectedShift.early,
    //         includeLate: get().selectedShift.late,
    //         updatedLater: get().lastFetchFromDbTS,
    //       },
    //     },
    //     {
    //       requestPolicy: "network-only",
    //     }
    //   )
    //   .toPromise()
    //   .then((result) => {
    //     console.log("Missions from BD, update    ", result.data);

    //     if (!result.data) {
    //       toast.error("Failed to load missions from DB");
    //       return;
    //     }

    //     const _missions = result.data.missionsTimeRange;

    //     if (!_missions) {
    //       toast.error("No missions found");
    //       return;
    //     }

    //     const effectedTourIds = _.uniqBy(_missions, "tourId").map(
    //       (m) => m.tourId || "nichtDa"
    //     );

    //     console.log("Effected TourIds", effectedTourIds);

    //     // appply changes to data

    //     client
    //       .query(
    //         Missions_MissionEasyPlan,
    //         {
    //           filter: {
    //             minDate: get().day,
    //             maxDate: get().day,
    //             tourIds: effectedTourIds,
    //             isActive: true,
    //             includeEarly: get().selectedShift.early,
    //             includeLate: get().selectedShift.late,
    //           },
    //         },
    //         {
    //           networkPolicy: "network-only",
    //         }
    //       )
    //       .toPromise()
    //       .then((result) => {
    //         // Iterarte over all effected toours and update missions

    //         const _allMissions = result?.data?.missionsTimeRange;

    //         if (!_allMissions) {
    //           toast.error("No missions found");
    //           return;
    //         }

    //         const _newTours = produce(get().tours, (draft) => {
    //           for (let tourId of effectedTourIds) {
    //             const _tourIndex = draft.findIndex((t) => t.tourId === tourId);

    //             console.log("Tour", _tourIndex, tourId, get().tours);
    //             if (_tourIndex === -1) {
    //               toast.error("Tour not found");
    //               continue;
    //             }
    //             const effectedMissions = _allMissions.filter(
    //               (m) => m.tourId === tourId
    //             );

    //             console.log("Effected Missions", effectedMissions);

    //             const _newMissions = produce(
    //               draft[_tourIndex].currentMissions,
    //               (draft) => {
    //                 const __missions = [];

    //                 for (let i = 0; i < draft.length; i++) {
    //                   const effectedMission = effectedMissions.find(
    //                     (m) => m.id === draft[i].id
    //                   );

    //                   if (!effectedMission) {
    //                     console.log("Mission not found", draft[i].id);
    //                     draft.slice(i, 1);
    //                     continue; // remove missions
    //                   }

    //                   if (effectedMission?.tourId === draft[i].tourId) {
    //                     __missions.push({
    //                       ...effectedMission,
    //                       isTouched: true,
    //                       isModifiedInDatabase: false,
    //                       isNewCreated: false,
    //                       type: "mission",
    //                     });
    //                   }

    //                   // has mission same tourId ?
    //                 }

    //                 draft = [...__missions];

    //                 return draft;
    //               }
    //             );

    //             draft[_tourIndex].currentMissions = _newMissions;
    //             draft[_tourIndex].isModifiedInBrowser = false;
    //             draft[_tourIndex].isAutorouted = false;
    //             //draft[_tourIndex].isModifiedInDatabase = true;
    //             draft[_tourIndex].memberId = _newMissions[0].memberId;
    //             draft[_tourIndex].member = _newMissions[0].member;
    //           }
    //         });

    //         set({
    //           tours: _newTours,
    //           lastFetchFromDbTS: __lastFetchFromDbTS,
    //         });
    //       });
    //   });

    // now apply changes
  },
  countChangedMissions({ tourId }: { tourId?: string }) {
    const _missionsMap = get().missionsMap;
    const _notActiveMissionsMap = get().notActiveMissionsMap;
    const _distinctTourIds = get().distinctTourIds;

    let count = 0;
    for (let _tourId of _distinctTourIds) {
      const _missions = _missionsMap?.get(_tourId) || [];
      const _notActiveMissions = _notActiveMissionsMap?.get(_tourId) || [];

      count += _missions.filter((m) => m.isTouched).length;
      count += _notActiveMissions.filter((m) => m.isTouched).length;
    }

    return count;
  },

  assignMemberIdToTour({ tourId, member, memberId }) {
    const _tourInfos = get().tourInfos_old;
    const _missions = get().missionsMap?.get(tourId) || [];
    const _notActiveMissions = get().notActiveMissionsMap?.get(tourId) || [];

    console.log("assignMemberIdToTour", tourId, member, memberId);
    console.log(_tourInfos);
    console.log(_missions);
    console.log(_notActiveMissions);

    if (!_missions || !_notActiveMissions) {
      toast.error(
        "assignMemberIdToTour: Missions not loaded " + tourId + " " + memberId
      );
      return;
    }

    const _newTourInfos = produce(_tourInfos, (draft) => {
      const index = draft.findIndex((t) => t.tourId === tourId);

      if (index === -1) {
        toast.error("assigenMemberIdTour: Tour not found");
        return draft;
      }

      draft[index].memberId = memberId;
      draft[index].member = member;

      return draft;
    });

    set({
      tourInfos_old: _newTourInfos,
    });

    const _newMissions = produce(_missions, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        draft[i].memberId = memberId;
        draft[i].member = member;
        draft[i].isTouched = true;
      }

      return draft;
    });

    const _newNotActiveMissions = produce(_notActiveMissions, (draft) => {
      for (let i = 0; i < draft.length; i++) {
        draft[i].memberId = memberId;
        draft[i].member = member;
        draft[i].isTouched = true;
      }
      return draft;
    });

    console.log(_newMissions);
    console.log(_newNotActiveMissions);

    set({
      missionsMap: get().missionsMap?.set(tourId, _newMissions),
      notActiveMissionsMap: get().notActiveMissionsMap?.set(
        tourId,
        _newNotActiveMissions
      ),
    });
    get().recalculateTour_old(tourId);
  },
  setShowTourInfoMessageDialog(show) {
    set({ showTourInfoMessageDialog: show });
  },
  setTourInfoMessageTourId(tourId) {
    set({ tourInfoMessageTourId: tourId });
  },
  prevDay() {
    set({
      //  missions: [],
      moveMissions: [],
      distinctTourIds: [],
      missionsMap: undefined,
      notActiveMissionsMap: undefined,
      tourInfos_old: [],
      touchedTourIds: [],
      touchedMissionIds: [],
    });
    const _day = get().day;
    const _newDay = dayjs(_day).subtract(1, "day").format("YYYY-MM-DD");
    set({ day: _newDay });
  },
  nextDay() {
    set({
      //    missions: [],
      distinctTourIds: [],
      moveMissions: [],
      missionsMap: undefined,
      notActiveMissionsMap: undefined,
      tourInfos_old: [],
      touchedTourIds: [],
      touchedMissionIds: [],
    });
    const _day = get().day;
    const _newDay = dayjs(_day).add(1, "day").format("YYYY-MM-DD");
    set({ day: _newDay });
  },
  setDay(day) {
    set({
      //   missions: [],
      moveMissions: [],
      distinctTourIds: [],
      missionsMap: undefined,
      notActiveMissionsMap: undefined,
      tourInfos_old: [],
      touchedTourIds: [],
      touchedMissionIds: [],
    });
    set({ day: day });
  },
  toogleShift() {
    // set({
    //   //missions: [],
    //   distinctTourIds: [],
    //   moveMissions: [],
    //   missionsMap: undefined,
    //   notActiveMissionsMap: undefined,
    //   tourInfos: [],
    //   touchedTourIds: [],
    //   touchedMissionIds: [],
    // });
    // const _shift = get().shift;
    // if (_shift.early === true) {
    //   set({ shift: { early: false, late: true } });
    // } else {
    //   set({ shift: { early: true, late: false } });
    // }
  },
  appendMissionTour({ tourId, patient, duration, actionsWQ }) {
    const _missionsMap = get().missionsMap;
    const _tourInfos = get().tourInfos_old;
    const _missions = _missionsMap?.get(tourId) || [];
    const _tourInfo = _tourInfos.find((t) => t.tourId === tourId);

    if (!_tourInfo) {
      toast.error("appendMissionTour: Tour not found");
      return;
    }

    if (!patient) {
      toast.error("Patient not found");
      return;
    }

    const _newMission = {
      id: createId(),
      missionPublicationId: _missions[0].missionPublicationId,
      hasConflictAbsence: false,
      isActive: true,
      updatedAt: "",
      patientId: patient.patientId,
      duration_min: duration,
      startMinuteOfDay: 0,
      endMinuteOfDay: 0,
      day: _tourInfo.day,
      tourId: tourId,
      memberId: _tourInfo.memberId,
      memberHasQualificationLevel: "",
      member: undefined,
      patient: patient,
      actionsWithQuantity: actionsWQ.map((a) => {
        return {
          actionLnr: a.action.lnr,
          quantity: a.quantity,
          action: a.action,
        };
      }),
      type: "mission",
      isModifiedInDatabase: false,
      isTouched: true,
      isNewCreated: true,
      executionMobiles: [],
      minReqQualification: 0,
    } as MissionPause;

    console.log(_newMission);

    const _newMissions = produce(_missions, (draft) => {
      draft.push(_newMission);
      return draft;
    });

    set({
      missionsMap: get().missionsMap?.set(tourId, _newMissions),
    });
    get().recalculateTour_old(tourId);
  },
  setMissionDurationAction({ missionId, duration }) {
    const _tours = get().tours;

    // if (!_tours) {
    //   toast.error("setMissionDurationAction: Missions not loaded " + missionId);
    //   return;
    // }

    // const _newTours = produce(_tours, (draft) => {
    //   for (let tour of _tours) {
    //     const _tourIndex = draft.findIndex((t) => t.tourId === tour.tourId);

    //     if (_tourIndex === -1) {
    //       continue;
    //     }

    //     const _mission = tour.currentMissions.find((m) => m.id === missionId);
    //     if (_mission) {
    //       const _newMissions = produce(tour.currentMissions, (draft) => {
    //         const index = draft.findIndex((m) => m.id === missionId);
    //         draft[index].duration_min = duration;
    //         return draft;
    //       });
    //       draft[_tourIndex].currentMissions = _newMissions;
    //       draft[_tourIndex].isModifiedInBrowser = true;
    //       draft[_tourIndex].isAutorouted = false;
    //     }
    //   }
    //   return draft;
    // });
    // set({ tours: _newTours });
  },

  setMissionDurationAction_old({ missionId, duration }) {
    const _missionsMap = get().missionsMap;
    const _distinctTourIds = get().distinctTourIds;

    for (let tourId of _distinctTourIds) {
      const _missions = _missionsMap?.get(tourId) || [];
      const _mission = _missions.find((m) => m.id === missionId);

      if (_mission) {
        const _newMissions = produce(_missions, (draft) => {
          const index = draft.findIndex((m) => m.id === missionId);
          draft[index].duration_min = duration;
          //draft[index].actionsWithQuantity = awqs;
          draft[index].isTouched = true;
          return draft;
        });

        _missionsMap?.set(tourId, _newMissions);

        set({
          missionsMap: _missionsMap,
        });
        get().recalculateTour_old(tourId);
      }
    }
  },
  splitMission({ missionId }) {
    const _lastFetchFromDbTS = get().lastFetchFromDbTS;

    const ret = useMission
      .getState()
      .splitMission({ missionId, updatedAt: _lastFetchFromDbTS });
    ret
      .then(() => {
        toast.success("Mission splitted");
        //get().updateMisisonsFromDB();
        set({ selectedMissionId: undefined });
        get().loadData_old();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  },
  setSelectedShift(shift) {
    set({
      //missions: [],
      distinctTourIds: [],
      moveMissions: [],
      missionsMap: undefined,
      notActiveMissionsMap: undefined,
      tourInfos_old: [],
      touchedTourIds: [],
      touchedMissionIds: [],
      selectedShift: shift,
    });
  },
  createNewTour({ tourId }) {
    const _tourInfos = get().tourInfos_old;
    const _distinctTourIds = get().distinctTourIds;

    if (!_tourInfos) {
      toast.error("Touren nicht gefunden");
      return;
    }

    if (_distinctTourIds.includes(tourId)) {
      toast.error("Tour bereits vorhanden");
      return;
    }

    const _newTourInfos = produce(_tourInfos, (draft) => {
      const _newTour = {
        tourId: tourId,
        day: get().day,
        startMinuteOfDay: 360,
        tourPauses: [],
        memberId: undefined,
        member: undefined,
        currentMissions: [],
        originalMissions: [],
        isAutorouted: false,
        isModifiedInBrowser: true,
        isModifiedInDatabase: false,
      } as TourInfo_MissionEasyPlan;
      draft.splice(0, 0, _newTour);
      return draft;
    });

    const _newDistinctTourIds = produce(_distinctTourIds, (draft) => {
      draft.push(tourId);
      return draft;
    });

    set({
      tourInfos_old: _newTourInfos,
      distinctTourIds: _newDistinctTourIds,
    });
  },
  setMonitorTourIds(tourIds) {
    set({ monitorTourIds: tourIds });
  },
  createSocketMonitor() {
    // const _monitorTourIds = get().monitorTourIds;
    // const _socket = get().socketMonitorTourIds;
    // console.log("Create Socket Monitor");
    // if (!_socket) {
    //   const _newSocket = io(process.env.REACT_APP_SOCKET_ENDPOINT || "", {
    //     auth: {
    //       token: inMemoryJwt.getToken(),
    //     },
    //   });
    //   if (_newSocket) {
    //     toast.success("Socket erzeugt");
    //     set({ socketMonitorTourIds: _newSocket });
    //   }
    // }
  },
  closeSocketMonitor() {
    const _monitorTourIds = get().monitorTourIds;
    const _socket = get().socketMonitorTourIds;

    console.log("Close Socket Monitor");

    if (_socket) {
      _socket.close();
      set({ socketMonitorTourIds: undefined });
    }
  },
  syncSupabase() {
    client
      .mutation(UpsertSupabaseME_ReviewExecutionMobile, {
        day: get().day,
      })
      .toPromise()
      .then((result) => {
        console.log(result);
        if (!result.data?.upsertSupabaseExecutionMobile) {
          toast.error("Failed to sync");
          return;
        }

        toast.success("Tag synchronisiert");
      });
  },
  removeWanderlistItem(id) {
    const _wanderList = get().wanderList;
    const _newWanderList = produce(_wanderList, (draft) => {
      const index = draft.findIndex((w) => w.id === id);
      draft.splice(index, 1);
      return draft;
    });

    set({ wanderList: _newWanderList });
  },
  deleteOneTourPause(id) {
    client
      .mutation(DeleteOneTourPause_MissionEasyPlan, {
        id: id,
      })
      .toPromise()
      .then((result) => {
        console.log(result);
        if (!result.data?.deleteOneTourPause) {
          toast.error("Failed to delete pause");
          return;
        }

        toast.success("Pause gelöscht");
        get().updateTourPausesFromDB();
      });
  },
  addOneTourPause: async ({ tourId, duration }) => {
    const _tour = get().tours.find((t) => t.tourId === tourId);
    if (!_tour) {
      toast.error("Tour not found");
      return;
    }

    const _missions = [] as MissionPause[];

    for (let mId of _tour.currentMissionIds) {
      const _mission = get().activeMissionMap?.get(mId);

      if (_mission) {
        _missions.push(_mission);
      }
    }

    if (_missions.length === 0) {
      toast.error("No missions found");
      return;
    }

    const _publicationId = _missions[0].missionPublicationId;

    const dayDate = dayjs(_tour.day);

    const hoursStart = Math.floor(_tour.startMinuteOfDay / 60);
    const minutesStart = _tour.startMinuteOfDay % 60;

    const dateTimeStart = dayDate
      .hour(hoursStart)
      .minute(minutesStart)
      .second(0)
      .millisecond(0);

    const ret = await client
      .mutation(UpsertOneTourPause_MissionEasyPlan, {
        input: {
          tourId: tourId,
          missionPublicationId: _publicationId,
          startDate: dateTimeStart.toISOString(),
          endDate: dayjs(dateTimeStart).add(duration, "minute").toISOString(),
          groupId: _tour.day + "_" + tourId,

          memberId: _tour.memberId || "",
          id: createId(),
        },
      })
      .toPromise();

    console.log(ret);
    get().updateTourPausesFromDB();
  },
  updateTourPausesFromDB: async () => {
    const ret = await client
      .query(TourPauses_MissionEasyPlan, {
        publicationDays: [
          {
            day: get().day,
            missionPublicationId: get().currentMissionPublicationId,
          },
        ],
        tourIds: get().distinctTourIds,
      })
      .toPromise();

    const _tourPausesDB = ret.data?.tourPauses || [];

    const ret1 = await client
      .query(Missions_MissionEasyPlan, {
        filter: {
          minDate: get().day,
          maxDate: get().day,
          tourIds: get().distinctTourIds,
          isActive: true,
          includeEarly: get().selectedShift.early,
          includeLate: get().selectedShift.late,
        },
      })
      .toPromise();

    const _missionsDB = ret1.data?.missionsTimeRange || [];

    for (let tourId of get().distinctTourIds) {
      const _tourPausesForTourDB = _tourPausesDB.filter(
        (t) => t.tourId === tourId
      );
      const _missionsForTourDB = _missionsDB.filter((m) => m.tourId === tourId);

      const _tour = get().tours.find((t) => t.tourId === tourId);

      if (!_tour) {
        toast.error("Tour not found");
        return;
      }

      const _missionIdsState = _tour.currentMissionIds;

      const _activeMissionsMap = get().activeMissionMap;

      if (!_activeMissionsMap) {
        toast.error("Active Missions not found");
        return;
      }

      const _activeMissions = Array.from(_activeMissionsMap.values());

      // now check all Ids

      const _newMissionIds = [] as string[];

      for (let id of _missionIdsState) {
        // check if mission is in DB
        const _mission = _missionsForTourDB.find((m) => m.id === id);
        if (!_mission) {
          // check if it is pause
          const _pause = _tourPausesForTourDB.find((p) => p.id === id);

          if (!_pause) {
            toast.error("Neue Pause gefunden");
            continue;
          } else {
            // hier pause prüfen
          }
        } else {
          // hier mission prüfen
          const _missionInDB = _mission;
          const _missionInState = _activeMissionsMap.get(id);

          if (!_missionInState) {
            toast.error("Mission in State not found");
            continue;
          }

          /**
           * Cases to check
           * - mission is not active in DB but active in State
           * - mission is active in DB but not active in State
           * - member is deiffernt and tourId is identical
           * - member is different and tourId is different
           * - member is same and tourId is different
           * - member is same and tourId is same
           *
           *
           *
           */

          if (
            _missionInDB.isActive === false &&
            _missionInState.isActive === true
          ) {
            // mission is not active in DB but active in State
            continue;
          }
          if (
            _missionInDB.memberId !== _missionInState.memberId &&
            _missionInDB.tourId === _missionInState.tourId
          ) {
            // member is deiffernt and tourId is identical
          }

          if (
            _missionInDB.memberId !== _missionInState.memberId &&
            _missionInDB.tourId !== _missionInState.tourId
          ) {
            // member is different and tourId is different
          }

          if (
            _missionInDB.memberId === _missionInState.memberId &&
            _missionInDB.tourId !== _missionInState.tourId
          ) {
            // member is same and tourId is different
          }
        }
      }
    }
  },
}));
