import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogTitle,
  DialogTrigger,
} from "../UiComponents/Dialog";
import { Button } from "../UiComponents/Button";
import { Input } from "../UiComponents/Input";
import { set } from "lodash";
import { useMission } from "@/src/services/Mission/useMission";
import { useMissionEasyPlan } from "../MissionEasyPlan/useMissionEasyPlan";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";

type Props = {};

const CreateNewTourEasyPlan = (props: Props) => {
  const [tourName, setTourName] = React.useState<string>("");
  const [startTime, setStartTime] = React.useState<string>("06:00");
  const createNewTour = useMissionEasyPlanNext((state) => state.createNewTour);

  useEffect(() => {
    setTourName("");
    setStartTime("");
  }, []);

  return (
    <Dialog>
      <DialogTrigger>
        <Button size={"compact-sm"}>Neue Tour</Button>
      </DialogTrigger>
      <DialogContent className="w-96 h-96">
        <DialogTitle>Neue Tour</DialogTitle>
        <div className="flex flex-col justify-start">
          <div className="flex flex-col gap-4">
            <Input
              value={tourName}
              onChange={(e) => setTourName(e.target.value)}
            />

            <Input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </div>
        </div>
        <DialogClose>
          <Button
            onClick={(e) => {
              createNewTour({ tourId: tourName, startTime: startTime });
              setStartTime("");
              setTourName("");
            }}
          >
            Okay
          </Button>
          <Button>Abbrechen</Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default CreateNewTourEasyPlan;
