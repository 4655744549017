import React, { useEffect } from "react";
import inMemoryJwt from "../../Auth0/inMemoryJwt";
import LoginButton from "../../Auth0/LoginButton";
import { useSearchParams } from "react-router-dom";
import { useAnalyzeOneDay } from "./useAnalyzeOneDay";
import dayjs from "dayjs";
import { Button } from "../../UiComponents/Button";
import { Link } from "react-router-dom";
import { DisplayMobileExecutionResultIcon } from "../ReviewOneDay/ReviewOneDayExecution";
import { Input } from "../../UiComponents/Input";
import TourStats from "./TourStats";

type Props = {};

export const AnalyzeOneDayExecution = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return (
    <>
      <AnalyzeOneDayExecution1 />
    </>
  );
};

const AnalyzeOneDayExecution1 = () => {
  const {
    selectedDay,
    selectedTourId,
    loadData,
    setSelectedDay,
    setSelectedTourId,
    allExecutions,
    getMissionById,
    currentTourResult,
    selectedMemberId,
  } = useAnalyzeOneDay();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log("serachParam use Effect");
    const tourId = searchParams.get("tourId") || "Wählen";
    const day = searchParams.get("day") || dayjs().format("YYYY-MM-DD");

    setSelectedDay(day);
    setSelectedTourId(tourId);

    loadData();
  }, []);

  useEffect(() => {
    const params: Record<string, string> = {};
    if (selectedTourId) params.tourId = selectedTourId;
    if (selectedDay) params.day = selectedDay;

    // Update the URL search params without a page refresh
    setSearchParams(params);
  }, [selectedDay, selectedTourId, setSearchParams]);

  return (
    <div className="flex flex-col overflow-hidden">
      {/* //Menue */}
      <div className="flex flex-row gap-2 sticky top-0 bg-white p-2">
        <Link to={"/reviewonedayexecution?" + searchParams.toString()}>
          Edit
        </Link>
        <Button
          onClick={() => {
            const _item = allExecutions.map((e) => e.patientId);
            const _row = _item.join("\t");
            navigator.clipboard.writeText(_row);
          }}
        >
          Clipboard
        </Button>
      </div>

      <div className="flex flex-row overflow-hidden">
        <div className="bg-red-100 overflow-hidden">
          <div className="flex flex-col">
            {allExecutions.map((execution, index) => {
              const _mission = getMissionById(execution.missionId);
              let previousExecution;
              if (index !== 0) {
                previousExecution = allExecutions[index - 1];
              }
              return (
                <div className="flex flex-col gap-2 bg-white w-[800px]">
                  {previousExecution && (
                    <div className="flex flex-row gap-2 bg-white p-2 items-center justify-between">
                      <div>Fahrt IST: {execution.timeToNextAsIs} </div>
                      <div>Fahrt GOOGLE: {execution.timeToNextGoolge}</div>
                      <div className="bg-gray-100">
                        Fahrt Differenz:{" "}
                        {execution.timeToNextAsIs - execution.timeToNextGoolge}
                      </div>
                      <div className="w-24 ">
                        <Input />
                      </div>
                    </div>
                  )}
                  <div
                    key={execution.executionId}
                    className="flex flex-row gap-2 bg-white p-2 items-center"
                  >
                    <div className="flex flex-col">
                      <div>{_mission?.patientId}</div>
                      <div>{_mission?.patient?.lastName}</div>
                      <div>{_mission?.patient?.firstName}</div>
                    </div>
                    <div className="text-xs">{_mission?.time}</div>
                    <div className="bg-gray-100 p-2 flex flex-row gap-2 items-center">
                      <DisplayMobileExecutionResultIcon {...execution} />
                      <div>{dayjs(execution.startTS_iso).format("HH:mm")}</div>
                      <div>{dayjs(execution.endTS_iso).format("HH:mm")}</div>
                      <div>{execution.remark}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <TourStats
            tourId={selectedTourId}
            day={selectedDay}
            memberId={selectedMemberId}
            currentTourResult={currentTourResult}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyzeOneDayExecution;
