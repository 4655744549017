import React from "react";
import { MyCalendar } from "../UiComponents/MyCalendar";
import { useMissionPublication } from "./useMissionPublication";

import { DayContent, DayContentProps } from "react-day-picker";
import MissionPublicationMonthPicker from "./MissionPublicationMonthPicker";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import LoginButton from "../Auth0/LoginButton";
import { Button } from "../UiComponents/Button";
import dayjs from "dayjs";

const MyDayContent = (props: DayContentProps) => {
  console.log(props);
  return (
    <div className="flex flex-col justify-center items-center bg-green-100 h-full w-full">
      <div className="text-xl">{props.date.getDay()}</div>
    </div>
  );
};

export const MissionPublicationStatus = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Token holen
        <LoginButton />
      </div>
    );
  }

  return <MissionPublicationStatus1 {...props} />;
};

type Props = {
  updateParentFunction?: () => void;
};

const MissionPublicationStatus1 = (props: Props) => {
  const {
    missionPublications,
    groupedMissionPublications,
    setMissionPublicationCancel,
    setMissionPublicationReleased,
    setMissionPublicationRework,
    setCurrentPeriod,
    maxDay,
    minDay,
  } = useMissionPublication();

  console.log("MissionPublicationStatus", missionPublications);
  console.log("MissionPublicationStatus", groupedMissionPublications);

  return (
    <>
      <div>Nicht mehr da</div>
      {/* <div className="flex flex-col justify-center items-center bg-white">
        <MissionPublicationMonthPicker />
        <div className="gap-2  flex flex-col justify-center items-center">
          {missionPublications.map((missionPublication) => {
            console.log(
              "MissionPublicationStatus",
              missionPublication?.createdAt
            );

            return (
              <div className="flex flex-row justify-center items-center gap-2">
                <div>
                  {dayjs(Number(missionPublication?.createdAt)).format(
                    "YYYY-MM-DDTHH-mm-ss"
                  )}
                </div>
                <div className="">{missionPublication?.day}</div>
                <div className="font-bold text-xl">
                  {missionPublication?.releaseStatusName}
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setMissionPublicationCancel(missionPublication?.id || "");
                      if (props.updateParentFunction)
                        props.updateParentFunction();
                    }}
                  >
                    Stornieren
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setMissionPublicationRework(missionPublication?.id || "");
                      if (props.updateParentFunction)
                        props.updateParentFunction();
                    }}
                  >
                    Bearbeiten
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      setMissionPublicationReleased(
                        missionPublication?.id || ""
                      );
                      if (props.updateParentFunction)
                        props.updateParentFunction();
                    }}
                  >
                    Frei zur Abrechnung
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
    </>
  );
};

export default MissionPublicationStatus;
