import { create } from "zustand";
import { graphql } from "../../../gql";
import { client } from "../../../urqlClient";

import { ArrElement } from "../../../helpers/typeHelpers";
import { InCarePatients_OverviewPatientsQuery } from "../../../gql/graphql";
import {
  getEndDateOfWeek,
  getStartDateOfWeek,
} from "../../../helpers/datetime";
import dayjs from "dayjs";
import _ from "lodash";

const InCarePatients_OverviewPatients = graphql(/* GraphQL */ `
  query InCarePatients_OverviewPatients($minDay: String!, $maxDay: String!) {
    inCarePatients(minDay: $minDay, maxDay: $maxDay) {
      patientId
      shortName
      lastName
      firstName
      address
      zipCode
      city
      latitude
      longitude
      lat_long
      hasSchedule
      hasGenderDeny
      hasOpenInfoMessage
      hasGeoData
      isDa
      isActive
      hasActiveBetreuungszeitraum
      absenceEndDate
    }
  }
`);

export type LocalInCarePatients_OverviewPatients = ArrElement<
  InCarePatients_OverviewPatientsQuery["inCarePatients"]
>;

type OverviewPatientsState = {
  patients: LocalInCarePatients_OverviewPatients[];
  loadData: () => void;
};

export const useOverviewPatients = create<OverviewPatientsState>(
  (set, get) => ({
    patients: [],
    loadData: async () => {
      const _minDay = dayjs(
        getStartDateOfWeek(dayjs().format("YYYY-MM-DD"))
      ).format("YYYY-MM-DD");
      const _maxDay = dayjs(
        getEndDateOfWeek(dayjs().format("YYYY-MM-DD"))
      ).format("YYYY-MM-DD");

      const ret = await client
        .query(
          InCarePatients_OverviewPatients,
          {
            minDay: _minDay,
            maxDay: _maxDay,
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise();

      set({
        patients: _.orderBy(
          ret?.data?.inCarePatients || [],
          ["lastName", "firstName"],
          ["asc", "asc"]
        ),
      });
    },
  })
);
