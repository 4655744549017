import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import _, { set } from "lodash";

import { useMissionSetIsActiveMutation } from "../../generated/graphql";
import { useActionClipboard } from "../ActionClipboard/useActionClipboard";
import { useMission } from "../../services/Mission/useMission";
import ActionClipboard from "../ActionClipboard/ActionClipboard";
import MyErrorDialog from "../UiComponents/MyErrorDialog";
import DisplayMemberShortName from "../Member/DisplayMemberShortName";

import {
  LocalActionWithQuantity_PatientMissionList,
  usePatientMissionList,
} from "./usePatientMissionList";
import { useManageSchedules } from "../ManageSchedules/useManageSchedules";
import { Button } from "../UiComponents/Button";
import {
  CheckIcon,
  CopyIcon,
  InfoCircledIcon,
  MinusIcon,
} from "@radix-ui/react-icons";
import { PopoverRadix } from "../UiComponents/PopoverRadix";
import { PopoverRadixTrigger } from "../UiComponents/PopoverRadix";
import { PopoverRadixContent } from "../UiComponents/PopoverRadix";
import DisplayAction from "../Action/DisplayAction";

import {
  LocalTourListMissionAction,
  useTourList,
} from "../TourList/useTourList";
import { HiOutlineWrenchScrewdriver } from "react-icons/hi2";
import DisplayMissionAnalytic from "../DisplayMissionAnalytic/DisplayMissionAnalytic";

import { useGlobalService } from "../GlobalService/useGlobalService";
import GlobalWeekSelect from "../GlobalService/GlobalWeekSelect";

import { getStartDateOfWeek, getEndDateOfWeek } from "../../helpers/datetime";
import { TbClock2 } from "react-icons/tb";
import { GripHorizontal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../UiComponents/Dropdown";
import { cn } from "../../helpers/utils";

type MissionSetActionDurationProps = {
  patientId: string;
  actionsWithQuantity:
    | { actionLnr: string; quantity: number }[]
    | null
    | undefined;

  duration_min: number;
  day: string;
};

const MissionSetActionDuration = (props: MissionSetActionDurationProps) => {
  const { setPatientActionDuration } = useTourList();
  const { updateMissions } = usePatientMissionList();

  const { patientId, actionsWithQuantity, duration_min, day } = props;

  const weekStartDate = dayjs(getStartDateOfWeek(day));
  const [fixedDuration, setFixedDuration] = useState<number>(duration_min);

  const allowSetFixDuration = actionsWithQuantity?.length !== 0 ? true : false;

  return (
    <div className="flex flex-col items-center justify-between bg-white">
      <div className="flex flex-row gap-2">
        <Button
          onClick={() => {
            let _newDuration = fixedDuration - 5;
            if (_newDuration < 0) _newDuration = 0;
            setFixedDuration(_newDuration);
          }}
        >
          -
        </Button>
        {fixedDuration} Minuten
        <Button
          onClick={() => {
            let _newDuration = fixedDuration + 5;
            setFixedDuration(_newDuration);
          }}
        >
          +
        </Button>
      </div>
      <div className="flex flex-row">
        <Button
          variant="neutral-outline"
          disabled={!allowSetFixDuration}
          // setPatientActionDuration(patientId, actions, duration_min) {
          onClick={() => {
            setPatientActionDuration({
              patientId: patientId,
              actions: actionsWithQuantity,
              duration_min: fixedDuration,
              startDay: day,
              endDay: day,
            });
            updateMissions();
          }}
        >
          Tag
        </Button>
        <Button
          variant="neutral-outline"
          disabled={!allowSetFixDuration}
          // setPatientActionDuration(patientId, actions, duration_min) {
          onClick={() => {
            setPatientActionDuration({
              patientId: patientId,
              actions: actionsWithQuantity,
              duration_min: fixedDuration,
              startDay: weekStartDate.format("YYYY-MM-DD"),
              endDay: weekStartDate.add(1, "week").format("YYYY-MM-DD"),
            });
            updateMissions();
          }}
        >
          Woche
        </Button>
        <Button
          variant="neutral-outline"
          disabled={!allowSetFixDuration}
          // setPatientActionDuration(patientId, actions, duration_min) {
          onClick={() => {
            setPatientActionDuration({
              patientId: patientId,
              actions: actionsWithQuantity,
              duration_min: fixedDuration,
              startDay: weekStartDate.format("YYYY-MM-DD"),
              endDay: weekStartDate.add(2, "week").format("YYYY-MM-DD"),
            });
            updateMissions();
          }}
        >
          2 Wo
        </Button>
        <Button
          variant="neutral-outline"
          disabled={!allowSetFixDuration}
          // setPatientActionDuration(patientId, actions, duration_min) {
          onClick={() => {
            setPatientActionDuration({
              patientId: patientId,
              actions: actionsWithQuantity,
              duration_min: fixedDuration,
              startDay: weekStartDate.format("YYYY-MM-DD"),
              endDay: weekStartDate.add(4, "week").format("YYYY-MM-DD"),
            });
            updateMissions();
          }}
        >
          4 Wo
        </Button>
        <Button
          variant="neutral-outline"
          disabled={!allowSetFixDuration}
          // setPatientActionDuration(patientId, actions, duration_min) {
          onClick={() => {
            setPatientActionDuration({
              patientId: patientId,
              actions: actionsWithQuantity,
              duration_min: fixedDuration,
              startDay: weekStartDate.format("YYYY-MM-DD"),
              endDay: weekStartDate.add(9999, "week").format("YYYY-MM-DD"),
            });
            updateMissions();
          }}
        >
          Immer
        </Button>
      </div>
    </div>
  );
};

interface QualificationWarningProps {
  minRequiredQualification: number;
  hasRequiredQualification: string;
  isPrivate: boolean;
}

export const qualificationWarning = (props: QualificationWarningProps) => {
  if (props.isPrivate) return false;
  if (Number(props.hasRequiredQualification) < props.minRequiredQualification) {
    return true;
  } else {
    return false;
  }
};

interface TimeDeviationProps {
  asIsTime: number;
  calculatedTime: number | null | undefined;
}

const TimeDeviation = (props: TimeDeviationProps) => {
  const { asIsTime, calculatedTime } = props;

  if (calculatedTime === null || calculatedTime === undefined)
    return <div>E</div>;

  const diff = asIsTime - calculatedTime;

  if (Math.abs(diff) <= 1) return null;
  return (
    <div
      className={classNames({
        "text-green-600": diff < 0,
        "text-red-600": diff > 0,
      })}
    >
      {diff}
    </div>
  );
};

interface TimeProps {
  time: string;
}

export const ColorizedTime = (props: TimeProps) => {
  const { time } = props;
  type Shift = "F" | "S" | "M";

  let shift: Shift = "F";

  if (time < "11:59") shift = "F";
  else if (time < "14:59")
    shift = "M"; // 12:00 - 18:00
  else shift = "S";

  return (
    <div
      className={classNames({
        "text-yellow-600": shift === "F",
        "text-pink-600": shift === "M",
        "text-sky-600": shift === "S",
      })}
    >
      {time}
    </div>
  );
};

export const ColorizedBackground = (props: TimeProps) => {
  const { time } = props;
  type Shift = "F" | "S" | "M";

  let shift: Shift = "F";

  if (time < "11:59") shift = "F";
  else if (time < "14:59")
    shift = "M"; // 12:00 - 18:00
  else shift = "S";

  return classNames({
    "bg-yellow-600": shift === "F",
    "bg-pink-600": shift === "M",
    "bg-sky-600": shift === "S",
  });
};

type Props = {
  patientId: string;
};

const PatientMissionList = (props: Props) => {
  const patientIds = [];

  const { currentMinDay, currentMaxDay, prevWeek, nextWeek } =
    useGlobalService();
  //const lastUpdated = useMission((state) => state.lastUpdated);

  const actionClipboardContent = useActionClipboard(
    (state) => state.actionsClipboardContent
  );

  const setActionClipboardContent = useActionClipboard(
    (state) => state.setActionClipboardContent
  );

  const missionSetAction = useMission((state) => state.missionSetAction);
  const { setPatientActionDuration } = useTourList();

  const {
    filteredMissions,
    loadMissions,
    updateMissions,
    updateMission,
    clearAll,
    isLoading,
  } = usePatientMissionList();

  const lastUpdated = useManageSchedules((state) => state.lastUpdated);

  patientIds.push(props.patientId);
  // const _minDate = dayjs().subtract(14, "days").format("YYYY-MM-DD");
  // const _maxDate = dayjs().add(7, "days").format("YYYY-MM-DD");

  const _minDate = currentMinDay;
  const _maxDate = currentMaxDay;

  const _currentDay = dayjs().format("YYYY-MM-DD");

  const [missionSetIsActiveResult, missionSetIsActive] =
    useMissionSetIsActiveMutation();

  const [errorOpen, setErrorOpen] = useState(false);

  useEffect(() => {
    clearAll();
    loadMissions(props.patientId, _minDate, _maxDate);
  }, [lastUpdated, _maxDate, _minDate, props.patientId]);

  // console.log("filteredMission", filteredMissions);

  return (
    <div className="">
      <div>
        <GlobalWeekSelect />
      </div>
      <div className="flex w-full">
        {/* PatientMissionList {dayjs(lastUpdated).format("HH:mm:ss")}
        {JSON.stringify(fetching)} {actionClipboardContent.length} */}
        {/* {fetching ? <progress className="progress w-12"></progress> : null}{" "} */}
      </div>
      <div className="grid grid-cols-[2fr,2fr,1fr,2fr,2fr] gap-1 items-center ">
        <div className="">Aktionen</div>
        <div className="">Tag</div>
        <div>
          <TbClock2 />
        </div>

        <div>Zeit</div>
        <div>MA</div>
        {/* <div>Tour</div> */}
        {filteredMissions?.map((mission) => {
          const weekStartDate = dayjs(getStartDateOfWeek(mission.day));

          const weekDayOddEven =
            dayjs(mission.day).isoWeekday() % 2 === 0 ? "even" : "odd";

          return (
            <>
              <div className={cn("flex flex-row items-center gap-2", {})}>
                {/* Actions buchen    */}
                <Button
                  variant="neutral-ghost"
                  size="compact-sm"
                  // className="btn btn-square btn-xs btn-ghost "
                  disabled={true}
                  onClick={() => {
                    console.log("buchen");
                    missionSetAction(
                      mission?.id!,
                      actionClipboardContent,
                      mission?.updatedAt!
                    )
                      .then((res) => {
                        updateMission(mission?.id);
                      })
                      .catch((err) => {
                        console.log("Error", err);
                        setErrorOpen(true);
                      });
                  }}
                >
                  <HiOutlineWrenchScrewdriver
                    className={classNames("w-4 h-4", {
                      "text-yellow-400": !mission?.hasAction,
                      "text-success": mission?.hasAction,
                    })}
                  />
                </Button>

                <div className="flex items-center">
                  <PopoverRadix>
                    <PopoverRadixTrigger>
                      <div>
                        <InfoCircledIcon className="h-4 w-4" />
                      </div>
                    </PopoverRadixTrigger>
                    <PopoverRadixContent>
                      <div className="w-96 m-2 p-2">
                        <DisplayMissionAnalytic missionId={mission.id}>
                          <MissionSetActionDuration
                            actionsWithQuantity={mission.actionsWithQuantity?.map(
                              (awq) => {
                                return {
                                  actionLnr: awq?.actionLnr || "",
                                  quantity: awq?.quantity || 0,
                                };
                              }
                            )}
                            duration_min={mission.duration_min}
                            day={mission.day}
                            patientId={mission.patientId}
                          />
                        </DisplayMissionAnalytic>
                      </div>
                    </PopoverRadixContent>
                  </PopoverRadix>
                </div>

                {mission?.isActive === true ? (
                  <Button
                    variant={"neutral-ghost"}
                    size="compact-sm"
                    disabled={true}
                    onClick={() => {
                      missionSetIsActive({
                        id: mission!.id,
                        isActive: false,
                        updatedAt: mission!.updatedAt,
                      })
                        .then((res) => {
                          updateMission(mission!.id);
                        })
                        .catch((err) => {
                          console.log("Error", err);
                          setErrorOpen(true);
                        });
                    }}
                  >
                    <CheckIcon className="w-4 h-4 text-success" />
                  </Button>
                ) : (
                  <Button
                    variant={"neutral-ghost"}
                    size="compact-sm"
                    disabled={true}
                    onClick={() => {
                      missionSetIsActive({
                        id: mission!.id,
                        isActive: true,
                        updatedAt: mission!.updatedAt,
                      }).then((res) => {
                        updateMission(mission!.id);
                      });
                    }}
                  >
                    <MinusIcon className="w-4 h-4 text-red-500" />
                  </Button>
                )}
                <Button
                  variant={"neutral-ghost"}
                  size="compact-sm"
                  onClick={() => {
                    setActionClipboardContent(mission?.actionsWithQuantity!);
                  }}
                >
                  <CopyIcon className="w-4 h-4" />
                </Button>

                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant={"neutral-ghost"} size={"compact-sm"}>
                      <GripHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuLabel>Aktionen Einsatz</DropdownMenuLabel>
                    <DropdownMenuGroup>
                      <DropdownMenuItem>
                        <span>Nach vergeblicher Anfahrt ausgeführt</span>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>

              <div
                className={classNames({
                  "font-bold": mission?.day === _currentDay,
                  "border-l-4 border-l-purple-500": qualificationWarning({
                    hasRequiredQualification:
                      mission?.member?.hasQualificationLevel!,
                    minRequiredQualification: mission?.minReqQualification,
                    isPrivate: mission?.patient?.isPrivate || false,
                  }),
                })}
              >
                {dayjs(Number(mission?.startTS)).format("dd, DD")}
              </div>
              <div className="text-xs">
                {mission?.missionAnalytic?.durationCorrection_min || 0}
              </div>

              <div className="flex flex-row items-center">
                <ColorizedTime time={mission.time!} />
                <div className="flex flex-row text-xs text-gray-500">
                  ({mission.duration_min}|
                  <TimeDeviation
                    asIsTime={mission.duration_min}
                    calculatedTime={
                      Math.round(
                        ((mission.missionAnalytic?.expectedDuration_NOSAFE ||
                          1) -
                          1) /
                          5
                      ) * 5
                    }
                  />
                  )
                </div>
              </div>
              <div>
                <DisplayMemberShortName
                  hasExamination={mission?.member?.hasExamination!}
                  shortName={mission?.member?.shortName!}
                  hasQualificationLevel={
                    mission?.member?.hasQualificationLevel!
                  }
                />
              </div>
              {/* <div>{mission?.tourId}</div> */}
            </>
          );
        })}
      </div>
      <MyErrorDialog
        open={errorOpen}
        setOpen={setErrorOpen}
        handleClose={updateMissions}
      />
    </div>
  );
};

export default PatientMissionList;
