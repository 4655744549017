import { ArrElement } from "@/src/helpers/typeHelpers";
import { graphql } from "../../gql";
import { create } from "zustand";
import { TasMissions_PatientTasMissionListQuery } from "@/src/gql/graphql";
import { client } from "../../urqlClient";
import _ from "lodash";

const TasMissions_PatientTASMissionList = graphql(/* GraphQL */ `
  query TasMissions_PatientTASMissionList(
    $maxDay: String!
    $minDay: String!
    $patientId: String!
  ) {
    tasMissions(maxDay: $maxDay, minDay: $minDay, patientId: $patientId) {
      missionId
      bookedMission {
        id
        startTS
        day
        time
        updatedAt
      }
      memberId
      member {
        shortName
      }
      patient {
        shortName
        lastName
      }
      startTimeMinuteOfDay
      duration_min
      day
      tourId
      startTS
      updatedAt
    }
  }
`);

type Local_TASMissions_PatientTASMissionList = ArrElement<
  TasMissions_PatientTasMissionListQuery["tasMissions"]
>;

export type PatientTASMissionListState = {
  patientTASMissionList: string[];
  minDay: string;
  maxDay: string;
  selectedPatientId: string;
  tasMissions: Local_TASMissions_PatientTASMissionList[];
  setSelectedPatientId: (selectedPatientId: string) => void;
  setPatientTASMissionList: (patientTASMissionList: string[]) => void;
  setTimeRange: (minDay: string, maxDay: string) => void;
  loadData: () => void;
};

export const usePatientTASMissionList = create<PatientTASMissionListState>(
  (set, get) => ({
    patientTASMissionList: [],
    minDay: "",
    maxDay: "",
    selectedPatientId: "",
    tasMissions: [],
    setSelectedPatientId: (selectedPatientId) =>
      set({ selectedPatientId, tasMissions: [] }),
    setTimeRange: (minDay, maxDay) => set({ minDay, maxDay, tasMissions: [] }),
    setPatientTASMissionList: (patientTASMissionList) =>
      set({ patientTASMissionList }),
    loadData: async () => {
      const { minDay, maxDay, selectedPatientId } = get();
      const { error, data } = await client
        .query(
          TasMissions_PatientTASMissionList,
          {
            minDay,
            maxDay,
            patientId: selectedPatientId,
          },
          {
            requestPolicy: "network-only",
          }
        )
        .toPromise();

      console.log(data);
      console.log(error);

      if (!data?.tasMissions) return;

      set({ tasMissions: _.orderBy(data.tasMissions, ["startTS"], ["asc"]) });
    },
  })
);
