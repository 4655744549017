import { create } from "zustand";
import {
  Missions_ReviewExecutionMobile,
  CreateOrUpdateExecutionMobile_ReviewExecutionMobile,
  DeleteExecutionMobile_ReviewExecutionMobile,
  SetValidationOneExecutionMobile_ReviewExecutionMobile,
  compareTuples,
  LocalActionWithQuantity_ReviewExecutionMobile,
  MissionActionCompareResult,
  UpsertSupabaseME_ReviewExecutionMobile,
} from "../useReviewExecutionMobile";
import { LocalMission_ReviewExecutionMobile } from "../useReviewExecutionMobile";
import dayjs from "dayjs";
import { client } from "../../../urqlClient";
import _, { sum } from "lodash";
import {
  InputCreateExecutionMobile,
  TourStatMissions_ReviewExecutionMobileQuery,
} from "../../../gql/graphql";
import toast from "react-hot-toast";
import { graphql } from "../../../gql";
import { ArrElement } from "@/src/helpers/typeHelpers";

const CreateExecutionMobileInfoMessage_ReviewExecutionMobile = graphql(
  /* GraphQL */ `
    mutation CreateExecutionMobileInfoMessage_ReviewExecutionMobile(
      $input: InputCreateExecutionMobileInfoMessage!
    ) {
      createExecutionMobileInfoMessage(input: $input) {
        id
      }
    }
  `
);

export const TourStatMissions_ReviewExecutionMobile = graphql(/* GraphQL */ `
  query TourStatMissions_ReviewExecutionMobile(
    $minDate: String!
    $maxDate: String!
    $tourIds: [String!]!
  ) {
    tourStatMissions(minDate: $minDate, maxDate: $maxDate, tourIds: $tourIds) {
      missionId
      day
      tourId
      hasSuccessExecution
      durationPlanned_min
      durationExecuted_min
      diffDuration_min
      member {
        shortName
      }
    }
  }
`);

export type LocalTourStatMission_ReviewExecutionMobile = ArrElement<
  TourStatMissions_ReviewExecutionMobileQuery["tourStatMissions"]
>;

type UserInputEditOneDay = {
  inputStartTime?: string;
  inputEndTime?: string;
};

export type ActionExecution = {
  actionLnr: string;
  executionStatus: "executed" | "notExecuted" | "additionalExecuted";
  missionId: string;
  executionId: string;
  action: LocalActionWithQuantity_ReviewExecutionMobile;
};

type TourResultMember = {
  memberId: string;
  tourId: string;
  day: string;
  minStartTime: string;
  maxEndTime: string;
  calculatedPaidDuration: number;
  acceptedPaidDuration: number;
  sumWorktime: number;
  sumWaitingTime: number;
  ratioWorkPaid: number;
  travelTime: number;
  ratioTravelPaid: number;
};

interface ReviewOneDayState {
  selectedDay: string;
  selectedTourId: string;
  selectedMemberId: string;
  currentTourResult: TourResultMember | undefined;
  setSelectedDay: (day: string) => void;
  setSelectedTourId: (tourId: string) => void;
  setSelectedMemberId: (memberId: string) => void;

  allMissions: (LocalMission_ReviewExecutionMobile & UserInputEditOneDay)[];
  allTourStatMissions: LocalTourStatMission_ReviewExecutionMobile[];
  lastUpdated: string;
  missonExecutionResultMap: Map<string, ActionExecution[]> | undefined;
  missionActionCompareResultsMap:
    | Map<string, MissionActionCompareResult>
    | undefined;
  actionsMap:
    | Map<string, LocalActionWithQuantity_ReviewExecutionMobile>
    | undefined;
  loadData: (missionId?: string) => void;
  calculateTourResult: () => void;
  syncSupabase: (day: string) => void;
  createOrUpdateExecutionMobile: (input: InputCreateExecutionMobile) => void;
  createExecutionMobileInfoMessage: (input: {
    message: string;
    executionMobileId: string;
    actionRequired: boolean;
  }) => void;

  deleteExecutionMobile: ({
    id,
    missionId,
  }: {
    id: string;
    missionId: string;
  }) => void;
  setValidityOneExecutionMobile: ({
    id,
    valid,
    missionId,
  }: {
    id: string;
    valid: boolean;
    missionId: string;
  }) => void;
  updatingMissionIds: string[];
}
export const useReviewOneDay = create<ReviewOneDayState>((set, get) => ({
  selectedDay: "",
  selectedTourId: "",
  selectedMemberId: "",
  allMissions: [],
  currentTourResult: undefined,
  allTourStatMissions: [],
  updatingMissionIds: [],
  missionActionCompareResultsMap: undefined,
  missonExecutionResultMap: undefined,
  actionsMap: undefined,
  setSelectedDay: (day) => {
    set({
      selectedDay: day,
      allMissions: [],
      missonExecutionResultMap: undefined,
    });
    //get().loadData();
  },
  setSelectedTourId: (tourId) => {
    set({
      selectedTourId: tourId,
      allMissions: [],
      missonExecutionResultMap: undefined,
    });
    //get().loadData();
  },
  lastUpdated: (dayjs("2021-01-01").unix() * 1000).toString(),
  loadData: async (missionId?: string) => {
    console.log(
      "loadData Last Update",
      dayjs(Number(get().lastUpdated)).toISOString()
    );
    const res = await client
      .query(
        Missions_ReviewExecutionMobile,
        {
          filter: {
            maxDate: get().selectedDay,
            minDate: get().selectedDay,
            tourIds: [get().selectedTourId],
            updatedLater: get().lastUpdated,
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();
    console.log(res);

    const resTourStatMissions = await client
      .query(
        TourStatMissions_ReviewExecutionMobile,
        {
          minDate: get().selectedDay,
          maxDate: get().selectedDay,
          tourIds: [get().selectedTourId],
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();

    console.log(resTourStatMissions);

    if (resTourStatMissions.data?.tourStatMissions) {
      set({
        allTourStatMissions: resTourStatMissions.data.tourStatMissions,
      });
    }

    if (missionId) {
      set({
        updatingMissionIds: get().updatingMissionIds.filter(
          (id) => id !== missionId
        ),
      });
    }

    set({
      allMissions: _.orderBy(
        res?.data?.missionsTimeRange || [],
        ["startTS"],
        ["asc"]
      ),
      //lastUpdated: (dayjs().unix() * 1000).toString(),
    });

    const _actionsMap = new Map<
      string,
      LocalActionWithQuantity_ReviewExecutionMobile
    >();

    const missions = get().allMissions;

    const _districtActions = _.chain(missions)
      .map((m) => m.actionsWithQuantity)
      .flatten()
      .uniqBy("actionLnr")
      .value();

    for (let action of _districtActions) {
      if (!action) continue;
      _actionsMap.set(action.actionLnr, action);
    }

    set({ actionsMap: _actionsMap });

    const _missionCompareResultsMap = new Map<
      string,
      MissionActionCompareResult
    >();

    const _missionExecutionsMap = new Map<string, ActionExecution[]>();

    for (let mission of missions) {
      if (!mission.executionMobiles) continue;
      if (!mission.actionsWithQuantity) continue;

      for (let execution of mission.executionMobiles) {
        if (!execution.actionsWithQuantity) continue;
        if (!mission.actionsWithQuantity) continue;
        if (execution.result !== "success") continue;

        const _actionExecutions: ActionExecution[] = [];

        const missionActions = mission.actionsWithQuantity;
        const executionActions = execution.actionsWithQuantity;

        for (let a of missionActions) {
          if (!a) continue;
          // check if action is in execution
          const _action = executionActions.find(
            (e) => e.actionLnr === a?.actionLnr
          );

          if (_action) {
            _actionExecutions.push({
              actionLnr: a.actionLnr,
              executionStatus: "executed",
              missionId: mission.id,
              executionId: execution.id,
              action: a,
            });
          } else {
            _actionExecutions.push({
              actionLnr: a.actionLnr,
              executionStatus: "notExecuted",
              missionId: mission.id,
              executionId: execution.id,
              action: a,
            });
          }
        }
        _missionExecutionsMap.set(execution.id, _actionExecutions);
      }
    }

    console.log("missionExecutionsMap", _missionExecutionsMap);
    set({ missionActionCompareResultsMap: _missionCompareResultsMap });
    set({ missonExecutionResultMap: _missionExecutionsMap });
    get().calculateTourResult();
  },
  createOrUpdateExecutionMobile: async (input: InputCreateExecutionMobile) => {
    console.log("createOrUpdateExecutionMobile", input);
    const missionId = input.referenceId;

    set({
      updatingMissionIds: [...get().updatingMissionIds, missionId],
    });

    client
      .mutation(CreateOrUpdateExecutionMobile_ReviewExecutionMobile, {
        data: input,
      })
      .toPromise()
      .then(() => {
        get().loadData(missionId);
      })
      .catch((e) => {
        toast.error("Error: " + e.message);
        get().loadData(missionId);
      });
  },

  deleteExecutionMobile: async ({ id, missionId }) => {
    console.log("deleteExecutionMobile", id);

    set({
      updatingMissionIds: [...get().updatingMissionIds, missionId],
    });

    // find the mission whre execution belongs to

    client
      .mutation(DeleteExecutionMobile_ReviewExecutionMobile, {
        id: id,
      })
      .toPromise()
      .then(() => {
        get().loadData(missionId);
      })
      .catch((e) => {
        toast.error("Error: " + e.message);
        get().loadData(missionId);
      });
  },
  setValidityOneExecutionMobile: async ({ id, valid, missionId }) => {
    console.log("setValidOneExecutionMobile", id, valid);

    set({
      updatingMissionIds: [...get().updatingMissionIds, missionId],
    });

    client
      .mutation(SetValidationOneExecutionMobile_ReviewExecutionMobile, {
        id: id,
        isInvalid: valid,
      })
      .toPromise()
      .then(() => {
        get().loadData(missionId);
      })
      .catch((e) => {
        toast.error("Error: " + e.message);
        get().loadData(missionId);
      });
  },
  syncSupabase: async (day) => {
    client
      .mutation(UpsertSupabaseME_ReviewExecutionMobile, {
        day: day,
      })
      .toPromise()
      .then((ret) => {
        get().loadData();
        toast.success("Mobile Daten aktualisiert " + day);
      });
  },
  createExecutionMobileInfoMessage: async (input) => {
    client
      .mutation(CreateExecutionMobileInfoMessage_ReviewExecutionMobile, {
        input: {
          executionMobileId: input.executionMobileId,
          message: input.message,
          actionRequired: input.actionRequired,
        },
      })
      .toPromise()
      .then((ret) => {
        toast.success("Nachricht erstellt");
      });
  },
  setSelectedMemberId: (memberId) => {
    set({
      selectedMemberId: memberId,
      allMissions: [],
      missonExecutionResultMap: undefined,
    });
    //get().loadData();
  },

  calculateTourResult: () => {
    const allMissions = get().allMissions;
    const allTourStatMissions = get().allTourStatMissions;

    const successExecutions = _.chain(allMissions)
      .map((m) => m.executionMobiles)
      .flatten()
      .filter((e) => e.result === "success" && e.isInvalid === false)
      .orderBy(["startTS_iso"], ["asc"])
      .value();

    const _minExecutionStartTS = _.chain(successExecutions)
      .flatten()
      .map((e) => e.startTS_iso)
      .min()
      .value();

    const maxExecutionEndTS = _.chain(successExecutions)
      .flatten()
      .map((e) => e.endTS_iso)
      .max()
      .value();

    const sumWorktime = _.chain(successExecutions)
      .flatten()
      .map((e) => dayjs(e.endTS_iso).diff(dayjs(e.startTS_iso), "minute"))
      .sum()
      .value();

    const calculatedPaidDuration = dayjs(maxExecutionEndTS).diff(
      dayjs(_minExecutionStartTS),
      "minute"
    );

    const _sumWaitingTime = _.chain(successExecutions)
      .flatten()
      .map((e) => e.waitTimeToOpen)
      .sum()
      .value();

    //  for (let mission of allMissions) {

    //     const tourStatMission = allTourStatMissions.find(
    //       (t) => t.missionId === mission.id
    //     );

    //     if (!tourStatMission) continue;

    //   }

    const tourResult: TourResultMember = {
      memberId: get().selectedMemberId,
      tourId: get().selectedTourId,
      day: get().selectedDay,
      minStartTime: _minExecutionStartTS || "",
      maxEndTime: maxExecutionEndTS || "",
      calculatedPaidDuration: calculatedPaidDuration,
      acceptedPaidDuration: 0,
      sumWorktime: sumWorktime,
      ratioWorkPaid: _.round((sumWorktime / calculatedPaidDuration) * 100, 0),
      sumWaitingTime: _sumWaitingTime,
      travelTime: calculatedPaidDuration - sumWorktime,
      ratioTravelPaid: _.round(
        ((calculatedPaidDuration - sumWorktime) / calculatedPaidDuration) * 100,
        0
      ),
    };

    set({ currentTourResult: tourResult });
  },
}));
