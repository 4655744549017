import React, { useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import toast from "react-hot-toast";
import { MissionPause } from "./useMissionEasyPlan";

import Map, {
  Layer,
  LayerProps,
  Marker,
  Source,
  ScaleControl,
} from "react-map-gl";

import DeckGL from "@deck.gl/react/typed";
import {
  ArcLayer,
  TextLayer,
  ColumnLayer,
  IconLayer,
} from "@deck.gl/layers/typed";

import type { MapRef } from "react-map-gl";

const initialViewState = {
  longitude: 6.93191365074411,
  latitude: 51.40530535770421,
  zoom: 14,
};

const layerStyle = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 5,
    "circle-color": "#007cbf",
    "circle-opacity": 0.5,
  },
} as LayerProps;

const layerLablePatients = {
  id: "Point",
  type: "symbol",
  minzoom: 13, // Set zoom level to whatever suits your needs
  // onClickMap: (event: any) => {
  //   console.log(event);
  // },
  // onHover: (info: any, event: any) => {
  //   setIsHovering(info.picked || false);
  //   console.log("Patient Hovered", info, event);
  // },
  layout: {
    "text-size": 10,
    "text-field": ["get", "name"],
    "text-variable-anchor": ["top", "bottom", "left", "right"],
    "text-radial-offset": 0.5,
    "text-justify": "auto",
  },
} as LayerProps;

const layerStylePatients = {
  id: "point1",
  type: "circle",
  paint: {
    "circle-radius": 5,
    //"circle-color": "#007cbf",

    "circle-color": ["get", "colorHex"],
    // "circle-color": "black",
    "circle-opacity": 0.5,
  },
} as LayerProps;

type Props = {};

const ViewerMissionEasyPlan = (props: Props) => {
  const [socket, setSocket] = useState<Socket | undefined>(undefined);

  const [missions, setMissions] = useState<MissionPause[]>([]);

  const [geoPatients, setGeoPatients] = useState<
    GeoJSON.FeatureCollection<GeoJSON.Point>
  >({
    type: "FeatureCollection",
    features: [],
  });

  const mapRef = useRef<MapRef>(null);
  const [viewState, setViewState] = useState({
    initialViewState,
  });

  // useEffect(() => {
  //   console.log("Use effect socket", socket);
  //   if (!socket) {
  //     const _socket = io(process.env.REACT_APP_SOCKET_ENDPOINT || "", {
  //       auth: {
  //         token: inMemoryJwt.getToken(),
  //       },
  //     });
  //     console.log("socket", _socket);
  //     _socket.on("connect", () => {
  //       toast.success("Connected to Server" + _socket.id);

  //       _socket.on("disconnect", () => {
  //         toast.error("Disconnected from Server");
  //       });
  //     });

  //     _socket.on("mission_easy_plan", (args) => {
  //       const _missions = args as MissionPause[];
  //       setMissions(_missions);
  //       console.log("Mission Update", _missions);
  //       toast.success("Update from Server ");

  //       const _geoPatients = [] as GeoJSON.Feature<GeoJSON.Point>[];

  //       _missions.forEach((mission) => {
  //         _geoPatients.push({
  //           type: "Feature",
  //           geometry: {
  //             type: "Point",
  //             coordinates: [
  //               Number(mission.patient?.longitude),
  //               Number(mission.patient?.latitude),
  //             ],
  //           },
  //           properties: {
  //             name: `${mission.patient?.lastName} ${mission.patient?.firstName}`,
  //           },
  //         });
  //       });

  //       setGeoPatients({
  //         type: "FeatureCollection",
  //         features: _geoPatients,
  //       });
  //     });

  //     setSocket(_socket);
  //   }
  //   return () => {
  //     console.log("Cleanup");
  //     socket?.disconnect();
  //   };
  // }, []);

  if (!missions) return <div>Keine Einsätze</div>;

  return (
    <div className="bg-white">
      <div onContextMenu={(evt) => evt.preventDefault()}>
        <DeckGL
          //layers={[arcLayer]}
          initialViewState={initialViewState}
          controller={true}
          // onClick={onClickMap}
          getCursor={({ isDragging, isHovering }) =>
            isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
          }
          //   getTooltip={({ object }: { object: any }) =>
          //     object && `${object.from.name} to ${object.to.name}`
          //   }
        >
          <Map
            {...viewState}
            onMove={(evt: any) => setViewState(evt.viewState)}
            style={{ width: "100%", height: "100%" }}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            //mapStyle="mapbox://styles/capsicum42/cl0zfhxa3007914lp31nbqtug"
            mapStyle="mapbox://styles/capsicum42/cl7xahs94001314o6ztr12osy"
            // interactiveLayerIds={[clusterLayer.id]}
            ref={mapRef}
          >
            <Source id="my-data" type="geojson" data={geoPatients}>
              <Layer {...layerStyle} />
              <Layer {...layerLablePatients}></Layer>
            </Source>
          </Map>
        </DeckGL>
      </div>
    </div>
  );
};

export default ViewerMissionEasyPlan;
