import React, { useEffect, useMemo } from "react";

import {
  LocalInCarePatients_OverviewPatients,
  useOverviewPatients,
} from "./useOverviewPatients";

import {
  GroupingState,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import ManageGeoData from "../ManageGeoData";
import DisplayPatientIdWithLink from "../DisplayPatientIdWithLink/DisplayPatientIdWithLink";
import { FaMars, FaVenus } from "react-icons/fa";
import {
  ArrowDownNarrowWideIcon,
  ArrowUpNarrowWideIcon,
  CalendarCheck2Icon,
  CalendarClockIcon,
  GroupIcon,
  HousePlusIcon,
  MapIcon,
  MapPinIcon,
  SmilePlusIcon,
  StethoscopeIcon,
  UngroupIcon,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../UiComponents/Tooltip";

type Props = {};

const OverviewPatients = (props: Props) => {
  const { patients, loadData } = useOverviewPatients();

  const [grouping, setGrouping] = React.useState<GroupingState>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  useEffect(() => {
    loadData();
  }, []);

  const columns = useMemo<ColumnDef<LocalInCarePatients_OverviewPatients>[]>(
    () => [
      {
        accessorKey: "patientId",
        enableGrouping: false,
        cell: (info) => {
          const patientId = info.row.original.patientId;

          return <DisplayPatientIdWithLink patientId={patientId} />;
        },
      },
      {
        accessorKey: "shortName",
        cell: (info) => info.getValue(),
        enableGrouping: false,
      },
      {
        accessorKey: "lastName",
        cell: (info) => info.getValue(),
        enableGrouping: false,
      },
      {
        accessorKey: "firstName",
        cell: (info) => info.getValue(),
        enableGrouping: false,
      },
      {
        id: "addressInfo",
        enableGrouping: false,
        header: "Adresse",
        cell: (info) => {
          const { address, zipCode, city } = info.row.original;
          return (
            <div className="flex flex-row">
              <div className="text-sm flex flex-col text-gray-500">
                <div>{address}</div>
                <div>
                  {zipCode} {city}
                </div>
              </div>
              <div>
                {!info.row.original.hasGeoData && (
                  <ManageGeoData patientId={info.row.original.patientId} />
                )}
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "hasActiveBetreuungszeitraum",
        header: () => {
          return (
            <div className="flex flex-row">
              <Tooltip>
                <TooltipTrigger>
                  <StethoscopeIcon />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Aktiver Betreuungzeitraum</p>
                </TooltipContent>
              </Tooltip>
            </div>
          );
        },
        cell: (info) => (
          <div className="flex flex-row justify-center items-center">
            {info.getValue() ? "j" : "n"}
          </div>
        ),
      },
      {
        accessorKey: "isDa",
        cell: (info) => {
          return (
            <div className="flex flex-row justify-center items-center">
              {info.getValue() ? "j" : "n"}
            </div>
          );
        },
        header: () => {
          return (
            <div className="flex flex-row">
              <Tooltip>
                <TooltipTrigger>
                  <HousePlusIcon />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Anwesend (nicht abwesend) </p>
                </TooltipContent>
              </Tooltip>
            </div>
          );
        },
      },
      {
        accessorKey: "absenceEndDate",
        header: () => {
          return (
            <div className="flex flex-row">
              <Tooltip>
                <TooltipTrigger>
                  <CalendarClockIcon />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Zurück am: </p>
                </TooltipContent>
              </Tooltip>
            </div>
          );
        },
        cell: (info) => {
          const date = info.row.original.absenceEndDate;
          if (!date) {
            return (
              <div className="flex flex-row justify-center items-center">-</div>
            );
          }
          return (
            <div className="flex flex-row justify-center items-center">
              {date}
            </div>
          );
        },
      },
      {
        accessorKey: "isActive",
        header: () => {
          return (
            <div className="flex flex-row">
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex flex-row">
                    <SmilePlusIcon />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Ist Aktiv aus Accesss</p>
                </TooltipContent>
              </Tooltip>
            </div>
          );
        },

        cell: (info) => (
          <div className="flex flex-row justify-center items-center">
            {info.getValue() ? "j" : "n"}
          </div>
        ),
      },
      {
        accessorKey: "hasSchedule",
        header: () => {
          return (
            <div className="flex flex-row">
              <Tooltip>
                <TooltipTrigger>
                  <CalendarCheck2Icon />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Es gibt eine Leistungsplanung</p>
                </TooltipContent>
              </Tooltip>
            </div>
          );
        },
        cell: (info) => (
          <div className="flex flex-row justify-center items-center">
            {info.getValue() ? "j" : "n"}
          </div>
        ),
      },
      {
        accessorKey: "hasGenderDeny",
        header: () => {
          return (
            <div className="flex flex-row ">
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex flex-row">
                    <FaMars />
                    <FaVenus />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Mann / Frau Einschränklung</p>
                </TooltipContent>
              </Tooltip>
            </div>
          );
        },
        cell: (info) => (
          <div className="flex flex-row justify-center items-center">
            {info.getValue() ? "j" : "n"}
          </div>
        ),
      },
      {
        accessorKey: "hasGeoData",
        header: () => {
          return (
            <div className="flex flex-row">
              <Tooltip>
                <TooltipTrigger asChild>
                  <MapPinIcon />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Hat Geo-Daten gepflegt</p>
                </TooltipContent>
              </Tooltip>
            </div>
          );
        },
        cell: (info) => (
          <div className="flex flex-row justify-center items-center">
            {info.getValue() ? "j" : "n"}
          </div>
        ),
      },
    ],
    []
  );

  const table = useReactTable({
    data: patients,
    columns,
    state: {
      grouping,
      sorting,
    },
    onGroupingChange: setGrouping,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
  });

  return (
    <TooltipProvider delayDuration={0}>
      <div className="flex flex-col gap-2 overflow-y-hidden">
        <div className="p-2"> Huhu</div>
        <div className="p-2 bg-white overflow-y-visible">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : (
                        <div className="flex flex-row gap-1 items-center border-2 border-gray-200 p-1">
                          {header.column.getCanGroup() ? (
                            // If the header can be grouped, let's add a toggle
                            <button
                              {...{
                                onClick:
                                  header.column.getToggleGroupingHandler(),
                                style: {
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {header.column.getIsGrouped() ? (
                                <div className="flex flex-row gap-2 items-center">
                                  <UngroupIcon size={18} />
                                </div>
                              ) : (
                                <GroupIcon size={18} />
                              )}
                            </button>
                          ) : null}
                          <div
                            onClick={header.column.getToggleSortingHandler()}
                            className="flex flex-row gap-1 items-center"
                          >
                            {header.column.getCanSort() ? (
                              header.column.getIsSorted() === "asc" ? (
                                <ArrowUpNarrowWideIcon />
                              ) : header.column.getIsSorted() === "desc" ? (
                                <ArrowDownNarrowWideIcon />
                              ) : (
                                ""
                              )
                            ) : undefined}

                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          {...{
                            key: cell.id,
                            style: {
                              background: cell.getIsGrouped()
                                ? "#0aff0082"
                                : cell.getIsAggregated()
                                  ? "#ffa50078"
                                  : cell.getIsPlaceholder()
                                    ? "#ff000042"
                                    : "white",
                            },
                          }}
                        >
                          {cell.getIsGrouped() ? (
                            // If it's a grouped cell, add an expander and row count
                            <div>
                              <button
                                {...{
                                  onClick: row.getToggleExpandedHandler(),
                                  style: {
                                    cursor: row.getCanExpand()
                                      ? "pointer"
                                      : "normal",
                                  },
                                }}
                              >
                                {row.getIsExpanded() ? "👇" : "👉"}{" "}
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}{" "}
                                ({row.subRows.length})
                              </button>
                            </div>
                          ) : cell.getIsAggregated() ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            flexRender(
                              cell.column.columnDef.aggregatedCell ??
                                cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
          <div className="h-4" />
        </div>
      </div>
    </TooltipProvider>
  );
};

export default OverviewPatients;
