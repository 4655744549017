import React, { useEffect, useRef } from "react";
import inMemoryJwt from "../Auth0/inMemoryJwt";
import LoginButton from "../Auth0/LoginButton";
import { useMissionEasyPlanNext } from "./useMissionEasyPlanNext";
import ElementEasyPlan from "./ElementEasyPlan";
import { Button } from "../UiComponents/Button";
import TourEasyPlan from "./TourEasyPlan";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import { useShallow } from "zustand/react/shallow";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  PointerSensorOptions,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../UiComponents/Popover";
import { Calendar } from "../UiComponents/Calendar";
import CreateNewTourEasyPlan from "./CreateNewTourEasyPlan";
import PatientInfoEasyPlan from "./PatientInfoEasyPlan";
import { set } from "lodash";
import { Input } from "../UiComponents/Input";
import PatientSearchInput from "./PatientSearchInput";
import { useVirtualizer } from "@tanstack/react-virtual";
import PatientFilterEasyPlan from "./PatientFilterEasyPlan";
import NoActionFilterEasyPlan from "./NoActionFilterEasyPlan";

type Props = {};

export const MissionEasyPlanNext = (props: Props) => {
  const token = inMemoryJwt.getToken();

  if (token === null) {
    return (
      <div>
        Getting Token
        <LoginButton />
      </div>
    );
  }

  return <MissionEasyPlanNext1 {...props} />;
};

const MissionEasyPlanNext1 = (props: Props) => {
  // const {

  // } = useMissionEasyPlanNext();

  const tours = useMissionEasyPlanNext(useShallow((state) => state.tours));
  const selectedTourId = useMissionEasyPlanNext(
    (state) => state.selectedTourId
  );
  const loadData = useMissionEasyPlanNext((state) => state.loadData);
  const setSelectedDay = useMissionEasyPlanNext(
    (state) => state.setSelectedDay
  );
  const selectedDay = useMissionEasyPlanNext((state) => state.selectedDay);
  const handleDragEndService = useMissionEasyPlanNext(
    (state) => state.handleDragEndService
  );
  const updateMissionsOnDatabase = useMissionEasyPlanNext(
    (state) => state.updateMissionsOnDatabase
  );
  const lastMissionsUpdate = useMissionEasyPlanNext(
    (state) => state.lastMissionsUpdate
  );
  const routingAllTours = useMissionEasyPlanNext(
    (state) => state.routingAllTours
  );

  const setSelectedElement = useMissionEasyPlanNext(
    (state) => state.setSelectedElement
  );
  const autoDistanceEnabled = useMissionEasyPlanNext(
    (state) => state.autoDistanceEnabled
  );
  const setAutoDistanceEnabled = useMissionEasyPlanNext(
    (state) => state.setAutoDistanceEnabled
  );

  const isLoading = useMissionEasyPlanNext((state) => state.isLoading);

  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [showPatientInfo, setShowPatientInfo] = React.useState(false);

  const [activeDragId, setActiveDragId] = React.useState<string | null>(null);
  const [draggingPatientShortName, setDraggingPatientShortName] =
    React.useState<string | null>(null);

  const [tourFocus, setTourFocus] = React.useState<string | undefined>(
    undefined
  );

  const [searchPatientView, setSearchPatientView] =
    React.useState<boolean>(false);

  const [noLeistFilterView, setNoLeistFilterView] =
    React.useState<boolean>(false);

  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!tourFocus) return;

    const normalizedSearchText = tourFocus.toLowerCase();

    const firstTour = tours.find((tour) =>
      tour.tourId.toLowerCase().includes(normalizedSearchText)
    );

    if (firstTour) {
      tourRefs.current[firstTour.tourId]?.scrollIntoView();
    }
  }, [tourFocus]);

  const tourRefs = useRef<Record<string, HTMLDivElement | null>>({});

  React.useEffect(() => {
    setSelectedDay(dayjs().format("YYYY-MM-DD"));
    loadData();
  }, [loadData]);

  // useEffect(() => {
  //   if (!inputPatientId) return;
  //   setMatchingElementsPatient(inputPatientId);
  // }, [inputPatientId]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // activationConstraint: {
      //   delay: 10,
      //   tolerance: 5,
      // },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    console.log("handleDragEndMission", event);
    const { active, over } = event;
    setActiveDragId(null);
    setDraggingPatientShortName(null);
    handleDragEndService({ active, over });
  };

  // const handleDragOver = (event: any) => {
  //   console.log("handleDragOver", event);
  // };

  const handleDragStart = (event: any) => {
    setActiveDragId(event.active.id);
    setDraggingPatientShortName(
      event.active.data?.current?.element?.patient?.shortName
    );
    console.log("handleDragStart", event);
  };

  const handleCalendarSelect = (date: Date | undefined) => {
    console.log("handleCalendarSelect", date);
    setSelectedDay(dayjs(date).format("YYYY-MM-DD"));
    setShowDatePicker(false);
  };

  return (
    <>
      <Helmet>
        <title>TourenEasy {dayjs(selectedDay).format("DD.MMM")}</title>
      </Helmet>
      <div className="flex flex-col bg-white w-full overflow-y-hidden h-full select-none  ">
        {/* Menue bar */}
        <div className="flex flex-row gap-2 sticky top-0 bg-white  justify-between p-2 ">
          <div>
            <Button
              onClick={() => {
                setSearchPatientView(!searchPatientView);
              }}
            >
              Suchen
            </Button>
            <Button
              onClick={() => {
                setNoLeistFilterView(!noLeistFilterView);
              }}
            >
              No Leist
            </Button>
          </div>
          <div>
            <CreateNewTourEasyPlan />
          </div>
          <Button
            onClick={() => {
              setAutoDistanceEnabled(!autoDistanceEnabled);
            }}
          >
            {autoDistanceEnabled ? "Auto" : "Manual"}
          </Button>

          <Button
            onClick={() => {
              routingAllTours();
            }}
          >
            All Routing
          </Button>
          <div className="flex flex-row gap-2">
            <Button
              disabled={isLoading}
              onClick={() => {
                setSelectedDay(
                  dayjs(selectedDay).add(-1, "day").format("YYYY-MM-DD")
                );
              }}
            >
              -
            </Button>
            <Popover open={showDatePicker} onOpenChange={setShowDatePicker}>
              <PopoverTrigger asChild>
                <Button
                  onClick={() => {
                    setShowDatePicker(!showDatePicker);
                  }}
                  variant={"neutral-ghost"}
                >
                  {dayjs(selectedDay).format("dddd, DD. MMMM")}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div className="bg-white">
                  <Calendar
                    mode="single"
                    selected={dayjs(selectedDay).toDate()}
                    onSelect={handleCalendarSelect}
                    initialFocus
                  />
                </div>
              </PopoverContent>
            </Popover>

            <Button
              disabled={isLoading}
              onClick={() => {
                setSelectedDay(
                  dayjs(selectedDay).add(1, "day").format("YYYY-MM-DD")
                );
              }}
            >
              +
            </Button>
          </div>
          <div className="flex flex-row gap-2">
            <Button
              disabled={isLoading}
              onClick={() => {
                updateMissionsOnDatabase();
                setSelectedElement({ selectedElement: null });
              }}
            >
              Buchen
            </Button>
            <div>
              {dayjs(lastMissionsUpdate).format("ddd DD. MMM HH:mm:ss")}
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <Button
              onClick={() => {
                setShowPatientInfo(!showPatientInfo);
              }}
            >
              Patient
            </Button>
          </div>
        </div>
        <div className="flex flex-row overflow-y-scroll w-full">
          {searchPatientView && (
            <div className="w-56">
              <PatientFilterEasyPlan />
            </div>
          )}
          {noLeistFilterView && (
            <div className="w-56">
              <NoActionFilterEasyPlan />
            </div>
          )}
          <div className="flex flex-col gap-2 w-full h-full overflow-y-scroll  ">
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              // onDragOver={handleDragOver}
              onDragStart={handleDragStart}
            >
              {tours
                .filter((t) => t.tourId === selectedTourId)
                .map((tour) => (
                  <div
                    key={tour.tourId}
                    className="sticky top-0  bg-teal-100 border-4 border-black  shadow-lg"
                  >
                    <TourEasyPlan
                      key={tour.tourId}
                      tour={tour}
                      keepOldTimes={true}
                    />
                  </div>
                ))}

              {tours
                .filter((t) => t.tourId !== selectedTourId)
                .map((tour) => (
                  <div
                    key={tour.tourId}
                    ref={(el) => {
                      if (el) {
                        tourRefs.current[tour.tourId] = el;
                      }
                    }}
                    className="bg-gray-50"
                  >
                    <TourEasyPlan
                      key={tour.tourId}
                      tour={tour}
                      keepOldTimes={false}
                    />
                  </div>
                ))}
              <DragOverlay>
                {activeDragId && (
                  <div className="bg-white text-xs font-extralight w-28">
                    {draggingPatientShortName}
                  </div>
                )}
              </DragOverlay>
            </DndContext>
          </div>
          {showPatientInfo && (
            <div className="w-96 flex flex-col">
              <PatientInfoEasyPlan />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MissionEasyPlanNext;
