import { client } from "../../../urqlClient";
import { create } from "zustand";
import {
  LocalMission_ReviewExecutionMobile,
  Missions_ReviewExecutionMobile,
} from "../useReviewExecutionMobile";
import _ from "lodash";
import dayjs from "dayjs";
import { graphql } from "../../../gql";
import toast from "react-hot-toast";

const SingleDistance_AnalyzeOneDay = graphql(/* GraphQL */ `
  query SingleDistance_AnalyzeOneDay(
    $fromPatientId: String!
    $toPatientId: String!
  ) {
    singleDistance(fromPatientId: $fromPatientId, toPatientId: $toPatientId) {
      fromPatientId
      toPatientId
      travelTime_min
      distance_km
    }
  }
`);

type ExecutionAnalyze = {
  patientId: string;
  executionId: string;
  startTS_iso: string;
  endTS_iso: string;
  missionId: string;
  result: string;
  timeToNextAsIs: number;
  timeToNextGoolge: number;
  remark?: string | null;
};

export type TourResultMember = {
  memberId: string;
  tourId: string;
  day: string;
  minStartTime: string;
  maxEndTime: string;
  calculatedPaidDuration: number;
  acceptedPaidDuration: number;
  sumWorktime: number;
  sumWaitingTime: number;
  ratioWorkPaid: number;
  travelTime: number;
  ratioTravelPaid: number;
};

type AnalyzeOneDayState = {
  selectedDay: string;
  setSelectedDay: (day: string) => void;
  selectedTourId: string;
  setSelectedTourId: (tourId: string) => void;
  selectedMemberId: string;
  setSelectedMemberId: (memberId: string) => void;
  currentTourResult?: TourResultMember;
  loadData: () => void;
  calculateTourResult: () => void;
  allExecutions: ExecutionAnalyze[];
  allMissions: LocalMission_ReviewExecutionMobile[];
  getMissionById: (
    missionId: string
  ) => LocalMission_ReviewExecutionMobile | undefined;
};

export const useAnalyzeOneDay = create<AnalyzeOneDayState>((set, get) => ({
  selectedDay: "",
  setSelectedDay: (selectedDay: string) => set({ selectedDay }),
  selectedTourId: "",
  allExecutions: [],
  allMissions: [],
  selectedMemberId: "",
  setSelectedMemberId: (selectedMemberId: string) => set({ selectedMemberId }),

  setSelectedTourId: (selectedTourId: string) => set({ selectedTourId }),
  loadData: async () => {
    const res = await client
      .query(
        Missions_ReviewExecutionMobile,
        {
          filter: {
            maxDate: get().selectedDay,
            minDate: get().selectedDay,
            tourIds: [get().selectedTourId],
          },
        },
        {
          requestPolicy: "network-only",
        }
      )
      .toPromise();

    const _distinctMemberIds = _.chain(res?.data?.missionsTimeRange)
      .uniqBy("memberId")
      .map((o) => o.memberId)
      .value();

    if (_distinctMemberIds.length === 1) {
      set({ selectedMemberId: _distinctMemberIds[0] });
      get().calculateTourResult();
    } else {
      set({ selectedMemberId: "" });
      toast.error("Mehrere Mitarbeiter in einer Tour sind nicht erlaubt");
    }

    set({ allMissions: res?.data?.missionsTimeRange || [] });

    // build all Executions

    const _allExecutions = [];

    for (const mission of get().allMissions) {
      for (const execution of mission.executionMobiles) {
        if (execution.isInvalid === true) continue;
        _allExecutions.push({
          patientId: mission.patientId,
          executionId: execution.id,
          startTS_iso: execution.startTS_iso,
          endTS_iso: execution.endTS_iso,
          missionId: mission.id,
          result: execution.result,
          remark: execution.remark,
          timeToNextAsIs: 0,
          timeToNextGoolge: 0,
        });
      }
    }

    // sort
    const sortedExecutions = _.orderBy(
      _allExecutions,
      ["startTS_iso"],
      ["asc"]
    );

    // add time to next
    for (let i = 1; i < sortedExecutions.length; i++) {
      const execution = sortedExecutions[i];
      const previousExecution = sortedExecutions[i - 1];

      const { data } = await client
        .query(SingleDistance_AnalyzeOneDay, {
          fromPatientId: previousExecution.patientId,
          toPatientId: execution.patientId,
        })
        .toPromise();

      const sdTravelTime =
        previousExecution.patientId === execution.patientId
          ? 0
          : _.round(data?.singleDistance?.travelTime_min || 7, 0);

      const diff = dayjs(execution.startTS_iso).diff(
        dayjs(previousExecution.endTS_iso),
        "minute"
      );

      execution.timeToNextAsIs = diff;
      execution.timeToNextGoolge = sdTravelTime;
    }

    set({ allExecutions: sortedExecutions });

    console.log(res);
  },
  getMissionById: (missionId: string) => {
    return get().allMissions.find((m) => m.id === missionId);
  },
  calculateTourResult: () => {
    const allMissions = get().allMissions;

    const successExecutions = _.chain(allMissions)
      .map((m) => m.executionMobiles)
      .flatten()
      .filter((e) => e.result === "success" && e.isInvalid === false)
      .orderBy(["startTS_iso"], ["asc"])
      .value();

    const _minExecutionStartTS = _.chain(successExecutions)
      .flatten()
      .map((e) => e.startTS_iso)
      .min()
      .value();

    const maxExecutionEndTS = _.chain(successExecutions)
      .flatten()
      .map((e) => e.endTS_iso)
      .max()
      .value();

    const sumWorktime = _.chain(successExecutions)
      .flatten()
      .map((e) => dayjs(e.endTS_iso).diff(dayjs(e.startTS_iso), "minute"))
      .sum()
      .value();

    const calculatedPaidDuration = dayjs(maxExecutionEndTS).diff(
      dayjs(_minExecutionStartTS),
      "minute"
    );

    const _sumWaitingTime = _.chain(successExecutions)
      .flatten()
      .map((e) => e.waitTimeToOpen)
      .sum()
      .value();

    //  for (let mission of allMissions) {

    //     const tourStatMission = allTourStatMissions.find(
    //       (t) => t.missionId === mission.id
    //     );

    //     if (!tourStatMission) continue;

    //   }

    const tourResult: TourResultMember = {
      memberId: get().selectedMemberId,
      tourId: get().selectedTourId,
      day: get().selectedDay,
      minStartTime: _minExecutionStartTS || "",
      maxEndTime: maxExecutionEndTS || "",
      calculatedPaidDuration: calculatedPaidDuration,
      acceptedPaidDuration: 0,
      sumWorktime: sumWorktime,
      ratioWorkPaid: _.round((sumWorktime / calculatedPaidDuration) * 100, 0),
      sumWaitingTime: _sumWaitingTime,
      travelTime: calculatedPaidDuration - sumWorktime,
      ratioTravelPaid: _.round(
        ((calculatedPaidDuration - sumWorktime) / calculatedPaidDuration) * 100,
        0
      ),
    };

    set({ currentTourResult: tourResult });
  },
}));
