import React, { useEffect, useRef } from "react";
import {
  duration2Time,
  LocalTourAsString_MissionEasyPlanNext,
  TourStatistic,
  useMissionEasyPlanNext,
} from "./useMissionEasyPlanNext";
import { Button } from "../UiComponents/Button";
import ElementEasyPlan from "./ElementEasyPlan";
import { useDroppable } from "@dnd-kit/core";
import { nan } from "zod";
import { nanoid } from "nanoid";
import {
  BadgeEuroIcon,
  BicepsFlexedIcon,
  CarIcon,
  CoffeeIcon,
  PlusCircleIcon,
  ReplaceIcon,
  ScaleIcon,
  SquareSigmaIcon,
} from "lucide-react";
import { cn } from "../../helpers/utils";
import DisplayMemberShortName from "../Member/DisplayMemberShortName";
import { to } from "@react-spring/web";
import InActiveElementsEasyPlan from "./InActiveElementsEasyPlan";
import dayjs from "dayjs";
import PauseEasyPlan from "./PauseEasyPlan";
import _ from "lodash";
import { stat } from "fs";
import EditTourStartTime from "./EditTourStartTime";
import EditTourMemberEasyPlan from "./EditTourMemberEasyPlan";
import EditTourIdEasyPlan from "./EditTourIdEasyPlan";
import CreateNewMissionEasyPlan from "./CreateNewMissionEasyPlan";
import { t } from "xstate";
import { startMin2Time } from "../MissionEasyPlan/MissionEasyPlan";

const ElementPlus = ({ tourId, index }: { tourId: string; index: number }) => {
  const { setNodeRef: dropNodeRef, isOver } = useDroppable({
    id: tourId + "_" + index,
    data: {
      type: "plus",
      index: index,
      tourId: tourId,
    },
  });

  return (
    <div
      ref={dropNodeRef}
      className={cn("flex w-28 items-center justify-center", {
        "bg-green-200": isOver,
      })}
    >
      Hier
    </div>
  );
};

type ElementTimes = {
  id: string;
  startMinuteOfDay: number;
};

type Props = {
  tour: LocalTourAsString_MissionEasyPlanNext;
  keepOldTimes: boolean;
};

const TourEasyPlan = (props: Props) => {
  const { tour } = props;
  const tourId = tour.tourId;

  const getTourStatistic = useMissionEasyPlanNext(
    (state) => state.getTourStatistic
  );
  const createPause = useMissionEasyPlanNext((state) => state.createPause);
  const lastMissionsUpdate = useMissionEasyPlanNext(
    (state) => state.lastMissionsUpdate
  );
  const setSelectedTourId = useMissionEasyPlanNext(
    (state) => state.setSelectedTourId
  );
  const selectedTourId = useMissionEasyPlanNext(
    (state) => state.selectedTourId
  );

  const scrollToMissionId = useMissionEasyPlanNext(
    (state) => state.scrollToMissionId
  );

  const [stats, setStats] = React.useState<TourStatistic | null>(null);
  const missionRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const [oldElements, setOldElements] = React.useState<ElementTimes[]>();

  useEffect(() => {
    if (props.keepOldTimes) {
      setOldElements(
        tour.activeMissions.map((mission) => {
          return {
            id: mission.missionId,
            startMinuteOfDay: mission.startTimeMinuteOfDay,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    setStats(getTourStatistic(tourId));
  }, [tour]);

  useEffect(() => {
    if (scrollToMissionId) {
      scrollToMission(scrollToMissionId);
    }
  }, [scrollToMissionId]);

  const _tourIsNewer = dayjs(tour.createdAt).isAfter(lastMissionsUpdate);

  let warnPause = false;
  if (
    stats &&
    stats?.workTime + stats?.travelTime > 360 &&
    stats?.pauseTime < 30
  ) {
    warnPause = true;
  }

  const scrollToMission = (missionId: string) => {
    const element = missionRefs.current[missionId];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className="flex flex-col gap-1 border-2 border-gray-400 ">
      <div>
        <div className="flex flex-row gap-2 items-center">
          <EditTourIdEasyPlan tourId={tour.tourId}>
            <div
              className={cn("p-1", {
                "bg-blue-500 text-white": tour.startMinuteOfDay >= 720,
              })}
            >
              {tour.tourId}
            </div>

            <div className="p-1 font-semibold">
              {dayjs(tour.day).format("ddd DD. MMM")}
            </div>
          </EditTourIdEasyPlan>

          <div
            className={cn("p-1 text-xs tex-gray-400", {
              "bg-yellow-400": _tourIsNewer,
            })}
          >
            Stand: {dayjs(tour.createdAt).format("ddd DD. MMM, HH:mm:ss")}
          </div>
          {stats && (
            <div className="flex flex-row gap-2 items-center">
              <div className="flex flex-row gap-1 p-1 bg-white">
                <BadgeEuroIcon size={20} />
                {duration2Time(stats?.paidTime)}
              </div>
              <div className="flex flex-row gap-1  p-1 bg-white items-center">
                <CoffeeIcon size={20} />

                {duration2Time(stats?.pauseTime)}
              </div>
              <div className="flex flex-row gap-1  p-1 bg-white items-center">
                <CarIcon size={20} />
                {duration2Time(stats?.travelTime)}
              </div>
              <div className="flex flex-row gap-1  p-1 bg-white items-center">
                <BicepsFlexedIcon size={20} />
                {duration2Time(stats?.workTime)}
              </div>
              <div
                className={cn(
                  "flex flex-row gap-1  p-1 bg-white items-center",
                  {
                    "bg-red-500 text-white": warnPause,
                  }
                )}
              >
                <ScaleIcon size={20} />
                {duration2Time(stats.workTime + stats.travelTime)}
              </div>
              <div className="flex flex-row gap-1  p-1 bg-white items-center">
                <SquareSigmaIcon size={20} />
                {_.round(
                  (stats.travelTime / (stats.workTime + stats.workTime)) * 100,
                  0
                )}
                %
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row w-full gap-2">
        <div className="flex flex-col gap-2 bg-red-100 w-56">
          <div className="flex flex-row justify-between p-2 bg-white  h-full">
            {/* TourInfo */}
            <div className="flex flex-col gap-2">
              <div>
                <EditTourStartTime
                  tourId={tour.tourId}
                  tourStartMinuteOfDay={tour.startMinuteOfDay}
                />
              </div>
              <EditTourMemberEasyPlan
                tourId={tourId}
                tourMemberId={tour.member?.memberId || ""}
              >
                <DisplayMemberShortName {...tour.member} />
              </EditTourMemberEasyPlan>

              <InActiveElementsEasyPlan tour={tour} />
            </div>
            {/* IconBar */}

            <div className="flex flex-col items-center gap-1">
              <Button
                variant={"neutral-ghost"}
                size={"compact-sm"}
                onClick={() => {
                  if (tourId === selectedTourId) {
                    setSelectedTourId("");
                  } else {
                    setSelectedTourId(tourId);
                  }
                }}
              >
                <ReplaceIcon size={20} />
              </Button>
              <Button
                variant={"neutral-ghost"}
                size={"compact-sm"}
                onClick={() => {
                  createPause({ tourId: tourId, index: 4, duration_min: 15 });
                }}
              >
                <CoffeeIcon size={20} />
              </Button>
              <div>
                <CreateNewMissionEasyPlan tourId={tourId} day={tour.day} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2 flex-wrap w-full p-2 bg-gray-100">
          {tour.activeMissions.map((mission, index) => (
            <div
              ref={(el) => (missionRefs.current[mission.missionId] = el)}
              key={mission.missionId}
            >
              {mission.type === "M" ? (
                <>
                  <ElementEasyPlan
                    key={index}
                    memberHasQualificationLevel={Number(
                      tour.member?.hasQualificationLevel || 0
                    )}
                    element={mission}
                    index={index}
                    tourId={tour.tourId}
                    listType="active"
                  />
                  <div>
                    {props.keepOldTimes &&
                      oldElements?.find(
                        (oldElement) => oldElement.id === mission.missionId
                      ) && (
                        <div className="text-xs text-center text-gray-400">
                          {startMin2Time(
                            oldElements.find(
                              (oldElement) =>
                                oldElement.id === mission.missionId
                            )?.startMinuteOfDay || 0
                          )}
                        </div>
                      )}
                  </div>{" "}
                </>
              ) : (
                <PauseEasyPlan
                  key={index}
                  memberHasQualificationLevel={Number(
                    tour.member?.hasQualificationLevel || 0
                  )}
                  element={mission}
                  index={index}
                  tourId={tour.tourId}
                  listType="active"
                />
              )}
            </div>
          ))}
          <ElementPlus
            tourId={tour.tourId}
            index={tour.activeMissions.length}
          />
        </div>
      </div>
    </div>
  );
};

export default TourEasyPlan;
