import { useMission } from "@/src/services/Mission/useMission";
import React from "react";
import {
  LocalTourAsString_MissionEasyPlanNext,
  useMissionEasyPlanNext,
} from "./useMissionEasyPlanNext";
import { Trash2Icon } from "lucide-react";
import { Popover, PopoverTrigger } from "../UiComponents/Popover";
import { PopoverContent } from "@radix-ui/react-popover";
import ElementEasyPlan from "./ElementEasyPlan";
import { Button } from "../UiComponents/Button";

type Props = {
  tour: LocalTourAsString_MissionEasyPlanNext;
};

const InActiveElementsEasyPlan = (props: Props) => {
  const { tour } = props;
  const activateMission = useMissionEasyPlanNext(
    (state) => state.activateMission
  );

  if (tour.inActiveMissions.length === 0) return null;

  return (
    <Popover>
      <PopoverTrigger>
        <div className="text-red-500 flex flex-row gap-1 items-center">
          <Trash2Icon size={18} /> {tour.inActiveMissions.length}
        </div>
      </PopoverTrigger>
      <PopoverContent className="bg-gray-200 p-4 flex flex-row border-2 border-gray-400">
        {tour.inActiveMissions.map((mission, index) => (
          <div key={index} className="flex flex-col gap-2 p-2 bg-white">
            <div>
              {mission.patient?.shortName} ({mission.patientId})
            </div>
            <Button
              variant={"neutral-ghost"}
              size={"compact-sm"}
              onClick={() =>
                activateMission({
                  tourId: tour.tourId,
                  missionId: mission.missionId,
                  index: index,
                })
              }
            >
              Aktivieren
            </Button>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
};

export default InActiveElementsEasyPlan;
