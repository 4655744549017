import React, { useEffect } from "react";

import {
  LocalChangedMission_TourPublish,
  useTourPublish,
} from "./useTourPublish";

import { useTourInfoMessage } from "../TourInfoMessage/useTourInfoMessage";

import { Cross1Icon } from "@radix-ui/react-icons";
import { CheckCheckIcon } from "lucide-react";
import { Button } from "../UiComponents/Button";
import _ from "lodash";
import { Textarea } from "../UiComponents/Textarea";
import { Dialog, DialogContent, DialogTrigger } from "../UiComponents/Dialog";
import TourInfoMessage from "../TourInfoMessage/TourInfoMessage";
import dayjs from "dayjs";

type Props = {
  tourId: string;
};

const DisplayMissionsChanges = ({
  changedMission,
}: {
  changedMission: LocalChangedMission_TourPublish;
}) => {
  return (
    <div className="bg-white flex flex-row gap-2">
      <div>
        {changedMission.mission?.time}
        {changedMission.mission?.patient?.lastName},
        {changedMission.mission?.patient?.firstName}
      </div>
      <div>
        {changedMission.canceled && (
          <Cross1Icon className=" text-red-500 h-5 w-5" />
        )}
        {changedMission.timeChanged && (
          <div>
            {changedMission.oldTime} {"->"}
            {changedMission.newTime}{" "}
          </div>
        )}
      </div>
    </div>
  );
};

const TourPublishDetail = (props: Props) => {
  const { selectedDay, tourDatas, updateMissionSupabase } = useTourPublish();
  const { latestMessage, loadData: loadTourInfoMessages } =
    useTourInfoMessage();

  const selectedTourData = tourDatas.find(
    (tourData) => tourData.tourId === props.tourId
  );

  useEffect(() => {
    loadTourInfoMessages(selectedDay, props.tourId);
  }, [selectedDay, props.tourId]);

  const _missionChanges = _.orderBy(
    selectedTourData?.missionChanges,
    ["mission.time"],
    ["asc"]
  );

  console.log("Mission Changes", _missionChanges);

  if (props.tourId === "") return null;

  return (
    <div className="flex flex-col gap-2 h-full overflow-y-scroll">
      <div className="flex flex-row gap-2">
        <div className="font-semibold">{props.tourId}</div>
        <div className="font-bold">
          {dayjs(selectedDay).format("ddd DD.MM")}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {latestMessage ? (
          <div className=" flex flex-col gap-2">
            <div className="bg-gray-100">Version: {latestMessage.version}</div>

            <div className="bg-gray-100">
              {latestMessage?.message?.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        ) : (
          <div className="h-24  text-slate-300">Keine Nachricht</div>
        )}

        <Dialog>
          <DialogTrigger>
            <Button>Ändern</Button>
          </DialogTrigger>
          <DialogContent className="w-96">
            <TourInfoMessage
              day={selectedDay}
              tourId={props.tourId}
              setOpen={function (isOpen: boolean): void {
                throw new Error("Function not implemented.");
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Button
          onClick={() => {
            updateMissionSupabase([props.tourId]);
          }}
        >
          Auf das Handy
        </Button>
      </div>

      <div className="h-full overflow-y-scroll">
        {_missionChanges.map((missionChange) => (
          <div key={missionChange.missionId}>
            <DisplayMissionsChanges changedMission={missionChange} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TourPublishDetail;
