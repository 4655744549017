import React, { useEffect, useRef, useState } from "react";

import { HelmetProvider } from "react-helmet-async";

import { client } from "./urqlClient";
import inMemoryJWT from "./Components/Auth0/inMemoryJwt";

import { useAuth0 } from "@auth0/auth0-react";

import "./index.css";
import Navigation from "./Navigation";

import { Route, Routes } from "react-router";
import { ManageSchedules } from "./Components/ManageSchedules/ManageSchedules";

//import { WeeklyOverview } from "./Components/WeeklyOverview/WeeklyOverview";

import { ListOpenMissions } from "./Components/AssignSchedules/ListOpenMissions";
import { About } from "./Components/About/About";
//mport TourManager from "./Components/TourManager/TourManager";

import DistrictsMap from "./Components/PatientsMap/DistrictsMap";
import { Provider } from "urql";
import AnalyzeMission from "./Components/AnalyzeMission/AnalyzeMission";

import TourManager2 from "./Components/TourManager/TourManager2";

//import WeekByMember from "./Components/WeekByMember/WeekByMember";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

//import MapPatientAnalyzis from "./Components/MapPatientAnalyzis/MapPatientAnalyzis";
import MissionCreate from "./Components/MissionCreate/MissionCreate";
import TourBuilder from "./Components/TourBuilder/TourBuilder";

import PatientActionDurationOverview from "./Components/PatientActionDuration/PatientActionDurationOverview";
import ServerMessage from "./Components/ServerMessage";

import { nanoid } from "nanoid";

import PlanSchedule from "./Components/Schedule/PlanSchedule/PlanSchedule";
import PatientIssue from "./Components/PatientIssue/PatientIssue";
import EditExecutionMobile from "./Components/EditExecutionMobile/EditExecutionMobile";

import { useUserInfo } from "./Components/UserInfo/useUserInfo";
import { TooltipProvider } from "./Components/UiComponents/Tooltip";
import TourPublish from "./Components/TourPublish/TourPublish";
import MissionEasyPlan from "./Components/MissionEasyPlan/MissionEasyPlan";
import { useScroll } from "@react-spring/web";
import ReviewExecutionMobile from "./Components/ReviewExecutionMobile/ReviewExecutionMobile";
import ViewerMissionEasyPlan from "./Components/MissionEasyPlan/ViewerMissionEasyPlan";
import ReviewOneDayExecution from "./Components/ReviewExecutionMobile/ReviewOneDay/ReviewOneDayExecution";
import AnalyzeOneDayExecution from "./Components/ReviewExecutionMobile/AnalyzeOneDay/AnalyzeOneDayExecution";
import MissionEasyPlanNext from "./Components/MissionEasyPlanNext/MissionEasyPlanNext";
import OverviewPatients from "./Components/Patient/OverviewPatients/OverviewPatients";

const ManagePatientAbsence = React.lazy(
  () => import("./Components/Patient/PatientAbsence/ManagePatientAbsence")
);
const MemberQualificationEdit = React.lazy(
  () =>
    import("./Components/Member/MemberQualification/MemberQualificationEdit")
);

const TourManager = React.lazy(
  () => import("./Components/TourManager/TourManager")
);

const MissionTimeline = React.lazy(
  () => import("./Components/MissionTimeline/MissionTimeline")
);

const ImportMissionCsv = React.lazy(
  () => import("./Components/ImportMissionCsv/ImportMissionCsv")
);

const WeekByMember = React.lazy(
  () => import("./Components/WeekByMember/WeekByMember")
);

const WeeklyOverview = React.lazy(
  () => import("./Components/WeeklyOverview/WeeklyOverview")
);

const MapPatientAnalyzis = React.lazy(
  () => import("./Components/MapPatientAnalyzis/MapPatientAnalyzis")
);

const AllMissionPublicationStatus = React.lazy(
  () =>
    import("./Components/MissionPublicationStatus/AllMissionPublicationStatus")
);

const PatientInfoScreen = React.lazy(
  () => import("./Pages/PatientInfoScreen/PatientInfoScreen")
);

const ReviewFaktura = React.lazy(
  () => import("./Components/ReviewFaktura/ReviewFaktura")
);

const ExcelCompare = React.lazy(
  () => import("./Components/ExcelCompare/ExcelCompare")
);

const AllPatientActive = React.lazy(
  () => import("./Components/Patient/PatientActive/AllPatientActive")
);

export const myClientId = nanoid(10);

function App() {
  const { user, getAccessTokenSilently, isAuthenticated, isLoading } =
    useAuth0();
  const [infoText, setInfoText] = useState<string | undefined>("Huhu");
  const { hasRole, setUser } = useUserInfo();

  useEffect(() => {
    console.log("Auth app", isAuthenticated);
    if (isAuthenticated) {
      return;
    }
    getAccessTokenSilently()
      .then((result) => {
        console.log("token in jwt Token", result);
        inMemoryJWT.setToken(result);
        inMemoryJWT.setUserId(user?.sub || "");
        setUser({
          id: user?.sub || "",
          name: user?.name || "",
          email: user?.email || "",
          roles: user?.["/roles"] || [],
        });
      })
      .catch((err) => {
        console.log("login required", err);
      });
  }, [isAuthenticated]);

  if (!isAuthenticated && !isLoading) {
    return (
      <div>
        <About />
        {inMemoryJWT.getToken()}
      </div>
    );
  }

  return (
    <HelmetProvider>
      <TooltipProvider>
        <Provider value={client}>
          <DndProvider backend={HTML5Backend}>
            <div
              id="myMainDiv"
              className="h-screen flex w-full bg-primaryBackground"
            >
              <div className="flex flex-col w-full bg-primaryBackground">
                <Navigation />
                <div className=" flex-1 w-full overflow-auto h-full">
                  <Routes>
                    <Route path="/" element={<About />} />

                    <Route
                      path="/weeklyoverview"
                      element={
                        hasRole("ManagePatient") ? (
                          <WeeklyOverview />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/calendar"
                      element={
                        hasRole("ManagePatient") ? (
                          <WeekByMember />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/manageschedules/:patientId"
                      element={
                        hasRole("ManagePatient") ? (
                          <ManageSchedules />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />

                    <Route path="/about" element={<About />} />
                    <Route
                      path="/manageschedules"
                      element={
                        hasRole("ManagePatient") ? (
                          <ManageSchedules />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    {/* <Route
                    path="/listopenmissions/:publicationId"
                    element={<ListOpenMissions />}
                  /> */}
                    <Route
                      path="/tourmanager"
                      element={
                        hasRole("ManageTouren") ? (
                          <TourManager />
                        ) : (
                          <div>
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManageTouren
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/patientsmap"
                      element={
                        hasRole("ManagePatient") ? (
                          <DistrictsMap />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />

                    {/* <Route
                      path="/patientmapanalyzis"
                      element={<MapPatientAnalyzis />}
                    /> */}
                    <Route
                      path="/analyzemission"
                      element={<AnalyzeMission />}
                    />
                    {/* <Route path="/testcomponent2" element={<WeekByMember />} />
                  <Route path="/testcomponent3" element={<TourManager2 />} /> */}
                    <Route
                      path="/editmobilemission"
                      element={
                        hasRole("ManagePatient") ? (
                          <TourBuilder />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/missioncreate"
                      element={
                        hasRole("ManagePatient") ? (
                          <MissionCreate />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/missionpublicationstatus"
                      element={
                        hasRole("ManagePatient") ? (
                          <AllMissionPublicationStatus />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/memberqualificationedit"
                      element={
                        hasRole("ManagePatient") ? (
                          <MemberQualificationEdit />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/patientdurationoverview"
                      element={
                        hasRole("ManagePatient") ? (
                          <PatientActionDurationOverview />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/importmissioncsv"
                      element={
                        hasRole("ManagePatient") ? (
                          <ImportMissionCsv />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/managepatientabsence"
                      element={
                        hasRole("ManagePatient") ? (
                          <ManagePatientAbsence />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/missiontimeline"
                      element={
                        hasRole("ManagePatient") ? (
                          <MissionTimeline />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/planschedule"
                      element={
                        hasRole("ManagePatient") ? (
                          <PlanSchedule />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/patientissue"
                      element={
                        hasRole("ManagePatient") ? (
                          <PatientIssue />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/missioneasyplan"
                      element={
                        hasRole("ManagePatient") ? (
                          <MissionEasyPlanNext />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/viewermissioneasyplan"
                      element={
                        hasRole("ManagePatient") ? (
                          <ViewerMissionEasyPlan />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route path="/welcome" element={<div>Welcome</div>}></Route>
                    <Route
                      path="/patientinfoscreen"
                      element={
                        hasRole("PatientInfo") ? (
                          <PatientInfoScreen />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              PatientInfo
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/tourpublish"
                      element={
                        hasRole("ManagePatient") ? (
                          <TourPublish />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              MamnagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/reviewexecutionmobile"
                      element={
                        hasRole("ManagePatient") ? (
                          <ReviewExecutionMobile />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              MamnagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/reviewonedayexecution"
                      element={
                        hasRole("ManagePatient") ? (
                          <ReviewOneDayExecution />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              MamnagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/analyzeonedayexecution"
                      element={
                        hasRole("ManagePatient") ? (
                          <AnalyzeOneDayExecution />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              MamnagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/reviewfaktura"
                      element={
                        hasRole("ManagePatient") ? (
                          <ReviewFaktura />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/excelcompare"
                      element={
                        hasRole("ManagePatient") ? (
                          <ExcelCompare />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                    <Route
                      path="/allpatientactive"
                      element={
                        hasRole("ManagePatient") ? (
                          <AllPatientActive />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />

                    <Route
                      path="/overviewpatients"
                      element={
                        hasRole("ManagePatient") ? (
                          <OverviewPatients />
                        ) : (
                          <div>
                            {" "}
                            Keine Berechtigung:{" "}
                            <h1 className="font-semibold text-xl">
                              ManagePatient
                            </h1>
                          </div>
                        )
                      }
                    />
                  </Routes>
                </div>
                <footer className="flex flex-row justify-between bg-secondaryBackground ">
                  <div>
                    {process.env.REACT_APP_SERVER_ENDPOINT}{" "}
                    {process.env.REACT_APP_SOCKET_ENDPOINT}
                  </div>
                  <div>
                    <ServerMessage />
                  </div>
                </footer>
              </div>
            </div>
          </DndProvider>
        </Provider>
      </TooltipProvider>
    </HelmetProvider>
  );
}

export default App;
